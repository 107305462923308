
@font-face {font-family: 'RobotoMedium';src: url('../fonts/Roboto-Medium.eot');src: url('../fonts/Roboto-Medium.otf') format('otf'),url('../fonts/Roboto-Medium.woff') format('woff'),url('../fonts/Roboto-Medium.ttf') format('truetype');}

@font-face {font-family: 'RobotoRegular';src: url('../fonts/Roboto-Regular.eot');src: url('../fonts/Roboto-Regular.otf') format('otf'),url('../fonts/Roboto-Regular.woff') format('woff'),url('../fonts/Roboto-Regular.ttf') format('truetype');}

@font-face {font-family:'GothamMedium';src: url('../fonts/Gotham-Medium.woff');src: url('../fonts/Gotham-Medium.otf') format('otf'),url('../fonts/Gotham-Medium.woff') format('woff'),url('../fonts/Gotham-Medium.ttf') format('truetype');} 

@font-face {font-family:'GothamBook';src: url('../fonts/Gotham-Book.eot');src: url('../fonts/Gotham-Book.otf') format('otf'),url('../fonts/Gotham-Book.woff') format('woff'),url('../fonts/Gotham-Book.ttf') format('truetype');}

@font-face {font-family:'GothamLight';src: url('../fonts/Gotham-Light.eot');src: url('../fonts/Gotham-Light.otf') format('otf'),url('../fonts/Gotham-Light.woff') format('woff'),url('../fonts/Gotham-Light.ttf') format('truetype');}

@font-face {font-family:'GothamBold';src: url('../fonts/Gotham-Bold.eot');src: url('../fonts/Gotham-Bold.otf') format('otf'),url('../fonts/Gotham-Bold.woff') format('woff'),url('../fonts/Gotham-Bold.ttf') format('truetype');}

@font-face {font-family:'GothamOfficeRegular';src: url('../fonts/GothamOffice-Regular.eot');src: url('../fonts/GothamOffice-Regular.otf') format('otf'),url('../fonts/GothamOffice-Regular.woff') format('woff'),url('../fonts/GothamOffice-Regular.ttf') format('truetype');}

@font-face {font-family:'GothamOfficeBold';src: url('../fonts/GothamOffice-Bold.eot');src: url('../fonts/GothamOffice-Bold.otf') format('otf'),url('../fonts/GothamOffice-Bold.woff') format('woff'),url('../fonts/GothamOffice-Bold.ttf') format('truetype');}

/*@font-face {font-family:'GothamThin';src: url('../fonts/theme-fonts/Gotham-Thin.eot');src: url('../fonts/theme-fonts/Gotham-Thin.otf') format('otf'),url('../fonts/theme-fonts/Gotham-Thin.woff') format('woff'),url('../fonts/theme-fonts/Gotham-Thin.ttf') format('truetype');}

@font-face {font-family:'GothamUltra';src: url('../fonts/theme-fonts/Gotham-Ultra.eot');src: url('../fonts/theme-fonts/Gotham-Ultra.otf') format('otf'),url('../fonts/theme-fonts/Gotham-Ultra.woff') format('woff'),url('../fonts/theme-fonts/Gotham-Ultra.ttf') format('truetype');}

@font-face {font-family:'GothamNarrowThin';src: url('../fonts/theme-fonts/GothamNarrow-Thin.eot');src: url('../fonts/theme-fonts/GothamNarrow-Thin.otf') format('otf'),url('../fonts/theme-fonts/GothamNarrow-Thin.woff') format('woff'),url('../fonts/theme-fonts/GothamNarrow-Thin.ttf') format('truetype');}

@font-face {font-family:'GothamNarrowBlack';src: url('../fonts/theme-fonts/GothamNarrow-Black.eot');src: url('../fonts/theme-fonts/GothamNarrow-Black.otf') format('otf'),url('../fonts/theme-fonts/GothamNarrow-Black.woff') format('woff'),url('../fonts/theme-fonts/GothamNarrow-Black.ttf') format('truetype');}

@font-face {font-family:'GothamNarrowBook';src: url('../fonts/theme-fonts/GothamNarrow-Book.eot');src: url('../fonts/theme-fonts/GothamNarrow-Book.otf') format('otf'),url('../fonts/theme-fonts/GothamNarrow-Book.woff') format('woff'),url('../fonts/theme-fonts/GothamNarrow-Book.ttf') format('truetype');}

@font-face {font-family:'GothamNarrowUltra';src: url('../fonts/theme-fonts/GothamNarrow-Ultra.eot');src: url('../fonts/theme-fonts/GothamNarrow-Ultra.otf') format('otf'),url('../fonts/theme-fonts/GothamNarrow-Ultra.woff') format('woff'),url('../fonts/theme-fonts/GothamNarrow-Ultra.ttf') format('truetype');} */

@font-face {font-family: 'OpenSansRegular';src: url('../fonts/OpenSans-Regular.eot');src: url('../fonts/OpenSans-Regular.woff') format('woff'),url('../fonts/OpenSans-Regular.ttf') format('truetype');}

@font-face {font-family:'OpenSansLight';src: url('../fonts/OpenSans-Light.eot');src: url('../fonts/OpenSans-Light.woff') format('woff'),url('../fonts/OpenSans-Light.ttf') format('truetype');}

@font-face {font-family:'OpenSansBold';src: url('../fonts/OpenSans-Bold.eot');src: url('../fonts/OpenSans-Bold.woff') format('woff'),url('../fonts/OpenSans-Bold.ttf') format('truetype');}

@font-face {font-family:'OpenSansSemibold';src: url('../fonts/OpenSans-Semibold.eot');src: url('../fonts/OpenSans-Semibold.woff') format('woff'),url('../fonts/OpenSans-Semibold.ttf') format('truetype');}

@font-face {font-family:'OpenSansItalic';src: url('../fonts/OpenSans-Italic.eot');src: url('../fonts/OpenSans-Italic.woff') format('woff'),url('../fonts/OpenSans-Italic.ttf') format('truetype');}

@font-face {font-family:'OpenSansBoldItalic';src: url('../fonts/OpenSans-BoldItalic.eot');src: url('../fonts/OpenSans-BoldItalic.woff') format('woff'),url('../fonts/OpenSans-BoldItalic.ttf') format('truetype');}



h1,h2,h3,h4 {
    margin:0px;
    padding:0px;
	font-weight:normal;
	font-family:'GothamMedium';
	line-height:normal;
}

a {
	color: #00ADBB;
	font-size: 14px;
	font-weight:normal;
	&:hover {
		text-decoration:none;
		color: #033258;
	}
}
.dark-blue-link {
	color: #033258;
	&:hover {
		color: #00ADBB;
	}
}
