@import "_mixins.scss";
@import "shared/_loader.scss";
@import "shared/_textND.scss";
@import "shared/_inputND.scss";
@import "shared/_pageND.scss";
@import "_footerND.scss";

body {
  margin: 0px;
  padding: 0px;
  line-height: normal;
  font-weight: normal;
  font-size: 14px;
}
.custom-container {
  max-width: 1440px;
  margin: 0px auto;
  float: none !important;
  @include breakpointMax(1400) {
    max-width: 100%;
  }
}
a {
  font-size: 14px;
}
.body-wrapper {
  min-height: 600px;
}
/*******************************************common css start*******************************/
/***********************************Gotham Start*********************/
.GothamFont {
  font-family: "GothamBook";
  & h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: "GothamMedium";
  }
  & select,
  textarea {
    font-family: "GothamBook";
  }
  & input {
    font-family: "RobotoRegular";
  }
  & button {
    font-family: "RobotoMedium";
    &.material-icons {
      font-family: "Material Icons";
    }
  }
  a {
    font-family: "GothamBook";
  }
  & .notification {
    &.notification-success {
      font-family: "GothamBook";
    }
    & .fill-color-button {
      font-family: "GothamBook";
    }
    & .white-fill-color-button {
      font-family: "GothamBook";
    }
  }
  /********login page*****/
  & .login-form {
    & h2 {
      font-family: "GothamMedium";
    }
  }
  & .punchline-text {
    font-family: "GothamMedium";
  }
  /********login page End*****/
  & .navbar-signup-button {
    font-family: "GothamBook";
  }
  & .navbar-login-button {
    font-family: "GothamBook";
    &.login-dropdown-button {
      & button {
        font-family: "GothamBook";
      }
    }
  }

  /********home page start*****/
  & .banner-title {
    font-family: "GothamMedium";
  }
  & .loading-title {
    font-family: "GothamMedium";
  }
  & .banner-sub-title {
    font-family: "GothamLight";
  }
  & .rbt-input-main {
    font-family: "RobotoRegular";
  }
  & .rbt-input-hint {
    font-family: "RobotoRegular";
  }
  & .typeahead-search {
    font-family: "RobotoRegular";
  }
  & .openhouse-filter {
    font-family: "RobotoRegular";
  }
  & .section-title {
    & h2 {
      font-family: "GothamMedium";
    }
  }
  & .prop-card {
    & .card-prop-info {
      & .card-prop-amount {
        font-family: "GothamBold";
      }
    }
  }
  & .latest-mls-update {
    & .update-mls-text {
      & .update-time {
        & .large-time {
          font-family: "GothamLight";
        }
        & .small-time {
          font-family: "GothamMedium";
        }
      }
    }
  }
  & .afford-cost-clc {
    & .cost-range-titel {
      font-family: "GothamMedium";
    }
  }
  & .home-neighborhood {
    & .neighborhood-listing {
      & .neighborhood-card {
        & .neighborhood-name {
          font-family: "GothamLight";
          & .name-highlight {
            font-family: "GothamMedium";
          }
        }
      }
    }
  }
  & .prop-card {
    & .card-prop-info {
      & .prop-info-basic {
        & .card-add-lt {
          & .address-line {
            &:first-child {
              font-family: "GothamMedium";
            }
          }
        }
        & .prop-info-ext {
          & .prop-open-house-ext {
            font-family: "GothamMedium";
          }
        }
      }
    }
  }

  & .tlc-how-work {
    & .desktop-img-banner {
      & .tlc-price-amount {
        font-family: "GothamBold";
      }
    }
    & .tlc-mortgage-text {
      font-family: "GothamMedium";
    }
    & .tlc-utilities-text {
      font-family: "GothamMedium";
    }
    & .tlc-commute-text {
      font-family: "GothamMedium";
    }
  }
  & .tlc-how-works {
    & .desktop-img-banner {
      & .tlc-price-amount {
        font-family: "GothamBold";
      }
    }
    & .tlc-mortgage-text {
      font-family: "GothamMedium";
    }
    & .tlc-utilities-text {
      font-family: "GothamMedium";
    }
    & .tlc-commute-text {
      font-family: "GothamMedium";
    }
    & .desktop-img-banner {
      & .round-text {
        font-family: "GothamBold";
        & .round-subtext {
          font-family: "GothamBook";
        }
      }
      & .tlc-home-smallbox {
        & .sub-box-inner {
          & .box-info {
            font-family: "GothamBook";
            & .info-highlight {
              font-family: "GothamMedium";
            }
          }
        }
      }
    }
  }
  & .mb-img-banner {
    & .mb-tlc-price-amount {
      font-family: "GothamBold";
    }
    & .mb-tlc-mortgage-text {
      font-family: "GothamMedium";
    }
    & .mb-tlc-utilities-text {
      font-family: "GothamMedium";
    }
    & .mb-tlc-commute-text {
      font-family: "GothamMedium";
    }
  }
  & .mb-img-tlcbanner {
    & .mb-property-full-tlc {
      font-family: "GothamBold";
    }
    & .mb-tlc-mortgage-text {
      font-family: "GothamMedium";
    }
    & .mb-tlc-utilities-text {
      font-family: "GothamMedium";
    }
    & .mb-tlc-commute-text {
      font-family: "GothamMedium";
    }
    & .mb-tlc-home-smallbox {
      & .sub-box-inner {
        & .box-info {
          font-family: "GothamBook";
          & .info-highlight {
            font-family: "GothamMedium";
          }
        }
      }
      &.mb-property-full-tlc {
        & .sub-box-inner {
          & .box-info {
            font-family: "GothamBold";
            & .info-highlight {
              font-family: "GothamBold";
            }
          }
        }
      }
    }
  }

  /********home page End*****/

  /********property detail page start*****/
  & .breadcrumb {
    a {
      font-family: "GothamMedium";
    }
  }
  & .property-description {
    & h1 {
      font-family: "GothamMedium";
    }
    & .property-price {
      font-family: "GothamBold";
    }
    /* & .property-address {
			& .sub-address {
				font-family: 'GothamMedium';
			}
		} */
    & .utility-list {
      & .utility-text {
        & .hilight-text {
          font-family: "GothamBold";
        }
      }
    }
  }
  & .property-details-main {
    & .sidebar-right {
      & .sidebar-com-box {
        & .sidebar-rt-cost {
          &.listing-cost {
            font-family: "GothamBook";
          }
        }
        & .affordability-details {
          & .tlc-cost {
            font-family: "GothamOfficeRegular";
            &.final-tlc-cost {
              font-family: "GothamOfficeBold";
            }
          }
          & .tlc-calculat-label {
            font-family: "GothamBold";
          }
        }
        & .detail-agent-info {
          & .agent-details {
            & .agent-name {
              font-family: "GothamBold";
              & a {
                font-family: "GothamBold";
              }
            }
          }
        }
      }
    }
    & .accordion-listing {
      & .card-body {
        & .property-accordion-content {
          & .mortgage-list {
            & .mortgage-cost {
              font-family: "GothamMedium";
            }
          }
        }
      }
    }
    & .accordion-see-all {
      font-family: "GothamMedium";
    }
  }
  & .card-header {
    & .header-name {
      font-family: "GothamBook";
    }
  }
  & .type-select {
    & .react-select__control {
      & .react-select__single-value {
        font-family: "GothamBook";
      }
    }
  }
  & .gallery-type3 {
    & .prop-open-house {
      & .open-house-status {
        font-family: "GothamBold";
      }
    }
  }
  & .gallery-box {
    & .view-photo {
      & .theme-btn {
        font-family: "GothamBook";
      }
    }
    & .prop-status {
      & .status-active {
        font-family: "GothamLight";
      }
      & .gl-active {
        font-family: "GothamLight";
      }
    }
    & .prop-open-house {
      & .open-house-status {
        font-family: "GothamBold";
      }
    }
  }
  & .pre-approv-btn {
    & .theme-btn {
      font-family: "GothamBold";
    }
  }
  & .tlc-panel-title {
    font-family: "GothamMedium";
  }
  & .panel-title-box {
    font-family: "GothamMedium";
    & .category-amount {
      font-family: "GothamBook";
      &.category-final-amount {
        font-family: "GothamMedium";
      }
    }
  }
  & .calculate-your-tlc {
    & .theme-btn {
      font-family: "GothamMedium";
    }
  }
  & .blog-mid-content {
    &.inner-blog-mid-content {
      & .blog-full-detail {
        & .content-title {
          font-family: "GothamBold";
        }
      }
    }
  }
  & .tlc-calculat-edit {
    & h3 {
      font-family: "GothamMedium";
    }
    & label {
      font-family: "GothamLight";
      &.calculat-label {
        font-family: "GothamBook";
      }
      &.tlc-calculat-label {
        font-family: "GothamBold";
      }
    }
    & .est-cost {
      font-family: "GothamLight";
    }
    & .final-cost {
      & [type="text"] {
        font-family: "GothamBook";
      }
    }
    & .tlc-cost {
      font-family: "GothamOfficeRegular";
      &.final-tlc-cost {
        font-family: "GothamOfficeBold";
      }
    }
  }
  & .cost-calculat-edit {
    & h3 {
      font-family: "GothamMedium";
    }
    & label {
      font-family: "GothamBook";
    }
    & .final-cost {
      & [type="text"] {
        font-family: "GothamBook";
      }
    }
    & .final-total-cost {
      font-family: "GothamMedium";
    }
  }
  & .chat-window {
    & .chat-profile-round {
      font-family: "GothamMedium";
    }
  }
  & .mb-prop-open-house {
    font-family: "GothamBold";
  }
  /********property detail page start*****/

  /********realtor page start*****/
  & .agent-dt-box {
    & .agent-dt-right {
      & .agent-name {
        font-family: "GothamBold";
        & a {
          font-family: "GothamBold";
        }
      }
    }
  }
  & .find-agent-result {
    & h3 {
      font-family: "GothamMedium";
    }
  }
  & .find-agent-filter {
    & .nav-tabs {
      & .nav-link {
        &.active {
          font-family: "GothamMedium";
        }
      }
    }
  }
  /********realtor page End*****/

  /********accordion Start*****/
  & .accordion-listing {
    & .card-header {
      & .header-name {
        & .office-name {
          font-family: "GothamBold";
        }
        & .office-address {
          font-family: "GothamBook";
        }
      }
    }
    & .card-body {
      & .property-accordion-content {
        & .tlc-calculat-edit {
          &.mortgage-calculat {
            & label {
              font-family: "GothamBook";
            }
          }
        }
        & .mortgage-chart {
          & .highcharts-title {
            & tspan {
              &:first-child {
                font-family: "GothamMedium";
              }
              &:last-child {
                font-family: "GothamLight";
              }
            }
          }
        }
      }
      & .update-note {
        font-family: "GothamBook";
      }
      & .update-note-btn {
        font-family: "GothamMedium";
      }
    }
  }
  & .budget-chart {
    & .highcharts-title {
      & .budget-price {
        font-family: "GothamBold";
      }
      & .cost-living {
        font-family: "GothamMedium";
      }
      & .border-bottom {
        font-family: "GothamBold";
      }
    }
  }
  /********accordion End*****/

  /********Search page Start*****/
  & .search-bar-panel {
    & .dropdown {
      & .dropdown-toggle {
        font-family: "GothamBook";
      }
      & .dropdown-menu {
        font-family: "GothamBook";
      }
    }
    & .com-panel-box {
      & .reset-button {
        font-family: "GothamBook";
      }
    }
    & .price-range-control {
      & [type="text"] {
        font-family: "GothamBook";
      }
    }
    & .prop-save-searh-button {
      & .theme-btn {
        font-family: "GothamMedium";
      }
    }
  }
  & .more-filter-panel {
    & .more-filter-container {
      & [type="text"] {
        font-family: "GothamBook";
      }
      & .matching-listing-bg {
        font-family: "GothamMedium";
      }
    }
  }
  & .search-container {
    & .right-wrapper {
      & .hide-map-view,
      & .remove-line {
        & .theme-btn {
          font-family: "GothamBook";
        }
      }
    }
  }
  /********Search page End*****/

  /********agentprofile page Start*****/
  & .horizontal-prop-card {
    & .current-prop-box {
      & .current-prop-dt {
        & .current-pro-address {
          font-family: "GothamMedium";
        }
        & .current-pro-price {
          font-family: "GothamBold";
        }
      }
      & .current-prop-right {
        & .current-tlc-amount {
          font-family: "GothamBook";
        }
      }
    }
  }
  & .agent-detail-bg {
    & .agent-biodata-left {
      & h2 {
        font-family: "GothamMedium";
      }
      & h3 {
        font-family: "GothamLight";
      }
    }
    & .small-sub-title {
      font-family: "GothamBold";
    }
  }
  /********agentprofile page End*****/

  /********neighborhood detail page Start*****/
  & .neighborhood-detail {
    & .neighborhood-state-info {
      & h2 {
        font-family: "GothamMedium";
      }
      & .state-utility-list {
        & .state-utility-text {
          & .hilight-text {
            font-family: "GothamBold";
          }
        }
      }
    }
    & .tab-list {
      & .nav-tabs {
        & .nav-link {
          font-family: "GothamMedium";
        }
      }
      & .tab-content {
        & .nav-tabs {
          & .nav-link {
            font-family: "GothamBook";
          }
        }
      }
    }
    & .average-score {
      & .mortgage-list {
        & .mortgage-name {
          font-family: "GothamBold";
        }
      }
    }
    & .sign-up-neighborhood {
      font-family: "GothamMedium";
    }
  }
  /********neighborhood detail page End*****/

  /********Profile Agent page Start*****/
  & .table-result-title {
    font-family: "GothamBold";
  }
  /********Dashboard Start*****/
  & .last-top-comments {
    & .chat-right,
    & .chat-left {
      & .chat-box {
        & .comments-box {
          & .profile-name {
            font-family: "GothamMedium";
          }
          & .profile-text {
            font-family: "GothamBook";
          }
        }
      }
    }
    & .view-more-comment {
      font-family: "GothamBook";
    }
  }
  & .dash-profile-left {
    & .dash-profile-name {
      font-family: "GothamBook";
      & .dash-profile-fullname {
        font-family: "GothamMedium";
      }
    }
  }
  & .left-content-box {
    & .affordability-titel {
      font-family: "GothamBook";
    }
  }
  & .affordability-list {
    & .affordability-name {
      font-family: "GothamBold";
    }
  }
  /********dashboard right start*****/
  & .dashboard-right {
    & [type="text"] {
      font-family: "GothamBook";
    }
    /********dashboard tab start*****/
    & .tab-list {
      & .nav-tabs {
        & .nav-link {
          font-family: "GothamMedium";
        }
      }
    }
    /********dashboard tab End*****/
    /********timeline left start*****/
    & .timeline-left-col {
      & .timeline-short-name {
        & .timeline-name-box {
          font-family: "GothamMedium";
        }
      }
    }
    /********timeline left End*****/
    /********timeline right start*****/
    & .timeline-right-col {
      & .mb-timeline-left-col {
        & .timeline-short-name {
          & .timeline-name-box {
            font-family: "GothamMedium";
          }
        }
      }
      & .timeline-create-info {
        & .timeline-create {
          font-family: "GothamMedium";
          & .create-item {
            font-family: "GothamBook";
          }
          & .last-active-time {
            font-family: "GothamBook";
          }
        }
      }
      & .timeline-comment-bg {
        & .last-top-comments {
          & .chat-profile-round {
            font-family: "GothamMedium";
          }
        }
      }
    }
    /********timeline right End*****/
    /********account info start*****/
    & .account-detail {
      & .signal-switch {
        font-family: "GothamBook";
      }
      & .price-range {
        font-family: "GothamMedium";
      }
    }
    & .commute-calculat-edit {
      font-family: "GothamLight";
    }
    & .clients-info {
      & .clients-name {
        & .clients-fullname {
          font-family: "GothamBold";
          & .client-last-logged {
            font-family: "GothamBook";
          }
        }
      }
    }
    & .agent-client-detail {
      & .detail-title {
        font-family: "GothamBook";
      }
    }
  }
  /********dashboard right End*****/
  & .contactus-small-label {
    font-family: "GothamMedium";
  }
  /***********************************Dashboard End**********************************/

  /********Blog Start*****/
  & .blog-banner-sub-title {
    font-family: "GothamLight";
  }
  & .blog-card {
    & .blog-card-info {
      & .blog-header {
        font-family: "GothamBold";
      }
    }
    & .blog-short-content {
      & .blog-ct-more {
        & a {
          font-family: "GothamBold";
        }
      }
    }
  }
  & .blog-comments-content {
    & .blog-comments-listing {
      & .comments-number-title {
        font-family: "GothamMedium";
      }
      & .comments-reply-bt {
        & a {
          font-family: "GothamBold";
        }
      }
    }
  }
  & .MuiTypography-body2 {
    font-family: "GothamBook";
  }
  /********Blog End*****/

  /********Manage start*****/
  & .manage-card {
    & .card-prop-top {
      font-family: "GothamMedium";
    }
    & .card-prop-footer {
      font-family: "GothamMedium";
    }
  }
  & .table-top-title {
    font-family: "GothamBook";
  }
  & .save-search-table-header {
    & .filter-title {
      font-family: "GothamMedium";
    }
  }
  & .white-card-content {
    & .content-item-text {
      font-family: "GothamBook";
    }
  }
  & .pd-loader-text-t1 {
    font-family: "GothamMedium";
  }
  & .pd-loader-text-t2 {
    font-family: "GothamLight";
  }
  & .compare-cost-header {
    & .highlight-text {
      font-family: "GothamMedium";
    }
  }
  & .compare-result-info {
    & .compare-result-price {
      font-family: "GothamMedium";
    }
    & .compare-result-city {
      font-family: "GothamMedium";
    }
  }
  & .compare-city-box {
    & .compare-city-name {
      font-family: "GothamMedium";
    }
    & .compare-last-amount {
      font-family: "GothamMedium";
    }
  }
  & .more-tlc-price-filter {
    & .prop-price-filter {
      & .dropdown {
        & .dropdown-toggle {
          font-family: "GothamBook";
        }
      }
    }
  }
  & .more-commute-filter {
    & .dropdown {
      & .dropdown-toggle {
        font-family: "GothamBook";
      }
    }
    & .rbt-input-main {
      font-family: "RobotoRegular" !important;
    }
  }
  & .agent-listing-info {
    & .tab-list {
      & .nav-tabs {
        & .nav-link {
          font-family: "GothamMedium";
        }
      }
    }
  }
  & .profile-location-dropdown {
    & .rbt-input-main {
      font-family: "RobotoRegular";
    }
  }
  & .joyride-tooltip__header {
    font-family: "GothamBold";
  }
  & .joyride-tooltip__button {
    &.joyride-tooltip__button--skip {
      font-family: "GothamBook";
    }
    &.joyride-tooltip__button--secondary {
      font-family: "GothamBook";
    }
  }
  & .widget-card {
    & h2 {
      font-family: "GothamBook";
    }
    & .numeric-input-box {
      & .form-control {
        font-family: "GothamBook";
      }
    }
  }
  & .controls-wrapper {
    & .numeric-input-box {
      & .form-control {
        font-family: "GothamBook";
      }
    }
  }
  & .expenses-cost-header {
    & .expenses-content {
      font-family: "GothamMedium";
    }
  }

  & .tlc-note {
    font-family: "GothamBook";
  }

  & .journey-left {
    & .sidebar-journey-title {
      font-family: "GothamMedium";
    }
  }
  & .journey-content {
    & h3 {
      font-family: "GothamMedium";
    }
    & h4 {
      font-family: "GothamMedium";
    }
    & .journey-checkbox-fill {
      & .journey-checkbox-title {
        font-family: "GothamMedium";
      }
      & .journey-checkbox-subtitle {
        font-family: "GothamBook";
      }
    }
    & .journey-radio-fill {
      & .journey-icon-wrap {
        & .journey-radio-bold-title {
          font-family: "GothamMedium";
        }
      }
    }
    & .move-range-box {
      & .input-range__track {
        & .input-range__label--value {
          & .input-range__label-container {
            font-family: "GothamBold";
          }
        }
      }
      & .input-range {
        & .input-range__label--min {
          & .input-range__label-container {
            font-family: "GothamBook";
          }
        }
        & .input-range__label--max {
          & .input-range__label-container {
            font-family: "GothamBook";
          }
        }
      }
    }
    & .month-range-info {
      font-family: "GothamMedium";
    }
    & .bold-title {
      font-family: "GothamMedium";
    }
    & .mortgage-types-chart {
      & .circular-chart {
        & .percentage {
          font-family: "GothamMedium";
        }
      }
    }
    & .pdf-download-link {
      & .pdf-dn-name {
        font-family: "GothamMedium";
      }
    }
  }
  & .leaflet-popup-content {
    & .poi_tooltip {
      & .poi-header-name {
        font-family: "GothamMedium";
      }
    }
  }
  & .personal-note-lt {
    & .personal-note-tip-number {
      font-family: "GothamMedium";
    }
    & .personal-note-tip-cnt {
      font-family: "GothamBook";
    }
  }
}
/***********************************Gotham Start*********************/
.OpensansFont {
  font-family: "OpenSansRegular";
  & h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: "OpenSansSemibold";
  }
  & select,
  textarea {
    font-family: "OpenSansRegular";
  }
  & input {
    font-family: "OpenSansLight";
  }
  & button {
    font-family: "OpenSansRegular";
    &.material-icons {
      font-family: "Material Icons";
    }
  }
  a {
    font-family: "OpenSansLight";
  }
  & .italic-style-font {
    font-family: "OpenSansItalic";
  }
  & .bolditalic-style-font {
    font-family: "OpenSansBoldItalic";
  }
  & .notification {
    &.notification-success {
      font-family: "OpenSansRegular";
    }
    & .fill-color-button {
      font-family: "OpenSansRegular";
    }
    & .white-fill-color-button {
      font-family: "OpenSansRegular";
    }
  }
  /********login page*****/
  & .login-form {
    & h2 {
      font-family: "OpenSansSemibold";
    }
  }
  & .punchline-text {
    font-family: "OpenSansSemibold";
  }
  /********login page End*****/
  & .navbar-signup-button {
    font-family: "OpenSansRegular";
  }
  & .navbar-login-button {
    font-family: "OpenSansRegular";
    &.login-dropdown-button {
      & button {
        font-family: "OpenSansRegular";
      }
    }
  }

  /********home page start*****/
  & .banner-title {
    font-family: "OpenSansSemibold";
  }
  & .loading-title {
    font-family: "OpenSansSemibold";
  }
  & .banner-sub-title {
    font-family: "OpenSansLight";
  }
  & .rbt-input-main {
    font-family: "OpenSansLight";
  }
  & .rbt-input-hint {
    font-family: "OpenSansLight";
  }
  & .typeahead-search {
    font-family: "OpenSansLight";
  }
  & .openhouse-filter {
    font-family: "OpenSansLight";
  }
  & .section-title {
    & h2 {
      font-family: "OpenSansSemibold";
    }
  }
  & .prop-card {
    & .card-prop-info {
      & .card-prop-amount {
        font-family: "OpenSansBold";
      }
    }
  }
  & .latest-mls-update {
    & .update-mls-text {
      & .update-time {
        & .large-time {
          font-family: "OpenSansLight";
        }
        & .small-time {
          font-family: "OpenSansSemibold";
        }
      }
    }
  }
  & .afford-cost-clc {
    & .cost-range-titel {
      font-family: "OpenSansSemibold";
    }
  }
  & .home-neighborhood {
    & .neighborhood-listing {
      & .neighborhood-card {
        & .neighborhood-name {
          font-family: "OpenSansLight";
          & .name-highlight {
            font-family: "OpenSansSemibold";
          }
        }
      }
    }
  }
  & .prop-card {
    & .card-prop-info {
      & .prop-info-basic {
        & .card-add-lt {
          & .address-line {
            &:first-child {
              font-family: "OpenSansSemibold";
            }
          }
        }
        & .prop-info-ext {
          & .prop-open-house-ext {
            font-family: "OpenSansSemibold";
          }
        }
      }
    }
  }

  & .tlc-how-work {
    & .desktop-img-banner {
      & .tlc-price-amount {
        font-family: "OpenSansBold";
      }
    }
    & .tlc-mortgage-text {
      font-family: "OpenSansSemibold";
    }
    & .tlc-utilities-text {
      font-family: "OpenSansSemibold";
    }
    & .tlc-commute-text {
      font-family: "OpenSansSemibold";
    }
  }
  & .tlc-how-works {
    & .desktop-img-banner {
      & .tlc-price-amount {
        font-family: "OpenSansBold";
      }
    }
    & .tlc-mortgage-text {
      font-family: "OpenSansSemibold";
    }
    & .tlc-utilities-text {
      font-family: "OpenSansSemibold";
    }
    & .tlc-commute-text {
      font-family: "OpenSansSemibold";
    }
    & .desktop-img-banner {
      & .round-text {
        font-family: "OpenSansBold";
        & .round-subtext {
          font-family: "OpenSansRegular";
        }
      }
      & .tlc-home-smallbox {
        & .sub-box-inner {
          & .box-info {
            font-family: "OpenSansRegular";
            & .info-highlight {
              font-family: "OpenSansSemibold";
            }
          }
        }
      }
    }
  }
  & .mb-img-banner {
    & .mb-tlc-price-amount {
      font-family: "OpenSansBold";
    }
    & .mb-tlc-mortgage-text {
      font-family: "OpenSansSemibold";
    }
    & .mb-tlc-utilities-text {
      font-family: "OpenSansSemibold";
    }
    & .mb-tlc-commute-text {
      font-family: "OpenSansSemibold";
    }
  }
  & .mb-img-tlcbanner {
    & .mb-property-full-tlc {
      font-family: "OpenSansBold";
    }
    & .mb-tlc-mortgage-text {
      font-family: "OpenSansSemibold";
    }
    & .mb-tlc-utilities-text {
      font-family: "OpenSansSemibold";
    }
    & .mb-tlc-commute-text {
      font-family: "OpenSansSemibold";
    }
    & .mb-tlc-home-smallbox {
      & .sub-box-inner {
        & .box-info {
          font-family: "OpenSansRegular";
          & .info-highlight {
            font-family: "OpenSansSemibold";
          }
        }
      }
      &.mb-property-full-tlc {
        & .sub-box-inner {
          & .box-info {
            font-family: "OpenSansBold";
            & .info-highlight {
              font-family: "OpenSansBold";
            }
          }
        }
      }
    }
  }

  /********home page End*****/

  /********property detail page start*****/
  & .breadcrumb {
    a {
      font-family: "OpenSansSemibold";
    }
  }
  & .property-description {
    & h1 {
      font-family: "OpenSansSemibold";
    }
    & .property-price {
      font-family: "OpenSansBold";
    }
    /* & .property-address {
			& .sub-address {
				font-family: 'OpenSansSemibold';
			}
		} */
    & .utility-list {
      & .utility-text {
        & .hilight-text {
          font-family: "OpenSansBold";
        }
      }
    }
  }
  & .property-details-main {
    & .sidebar-right {
      & .sidebar-com-box {
        & .sidebar-rt-cost {
          &.listing-cost {
            font-family: "OpenSansRegular";
          }
        }
        & .affordability-details {
          & .tlc-cost {
            font-family: "GothamOfficeRegular";
            &.final-tlc-cost {
              font-family: "GothamOfficeBold";
            }
          }
          & .tlc-calculat-label {
            font-family: "OpenSansBold";
          }
        }
        & .detail-agent-info {
          & .agent-details {
            & .agent-name {
              font-family: "OpenSansBold";
              & a {
                font-family: "OpenSansBold";
              }
            }
          }
        }
      }
    }
    & .accordion-listing {
      & .card-body {
        & .property-accordion-content {
          & .mortgage-list {
            & .mortgage-cost {
              font-family: "OpenSansSemibold";
            }
          }
        }
      }
    }
    & .accordion-see-all {
      font-family: "OpenSansSemibold";
    }
  }
  & .card-header {
    & .header-name {
      font-family: "OpenSansRegular";
    }
  }
  & .type-select {
    & .react-select__control {
      & .react-select__single-value {
        font-family: "OpenSansRegular";
      }
    }
  }
  & .gallery-type3 {
    & .prop-open-house {
      & .open-house-status {
        font-family: "OpenSansBold";
      }
    }
  }
  & .gallery-box {
    & .view-photo {
      & .theme-btn {
        font-family: "OpenSansRegular";
      }
    }
    & .prop-status {
      & .status-active {
        font-family: "OpenSansLight";
      }
      & .gl-active {
        font-family: "OpenSansLight";
      }
    }
    & .prop-open-house {
      & .open-house-status {
        font-family: "OpenSansBold";
      }
    }
  }
  & .pre-approv-btn {
    & .theme-btn {
      font-family: "OpenSansBold";
    }
  }
  & .tlc-panel-title {
    font-family: "OpenSansSemibold";
  }
  & .panel-title-box {
    font-family: "OpenSansSemibold";
    & .category-amount {
      font-family: "OpenSansRegular";
      &.category-final-amount {
        font-family: "OpenSansSemibold";
      }
    }
  }
  & .calculate-your-tlc {
    & .theme-btn {
      font-family: "OpenSansSemibold";
    }
  }
  & .blog-mid-content {
    &.inner-blog-mid-content {
      & .blog-full-detail {
        & .content-title {
          font-family: "OpenSansBold";
        }
      }
    }
  }
  & .tlc-calculat-edit {
    & h3 {
      font-family: "OpenSansSemibold";
    }
    & label {
      font-family: "OpenSansLight";
      &.calculat-label {
        font-family: "OpenSansRegular";
      }
      &.tlc-calculat-label {
        font-family: "OpenSansBold";
      }
    }
    & .est-cost {
      font-family: "OpenSansLight";
    }
    & .final-cost {
      & [type="text"] {
        font-family: "OpenSansRegular";
      }
    }
    & .tlc-cost {
      font-family: "GothamOfficeRegular";
      &.final-tlc-cost {
        font-family: "GothamOfficeBold";
      }
    }
  }
  & .cost-calculat-edit {
    & h3 {
      font-family: "OpenSansSemibold";
    }
    & label {
      font-family: "OpenSansRegular";
    }
    & .final-cost {
      & [type="text"] {
        font-family: "OpenSansRegular";
      }
    }
    & .final-total-cost {
      font-family: "OpenSansSemibold";
    }
  }
  & .chat-window {
    & .chat-profile-round {
      font-family: "OpenSansSemibold";
    }
  }
  & .mb-prop-open-house {
    font-family: "OpenSansBold";
  }
  /********property detail page start*****/

  /********realtor page start*****/
  & .agent-dt-box {
    & .agent-dt-right {
      & .agent-name {
        font-family: "OpenSansBold";
        & a {
          font-family: "OpenSansBold";
        }
      }
    }
  }
  & .find-agent-result {
    & h3 {
      font-family: "OpenSansSemibold";
    }
  }
  & .find-agent-filter {
    & .nav-tabs {
      & .nav-link {
        &.active {
          font-family: "OpenSansSemibold";
        }
      }
    }
  }
  /********realtor page End*****/

  /********accordion Start*****/
  & .accordion-listing {
    & .card-header {
      & .header-name {
        & .office-name {
          font-family: "OpenSansBold";
        }
        & .office-address {
          font-family: "OpenSansRegular";
        }
      }
    }
    & .card-body {
      & .property-accordion-content {
        & .tlc-calculat-edit {
          &.mortgage-calculat {
            & label {
              font-family: "OpenSansRegular";
            }
          }
        }
        & .mortgage-chart {
          & .highcharts-title {
            & tspan {
              &:first-child {
                font-family: "OpenSansSemibold";
              }
              &:last-child {
                font-family: "OpenSansLight";
              }
            }
          }
        }
      }
      & .update-note {
        font-family: "OpenSansRegular";
      }
      & .update-note-btn {
        font-family: "OpenSansSemibold";
      }
    }
  }
  & .budget-chart {
    & .highcharts-title {
      & .budget-price {
        font-family: "OpenSansBold";
      }
      & .cost-living {
        font-family: "OpenSansSemibold";
      }
      & .border-bottom {
        font-family: "OpenSansBold";
      }
    }
  }
  /********accordion End*****/

  /********Search page Start*****/
  & .search-bar-panel {
    & .dropdown {
      & .dropdown-toggle {
        font-family: "OpenSansRegular";
      }
      & .dropdown-menu {
        font-family: "OpenSansRegular";
      }
    }
    & .com-panel-box {
      & .reset-button {
        font-family: "OpenSansRegular";
      }
    }
    & .price-range-control {
      & [type="text"] {
        font-family: "OpenSansRegular";
      }
    }
    & .prop-save-searh-button {
      & .theme-btn {
        font-family: "OpenSansSemibold";
      }
    }
  }
  & .more-filter-panel {
    & .more-filter-container {
      & [type="text"] {
        font-family: "OpenSansRegular";
      }
      & .matching-listing-bg {
        font-family: "OpenSansSemibold";
      }
    }
  }
  & .search-container {
    & .right-wrapper {
      & .hide-map-view,
      & .remove-line {
        & .theme-btn {
          font-family: "OpenSansRegular";
        }
      }
    }
  }
  /********Search page End*****/

  /********agentprofile page Start*****/
  & .horizontal-prop-card {
    & .current-prop-box {
      & .current-prop-dt {
        & .current-pro-address {
          font-family: "OpenSansSemibold";
        }
        & .current-pro-price {
          font-family: "OpenSansBold";
        }
      }
      & .current-prop-right {
        & .current-tlc-amount {
          font-family: "OpenSansRegular";
        }
      }
    }
  }
  & .agent-detail-bg {
    & .agent-biodata-left {
      & h2 {
        font-family: "OpenSansSemibold";
      }
      & h3 {
        font-family: "OpenSansLight";
      }
    }
    & .small-sub-title {
      font-family: "OpenSansBold";
    }
  }
  /********agentprofile page End*****/

  /********neighborhood detail page Start*****/
  & .neighborhood-detail {
    & .neighborhood-state-info {
      & h2 {
        font-family: "OpenSansSemibold";
      }
      & .state-utility-list {
        & .state-utility-text {
          & .hilight-text {
            font-family: "OpenSansBold";
          }
        }
      }
    }
    & .tab-list {
      & .nav-tabs {
        & .nav-link {
          font-family: "OpenSansSemibold";
        }
      }
      & .tab-content {
        & .nav-tabs {
          & .nav-link {
            font-family: "OpenSansRegular";
          }
        }
      }
    }
    & .average-score {
      & .mortgage-list {
        & .mortgage-name {
          font-family: "OpenSansBold";
        }
      }
    }
    & .sign-up-neighborhood {
      font-family: "OpenSansSemibold";
    }
  }
  /********neighborhood detail page End*****/

  /********Profile Agent page Start*****/
  & .table-result-title {
    font-family: "OpenSansBold";
  }
  /********Dashboard Start*****/
  & .last-top-comments {
    & .chat-right,
    & .chat-left {
      & .chat-box {
        & .comments-box {
          & .profile-name {
            font-family: "OpenSansSemibold";
          }
          & .profile-text {
            font-family: "OpenSansRegular";
          }
        }
      }
    }
    & .view-more-comment {
      font-family: "OpenSansRegular";
    }
  }
  & .dash-profile-left {
    & .dash-profile-name {
      font-family: "OpenSansRegular";
      & .dash-profile-fullname {
        font-family: "OpenSansSemibold";
      }
    }
  }
  & .left-content-box {
    & .affordability-titel {
      font-family: "OpenSansRegular";
    }
  }
  & .affordability-list {
    & .affordability-name {
      font-family: "OpenSansBold";
    }
  }
  /********dashboard right start*****/
  & .dashboard-right {
    & [type="text"] {
      font-family: "OpenSansRegular";
    }
    /********dashboard tab start*****/
    & .tab-list {
      & .nav-tabs {
        & .nav-link {
          font-family: "OpenSansSemibold";
        }
      }
    }
    /********dashboard tab End*****/
    /********timeline left start*****/
    & .timeline-left-col {
      & .timeline-short-name {
        & .timeline-name-box {
          font-family: "OpenSansSemibold";
        }
      }
    }
    /********timeline left End*****/
    /********timeline right start*****/
    & .timeline-right-col {
      & .mb-timeline-left-col {
        & .timeline-short-name {
          & .timeline-name-box {
            font-family: "OpenSansSemibold";
          }
        }
      }
      & .timeline-create-info {
        & .timeline-create {
          font-family: "OpenSansSemibold";
          & .create-item {
            font-family: "OpenSansRegular";
          }
          & .last-active-time {
            font-family: "OpenSansRegular";
          }
        }
      }
      & .timeline-comment-bg {
        & .last-top-comments {
          & .chat-profile-round {
            font-family: "OpenSansSemibold";
          }
        }
      }
    }
    /********timeline right End*****/
    /********account info start*****/
    & .account-detail {
      & .signal-switch {
        font-family: "OpenSansRegular";
      }
      & .price-range {
        font-family: "OpenSansSemibold";
      }
    }
    & .commute-calculat-edit {
      font-family: "OpenSansLight";
    }
    & .clients-info {
      & .clients-name {
        & .clients-fullname {
          font-family: "OpenSansBold";
          & .client-last-logged {
            font-family: "OpenSansRegular";
          }
        }
      }
    }
    & .agent-client-detail {
      & .detail-title {
        font-family: "OpenSansRegular";
      }
    }
  }
  /********dashboard right End*****/
  & .contactus-small-label {
    font-family: "OpenSansSemibold";
  }
  /***********************************Dashboard End**********************************/

  /********Blog Start*****/
  & .blog-banner-sub-title {
    font-family: "OpenSansLight";
  }
  & .blog-card {
    & .blog-card-info {
      & .blog-header {
        font-family: "OpenSansBold";
      }
    }
    & .blog-short-content {
      & .blog-ct-more {
        & a {
          font-family: "OpenSansBold";
        }
      }
    }
  }
  & .blog-comments-content {
    & .blog-comments-listing {
      & .comments-number-title {
        font-family: "OpenSansSemibold";
      }
      & .comments-reply-bt {
        & a {
          font-family: "OpenSansBold";
        }
      }
    }
  }
  & .MuiTypography-body2 {
    font-family: "OpenSansRegular";
  }
  /********Blog End*****/

  /********Manage start*****/
  & .manage-card {
    & .card-prop-top {
      font-family: "OpenSansSemibold";
    }
    & .card-prop-footer {
      font-family: "OpenSansSemibold";
    }
  }
  & .table-top-title {
    font-family: "OpenSansRegular";
  }
  & .save-search-table-header {
    & .filter-title {
      font-family: "OpenSansSemibold";
    }
  }
  & .white-card-content {
    & .content-item-text {
      font-family: "OpenSansRegular";
    }
  }
  & .pd-loader-text-t1 {
    font-family: "OpenSansSemibold";
  }
  & .pd-loader-text-t2 {
    font-family: "OpenSansLight";
  }
  & .compare-cost-header {
    & .highlight-text {
      font-family: "OpenSansSemibold";
    }
  }
  & .compare-result-info {
    & .compare-result-price {
      font-family: "OpenSansSemibold";
    }
    & .compare-result-city {
      font-family: "OpenSansSemibold";
    }
  }
  & .compare-city-box {
    & .compare-city-name {
      font-family: "OpenSansSemibold";
    }
    & .compare-last-amount {
      font-family: "OpenSansSemibold";
    }
  }
  & .more-tlc-price-filter {
    & .prop-price-filter {
      & .dropdown {
        & .dropdown-toggle {
          font-family: "OpenSansRegular";
        }
      }
    }
  }
  & .more-commute-filter {
    & .dropdown {
      & .dropdown-toggle {
        font-family: "OpenSansRegular";
      }
    }
    & .rbt-input-main {
      font-family: "RobotoRegular" !important;
    }
  }
  & .agent-listing-info {
    & .tab-list {
      & .nav-tabs {
        & .nav-link {
          font-family: "OpenSansSemibold";
        }
      }
    }
  }
  & .profile-location-dropdown {
    & .rbt-input-main {
      font-family: "RobotoRegular";
    }
  }
  & .joyride-tooltip__header {
    font-family: "OpenSansBold";
  }
  & .joyride-tooltip__button {
    &.joyride-tooltip__button--skip {
      font-family: "OpenSansRegular";
    }
    &.joyride-tooltip__button--secondary {
      font-family: "OpenSansRegular";
    }
  }
  & .widget-card {
    & h2 {
      font-family: "OpenSansRegular";
    }
    & .numeric-input-box {
      & .form-control {
        font-family: "OpenSansRegular";
      }
    }
  }
  & .expenses-cost-header {
    & .expenses-content {
      font-family: "OpenSansSemibold";
    }
  }

  & .tlc-note {
    font-family: "OpenSansRegular";
  }

  & .journey-left {
    & .sidebar-journey-title {
      font-family: "OpenSansSemibold";
    }
  }
  & .journey-content {
    & h3 {
      font-family: "OpenSansSemibold";
    }
    & h4 {
      font-family: "OpenSansSemibold";
    }
    & .journey-checkbox-fill {
      & .journey-checkbox-title {
        font-family: "OpenSansSemibold";
      }
      & .journey-checkbox-subtitle {
        font-family: "OpenSansRegular";
      }
    }
    & .journey-radio-fill {
      & .journey-icon-wrap {
        & .journey-radio-bold-title {
          font-family: "OpenSansSemibold";
        }
      }
    }
    & .move-range-box {
      & .input-range__track {
        & .input-range__label--value {
          & .input-range__label-container {
            font-family: "OpenSansBold";
          }
        }
      }
      & .input-range {
        & .input-range__label--min {
          & .input-range__label-container {
            font-family: "OpenSansRegular";
          }
        }
        & .input-range__label--max {
          & .input-range__label-container {
            font-family: "OpenSansRegular";
          }
        }
      }
    }
    & .month-range-info {
      font-family: "OpenSansSemibold";
    }
    & .bold-title {
      font-family: "OpenSansSemibold";
    }
    & .mortgage-types-chart {
      & .circular-chart {
        & .percentage {
          font-family: "OpenSansSemibold";
        }
      }
    }
    & .pdf-download-link {
      & .pdf-dn-name {
        font-family: "OpenSansSemibold";
      }
    }
  }
  & .leaflet-popup-content {
    & .poi_tooltip {
      & .poi-header-name {
        font-family: "OpenSansSemibold";
      }
    }
  }
  & .home-banner2-bg {
    & .header-search-filter {
      & .filter-mid-box {
        & .tlc-range-box {
          & .tlc-range-title {
            font-family: "OpenSansLight";
          }
          & .input-range__label--min {
            & .input-range__label-container {
              font-family: "OpenSansSemibold";
            }
          }
          & .input-range__label--max {
            & .input-range__label-container {
              font-family: "OpenSansSemibold";
            }
          }
        }
      }
    }
    & .header-titles-box {
      & .banner-title {
        font-family: "OpenSansBold";
      }
    }
    & .filter-find-button {
      & button {
        font-family: "OpenSansLight";
      }
    }
  }
  &.with-submenu {
    & .short-menubox {
      & .short-menu-link {
        & .nav-link {
          font-family: "OpenSansRegular";
          &.active {
            font-family: "OpenSansBold";
          }
        }
      }
    }
  }
  & .block-image-subheader {
    & .section-content {
      & .block-title {
        font-family: "OpenSansLight";
      }
      & .block-subtitle {
        font-family: "OpenSansSemibold";
      }
    }
  }
  & .landing-page-mid {
    & .landing-mid-content {
      & .landing-mid-title {
        font-family: "OpenSansLight";
      }
      & .heading-sub-text {
        font-family: "OpenSansRegular";
      }
    }
    & .patternbg-card-content {
      & h1 {
        font-family: "OpenSansLight";
      }
      & .more-listing {
        & a {
          font-family: "OpenSansLight";
        }
      }
    }
  }
  & .question-card-patternbg {
    & .question-name {
      font-family: "OpenSansLight";
    }
  }
  & .footer-otherstyle {
    & .footer-bg-color {
      font-family: "OpenSansLight";
    }
  }
  & .footer-nav-box {
    & .footer-nav {
      & a {
        font-family: "OpenSansLight";
      }
    }
  }
  & .modal-content {
    & .modal-header {
      font-family: "OpenSansRegular";
      & h1 {
        font-family: "OpenSansRegular";
      }
      & h2 {
        font-family: "OpenSansRegular";
      }
    }
    & .modal-body {
      font-family: "OpenSansRegular";
      & [type="text"] {
        font-family: "OpenSansLight";
      }
      & .typeahead-search {
        & .form-control {
          font-family: "OpenSansRegular";
        }
      }
      & h2 {
        font-family: "OpenSansRegular";
      }
      & .sub-label {
        font-family: "OpenSansLight";
      }
      & .modal-small-title {
        font-family: "OpenSansSemibold";
      }
      .MuiTypography-body2 {
        font-family: "OpenSansRegular";
      }
      & .tlc-calculat-edit {
        & h3 {
          font-family: "OpenSansSemibold";
        }
        & label {
          font-family: "OpenSansLight";
          &.calculat-label {
            font-family: "OpenSansRegular";
          }
          &.tlc-calculat-label {
            font-family: "OpenSansBold";
          }
        }
        & .est-cost {
          font-family: "OpenSansLight";
        }
        & .final-cost {
          & [type="text"] {
            font-family: "OpenSansRegular";
          }
        }
        & .tlc-cost {
          font-family: "OpenSansRegular";
          &.final-tlc-cost {
            font-family: "GothamOfficeBold";
          }
        }
      }
      & .est-payment-text {
        color: #7b8aa0;
      }
      & .approved-name {
        font-family: "OpenSansBold";
      }
      & .commute-calculat-edit-popup {
        font-family: "OpenSansRegular";
        & .commute-result {
          & .commute-name {
            & .commute-address {
              font-family: "OpenSansSemibold";
            }
          }
        }
      }
      & .contact-agent-disclaimer-cnt {
        font-family: "OpenSansRegular";
      }
      & .widget-blur-loader {
        font-family: "OpenSansRegular" !important;
      }
    }
  }
}
/*****************************************/
.modal-content {
  & .modal-body {
    .MuiTypography-body2 {
      font-family: "GothamBook";
    }
    & .tlc-calculat-edit {
      & h3 {
        font-family: "GothamMedium";
      }
      & label {
        font-family: "GothamLight";
        &.calculat-label {
          font-family: "GothamBook";
        }
        &.tlc-calculat-label {
          font-family: "GothamBold";
        }
      }
      & .est-cost {
        font-family: "GothamLight";
      }
      & .final-cost {
        & [type="text"] {
          font-family: "GothamBook";
        }
      }
      & .tlc-cost {
        font-family: "GothamOfficeRegular";
        &.final-tlc-cost {
          font-family: "GothamOfficeBold";
        }
      }
    }
    & .est-payment-text {
      color: #7b8aa0;
    }
    & .approved-name {
      font-family: "GothamBold";
    }
    & .commute-calculat-edit-popup {
      font-family: "GothamBook";
      & .commute-result {
        & .commute-name {
          & .commute-address {
            font-family: "GothamMedium";
          }
        }
      }
    }
    & .contact-agent-disclaimer-cnt {
      font-family: "GothamOfficeRegular";
    }
    & .property-description {
      & h3 {
        font-size:18px;
      }
    }
  }
}

/***********************************Gotham End*********************/

img {
  width: 100%;
}
.cursor-point {
  cursor: pointer;
}

.status-active {
  border-radius: 11px;
  padding: 3px 10px;
  font-size: 8px;
  display: inline-block;
  line-height: normal;
  vertical-align: middle;
}
/*****************section start**************/
section {
  width: 100%;
  display: flex;
}
.section-title {
  & h1 {
    font-size: 26px;
    @include breakpointMax(1023) {
      font-size: 24px;
    }
    @include breakpointMax(767) {
      font-size: 22px;
    }
    @include breakpointMax(480) {
      font-size: 20px;
    }
  }
  & .heading-sub-text {
    padding-top: 14px;
    line-height: 25px;
    font-size: 15px;
    display: inline-block;
    & a {
      &:hover {
        text-decoration: underline;
      }
    }
    & ul {
      margin: 0 0 0 15px;
      padding: 0;
      & li {
        margin: 0 0 10px 0;
        padding: 0;
        line-height: normal;
      }
    }
    @include breakpointMax(630) {
      padding-bottom: 20px;
    }
  }
  & .heading-sub-line {
    padding-top: 0;
    line-height: 25px;
    font-size: 15px;
    display: inline-block;
    & a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.white-card-cnt-box {
  width: 100%;
  display: block;
  padding: 0 20px;
}
.white-card-content {
  background: #fff;
  border-radius: 8px;
  padding: 37px 0px 30px 0px;
  width: 100%;
  display: block;
  & .container {
    max-width: 100%;
    padding: 0 50px;
    @include breakpointMax(1367) {
      padding: 0 15px;
    }
    & .row {
      @include breakpointMax(1367) {
        margin-left: -15px;
        margin-right: -15px;
      }
    }
  }
  & .content-item-text {
    font-size: 15px;
    width: 100%;
    display: block;
  }
}

/*****************section End**************/

/*****************Overflow hidden scroll start**************/
.mb-no-scroll {
  position: fixed;
}
/*****************Overflow hidden scroll End**************/
[type="button"] {
  border: none;
  outline: none;
  &:focus {
    outline: none;
  }
}
/*****************checkbox start**************/
.common-checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 13px;
  font-weight: normal;
  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    appearance: none;
    &:checked {
      + {
        & .checkmark {
          background: #00adbb;
          border: 2px solid #00adbb;
          &:after {
            display: block;
          }
        }
      }
    }
  }
  & .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background: #ffffff;
    border: 2px solid #757575;
    border-radius: 3px;
    &:after {
      left: 5px;
      top: 1px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      content: "";
      position: absolute;
      display: none;
    }
  }
}
/*****************checkbox End**************/

/*****************radio-option start**************/
.radio-option-beds {
  //height:34px;
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 4px;
  & label {
    width: 12.5%;
    font-size: 14px;
    border-left: 1px solid #dddddd;
    min-height: 32px;
    text-align: center;
    line-height: 32px;
    margin-bottom: 0px;
    cursor: pointer;
    & .beds-input-hidden {
      &:checked {
        + {
          & .beds-radio-fill {
            background: #00adbb;
            color: #fff;
            min-height: 32px;
          }
        }
      }
    }
    &:first-child {
      border: none;
      & .beds-input-hidden {
        &:checked {
          + {
            & .beds-radio-fill {
              border-radius: 4px 0 0 4px;
            }
          }
        }
      }
    }
    &:last-child {
      & .beds-input-hidden {
        &:checked {
          + {
            & .beds-radio-fill {
              border-radius: 0 4px 4px 0;
            }
          }
        }
      }
    }
  }
  &.option-5 {
    & label {
      width: 20%;
      font-size: 13px;
      @include breakpointMax(990) {
        font-size: 11px;
      }
    }
  }
  &.option-7 {
    & label {
      width: 14.28%;
    }
  }
  &.option-2 {
    & label {
      width: 50%;
    }
  }
  &.option-3 {
    & label {
      width: 33.33%;
    }
  }
  &.option-auto {
    display:flex;
    flex-flow:row nowrap;
    justify-content:flex-start;
    align-items:stretch;
    border:none ;
    & label {
      display:flex;
      line-height:normal;
      border:1px solid #DDDDDD;
      word-break: break-word;
      font-size:14px;
      @include breakpointMax(910){
        font-size:13px;
      }
      @include breakpointMax(768){
        font-size:14px;
      }
      &:first-child {
          border-radius:4px 0 0 4px;
          border-right: none;
      }
      &:last-child {
          border-radius:0 4px 4px 0;
          border-left: none;
      }
      & .beds-radio-fill {
          display:flex;
          justify-content:center;
          align-items:center;
          width:100%;
          height:100%;
          padding:10px 10px;
          @include breakpointMax(910){
            padding:10px 5px;
          }
          @include breakpointMax(768){
            padding:10px 10px;
          }
      }
      &.option-3 {
        & label {
          width:33.33%;
        }
      }
    }
    @include breakpointMax(768){
        width:100%;
    }
}
}
/*****************radio-option start**************/
/********************switchbox Start*************/
.switch-box {
  float: right;
  input[type="checkbox"] {
    &:checked {
      + {
        label {
          &:before {
            /* background:#88d8df; */
            opacity: 0.5;
            box-shadow: inherit;
          }
          &:after {
            background: inherit;
            left: 25px;
          }
        }
      }
    }
  }
  & label {
    cursor: pointer;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 40px;
    &:before {
      background: #d8d8d8;
      border-radius: 6px;
      content: "";
      height: 13px;
      margin-top: -7px;
      position: absolute;
      transition: all 0.4s ease-in-out;
      width: 40px;
    }
    &:after {
      background: rgb(255, 255, 255);
      border-radius: 16px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
      content: "";
      height: 16px;
      left: -4px;
      margin-top: -7px;
      position: absolute;
      top: -1px;
      transition: all 0.3s ease-in-out;
      width: 16px;
    }
  }
  & .open-switchbox-bg {
    background-color: #00adbb;
  }
}
/********************switchbox End*************/
/********************accordion start*************/
.accordion-listing {
  width: 100%;
  float: left;
  & .card {
    margin-bottom: 25px;
    width: 100%;
    border: none;
    border-radius: 8px !important;
    box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
  }
  & .card-body {
    padding: 20px 20px 20px 20px;
    @include breakpointMax(767) {
      padding: 20px 20px 20px 20px;
    }
    & .property-accordion-content {
      padding: 0px 20px 15px 20px;
      width: 100%;
      display: block;
      margin-top: -20px;
      @include breakpointMax(1024) {
        padding: 0px 0px 15px 0px;
      }
    }
  }
  /*************card header *******************/
  & .card-header {
    background: #fff;
    border-radius: 8px !important;
    border: none;
    padding: 0px;
    & .header-name {
      border: none;
      padding: 30px 40px;
      background: #fff;
      width: 100%;
      text-align: left;
      position: relative;
      font-size: 20px;
      @include breakpointMax(1190) {
        padding: 20px 20px;
      }
      &:after {
        position: absolute;
        top: 33px;
        right: 40px;
        font-size: 15px;
        font-family: "Material Icons";
        content: url(../img/nd/arrow-down.png);
        @include breakpointMax(1190) {
          right: 22px;
          top: 22px;
        }
      }
      @include breakpointMax(1024) {
        font-size: 17px;
        padding: 25px 20px;
        &:after {
          top: 26px;
          right: 22px;
        }
      }
      @include breakpointMax(767) {
        font-size: 15px;
      }
      & .sub-header-name {
        color: #bec4cb;
        font-size: 14px;
        display: block;
        padding-top: 5px;
      }
      &:active,
      &:focus {
        border: none;
        outline: none;
      }
      &.open {
        &::after {
          display: none;
        }
        &::before {
          position: absolute;
          top: 33px;
          right: 40px;
          font-size: 15px;
          font-family: "Material Icons";
          content: url(../img/nd/arrow-up.png);
          @include breakpointMax(1190) {
            right: 22px;
            top: 22px;
          }
          @include breakpointMax(1025) {
            right: 22px;
            top: 26px;
          }
          @include breakpointMax(767) {
            right: 20px;
            top: 21px;
          }
        }
      }
    }
  }
  /* & .collapse {
		&.show {
			&::before {
				position: absolute;
				top:20px;
				right:20px;
				font-size:15px;
				font-family: 'Material Icons';
				content:url(../img/nd/arrow-up.png);
				@include breakpointMax(1190) {
					right:12px;
				}
			}
		}
	} */
}
/********************accordion end**********************/
.inner-header {
  width: 100%;
  display: block;
  padding-top: 110px;
  @include breakpointMax(1025) {
    padding-top: 50px;
  }
  &.compact-header {
    padding-top: 30px;
  }
}

/*****************common css End**************/

/*****************************home page start**********************************************************/
.banner-title {
  font-size: 35px;
  @include breakpointMax(1024) {
    font-size: 35px;
  }
  @include breakpointMax(767) {
    font-size: 24px;
  }
}
.banner-sub-title {
  font-size: 26px;
  padding-top: 30px;
  padding-bottom: 40px;
  line-height: 40px;
  @include breakpointMax(1024) {
    font-size: 22px;
  }
  @include breakpointMax(767) {
    font-size: 17px;
    line-height: 30px;
  }
  @include breakpointMax(320) {
    font-size: 14px;
  }
  & span {
    display: block;
    /* padding-top:12px; */
  }
}

/*******banner Background start***************/
.home-banner-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  /*background-image:url(../img/nd/header-bg.jpg);*/
  position: relative;
  width: 100%;
  display: block;
  min-height: 800px;
  padding-top: 110px;
  &.no-img {
    background-color: #f7f7f7;
    min-height: 450px;
  }
  &.home-banner-compact {
    padding-top: 30px;
    min-height: 324px;
  }
  @include breakpointMax(1230) {
    min-height: 670px;
    padding-top: 90px;
    &.no-img {
      min-height: 450px;
    }
    &.home-banner-compact {
      min-height: 324px;
    }
  }
  @include breakpointMax(1199) {
    &.no-img {
      &.with-ad {
        min-height: 545px;
      }
      &.home-banner-compact {
        min-height: 320px;
        &.with-ad {
          min-height: 485px;
        }
      }
    }
  }
  @include breakpointMax(1160) {
    &.no-img {
      &.with-ad {
        min-height: 545px;
      }
      &.home-banner-compact {
        min-height: 320px;
        &.with-ad {
          min-height: 485px;
        }
      }
    }
  }
  @include breakpointMax(1024) {
    min-height: 570px;
    padding-top: 63px;
    &.no-img {
      min-height: 340px;
      &.with-ad {
        min-height: 524px;
      }
      &.home-banner-compact {
        min-height: 320px;
        &.with-ad {
          min-height: 485px;
        }
      }
    }
  }
  @include breakpointMax(767) {
    &.no-img {
      &.with-ad {
        min-height: 545px;
      }
      &.home-banner-compact {
        min-height: 350px;
        &.with-ad {
          min-height: 514px;
        }
      }
    }
  }
  @include breakpointMax(480) {
    min-height: 400px;
    padding-top: 50px;
    &.no-img {
      min-height: 340px;
      &.with-ad {
        min-height: 380px;
      }
      &.home-banner-compact {
        min-height: 350px;
        &.with-ad {
          min-height: 350px;
        }
      }
    }
  }
  /*******banner filter ***************/
  & .home-autosuggest-search {
    & .typeahead-search {
      /* width: calc(100% - 126px); */
      width: calc(100% - 180px);
      box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
      margin-right: 16px;
      @include breakpointMax(767) {
        width: 100%;
        margin-right: 0px;
      }
    }
    & .filter-button {
      min-height: 55px;
      min-width: 110px;
      max-width: 156px;
      vertical-align: top;
      font-size: 17px;
      @include breakpointMax(767) {
        min-height: 50px;
        padding: 10px 10px;
        font-size: 14px;
        width: 110px;
      }
    }
  }

  & .home-header-adds-wrapper {
    width: 302px;
    position: absolute;
    top: 110px;
    right: 100px;
    @include breakpointMax(1439) {
      right: 40px;
    }
    @include breakpointMax(1300) {
      right: 20px;
    }
    /* @include breakpointMax(1199) {
			top: 20px;
			right: 20px;
		} */
    @include breakpointMax(1199) {
      top: inherit;
      right: inherit;
      left: calc(50% - 151px);
      bottom: 100px;
    }
    @include breakpointMax(1024) {
      top: inherit;
      right: inherit;
      left: calc(50% - 151px);
      bottom: 50px;
    }
    @include breakpointMax(767) {
      top: inherit;
      right: inherit;
      left: calc(50% - 151px);
      bottom: 20px;
    }
    @include breakpointMax(480) {
      display: none;
    }
    & .home-header-adds {
      border: 1px solid rgb(178, 178, 178);
      width: 302px;
      height: 152px;
      & .home-header-adds-box {
        width: 100%;
        height: 50px;
      }
      & .home-header-adds-box-full {
        width: 100%;
        height: 150px;
      }
    }
  }

  &.no-img.with-ad {
    & .home-header-adds-wrapper {
      @include breakpointMax(1199) {
        bottom: 10px;
      }
    }
  }

  &.home-banner-compact {
    & .home-header-adds-wrapper {
      top: 55px;
      @include breakpointMax(1199) {
        top: inherit;
        bottom: 10px;
      }
    }
  }

  &.no-title {
    min-height: 84px !important;
  }
}

/*******banner Background End***************/

/*****************************TLC Help start***********************************/
.tlc-how-work {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  @include breakpointMax(1450) {
    overflow: hidden;
  }
  & .work-text {
    float: left;
    width: 100%;
    margin-top: 40px;
    @include breakpointMax(767) {
      position: relative;
      top: inherit;
      left: inherit;
      padding-top: 50px;
    }
    @include breakpointMax(480) {
      padding-top: 20px;
      padding-bottom: 30px;
    }
  }
  & .desktop-img-banner {
    position: relative;
    height: 429px;
    width: 1450px;
    float: none;
    margin: 60px auto 0;
    display: inline-block;
    @include breakpointMax(1450) {
      width: 100%;
      height: 445px;
    }
    @include breakpointMax(1400) {
      height: 435px;
      margin: 60px auto 0;
    }
    @include breakpointMax(1350) {
      height: 415px;
    }
    @include breakpointMax(1300) {
      height: 405px;
    }
    @include breakpointMax(1230) {
      height: 383px;
    }
    @include breakpointMax(1150) {
      height: 352px;
    }
    @include breakpointMax(1100) {
      height: 342px;
    }
    @include breakpointMax(1024) {
      height: 315px;
    }
    @include breakpointMax(991) {
      display: none;
    }
    & .tlc-price-amount {
      position: absolute;
      left: 46%;
      bottom: 117px;
      width: 174px;
      text-align: center;
      height: 50px;
      font-size: 20px;
      padding: 3px 5px;
      line-height: 40px;
      z-index: 10;
      @include breakpointMax(1450) {
        bottom: 136px;
        height: 46px;
      }
      @include breakpointMax(1440) {
        bottom: 140px;
        height: 42px;
        line-height: 35px;
      }
      @include breakpointMax(1423) {
        bottom: 140px;
      }
      @include breakpointMax(1400) {
        bottom: 135px;
      }
      @include breakpointMax(1380) {
        bottom: 139px;
      }
      @include breakpointMax(1366) {
        bottom: 144px;
      }
      @include breakpointMax(1350) {
        bottom: 136px;
        width: 164px;
        height: 41px;
      }
      @include breakpointMax(1300) {
        bottom: 132px;
        width: 154px;
        height: 40px;
      }
      @include breakpointMax(1299) {
        bottom: 131px;
      }
      @include breakpointMax(1280) {
        bottom: 140px;
      }
      @include breakpointMax(1230) {
        bottom: 122px;
      }
      @include breakpointMax(1210) {
        bottom: 130px;
        width: 147px;
        height: 36px;
      }
      @include breakpointMax(1190) {
        height: 36px;
        bottom: 130px;
        line-height: 35px;
        font-size: 19px;
      }
      @include breakpointMax(1175) {
        bottom: 135px;
      }
      @include breakpointMax(1150) {
        bottom: 111px;
      }
      @include breakpointMax(1110) {
        bottom: 116px;
        font-size: 18px;
      }
      @include breakpointMax(1100) {
        width: 135px;
        bottom: 111px;
      }
      @include breakpointMax(1070) {
        bottom: 119px;
        height: 33px;
      }
      @include breakpointMax(1050) {
        bottom: 124px;
        height: 33px;
      }
      @include breakpointMax(1024) {
        bottom: 100px;
        height: 33px;
      }
      @include breakpointMax(1023) {
        bottom: 96px;
        height: 33px;
        line-height: 26px;
      }
      @include breakpointMax(1000) {
        bottom: 100px;
      }
    }
    & .tlchelp-banner {
      position: relative;
      & img {
        width: auto;
        position: relative;
        z-index: 9;
        display: inline-block;
        vertical-align: bottom;
        @include breakpointMax(1450) {
          width: 100%;
          & img {
            width: 100%;
          }
        }
      }
      /*  &:after {
				position:absolute;
				content:'';
				right: calc(100% - 33.45%);
				top: 5px;
				background: linear-gradient(180deg, rgba(0,173,187,0) 0%, rgba(0,173,187,0.17) 100%);
				width:100%;
				height:100%;
				z-index: 9;
				@include breakpointMax(991) {
					display: none;
				} 
				@include breakpointMax(1450) {
					top: 0px;
				}
			}  */
      /* &:before {
				position:absolute;
				content:'';
				left:calc(100% - 33.36%);
				top:5px;
				background: linear-gradient(180deg, rgba(34,91,118,0) 0%, rgba(34,91,118,0.31) 100%);
				width:100%;
				height:100%;
				z-index: 9;
				@include breakpointMax(991) {
					display:none;
				} 
				@include breakpointMax(1450) {
					top: 0px;
				}
			} */
      & .banner-left-gradient {
        position: absolute;
        left: calc(100% - 33.36%);
        top: 5px;
        background: linear-gradient(
          180deg,
          rgba(34, 91, 118, 0) 0%,
          rgba(34, 91, 118, 0.31) 100%
        );
        width: 100%;
        height: 100%;
        z-index: 9;
        @include breakpointMax(991) {
          display: none;
        }
        @include breakpointMax(1450) {
          top: 0px;
        }
      }
      & .banner-right-gradient {
        position: absolute;
        right: calc(100% - 33.45%);
        top: 5px;
        background: linear-gradient(
          180deg,
          rgba(0, 173, 187, 0) 0%,
          rgba(0, 173, 187, 0.17) 100%
        );
        width: 100%;
        height: 100%;
        z-index: 9;
        @include breakpointMax(991) {
          display: none;
        }
        @include breakpointMax(1450) {
          right: calc(100% - 33.31%);
        }
        @include breakpointMax(1450) {
          top: 0px;
        }
      }
    }
    & .tlc-mortgage-text {
      position: absolute;
      top: 86px;
      left: 12%;
      font-size: 24px;
      text-transform: uppercase;
      color: #b6c5cf;
      z-index: 10;
      @include breakpointMax(1366) {
        font-size: 22px;
        top: 82px;
      }
      @include breakpointMax(1300) {
        top: 78px;
      }
      @include breakpointMax(1230) {
        top: 72px;
        font-size: 20px;
      }
      @include breakpointMax(1160) {
        font-size: 18px;
        top: 69px;
      }
      @include breakpointMax(1100) {
        top: 65px;
      }
      @include breakpointMax(1024) {
        top: 61px;
        left: 11%;
        font-size: 16px;
      }
      @include breakpointMax(992) {
        font-size: 17px;
      }
    }
    & .tlc-utilities-text {
      position: absolute;
      top: 86px;
      left: 46%;
      font-size: 24px;
      text-transform: uppercase;
      color: #b6c5cf;
      z-index: 10;
      @include breakpointMax(1366) {
        font-size: 22px;
        top: 82px;
      }
      @include breakpointMax(1300) {
        top: 78px;
      }
      @include breakpointMax(1230) {
        top: 72px;
        font-size: 20px;
      }
      @include breakpointMax(1160) {
        font-size: 18px;
        top: 69px;
      }
      @include breakpointMax(1100) {
        top: 65px;
      }
      @include breakpointMax(1024) {
        top: 61px;
        font-size: 16px;
      }
    }
    & .tlc-commute-text {
      position: absolute;
      top: 86px;
      right: 13%;
      font-size: 24px;
      text-transform: uppercase;
      color: #b6c5cf;
      z-index: 10;
      @include breakpointMax(1450) {
        right: 12%;
      }
      @include breakpointMax(1366) {
        font-size: 22px;
        top: 82px;
      }
      @include breakpointMax(1300) {
        top: 78px;
      }
      @include breakpointMax(1230) {
        top: 72px;
        font-size: 20px;
      }
      @include breakpointMax(1160) {
        right: 13%;
        font-size: 18px;
        top: 69px;
      }
      @include breakpointMax(1100) {
        top: 65px;
      }
      @include breakpointMax(1024) {
        top: 61px;
        font-size: 16px;
      }
    }
    & .leftside-banner {
      width: 33.33%;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      z-index: 10;
      /* left one */
      & .leftside-btm-round {
        position: absolute;
        bottom: 115px;
        left: 21%;
        width: 105px;
        @include breakpointMax(1450) {
          bottom: 130px;
          left: 19%;
        }
        @include breakpointMax(1400) {
          bottom: 115px;
          left: 19%;
        }
        @include breakpointMax(1350) {
          bottom: 110px;
          left: 19%;
        }
        @include breakpointMax(1300) {
          bottom: 110px;
          left: 17%;
        }
        @include breakpointMax(1230) {
          bottom: 100px;
          left: 16%;
        }
        @include breakpointMax(1150) {
          bottom: 86px;
          left: 16%;
        }
        @include breakpointMax(1130) {
          bottom: 97px;
          left: 16%;
        }
        @include breakpointMax(1024) {
          bottom: 75px;
        }
        @include breakpointMax(1023) {
          bottom: 84px;
          left: 13%;
        }
        & .round-box {
          width: 97px;
          height: 97px;
          background: #ffffff;
          border-radius: 100%;
          box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
          display: table;
          padding: 0 5px;
          @include breakpointMax(1150) {
            width: 90px;
            height: 90px;
          }
          @include breakpointMax(1130) {
            width: 85px;
            height: 85px;
          }
          @include breakpointMax(1023) {
            width: 80px;
            height: 80px;
          }
          & .round-number {
            display: table-cell;
            vertical-align: middle;
            font-size: 19px;
            @include breakpointMax(1150) {
              font-size: 17px;
            }
            @include breakpointMax(1130) {
              font-size: 16px;
            }
            @include breakpointMax(1023) {
              font-size: 15px;
            }
          }
        }
        & .round-text {
          width: 100%;
          margin-bottom: 6px;
          line-height: 18px;
          font-size: 13px;
        }
      }
      /* left two */
      & .leftside-mid-round {
        position: absolute;
        bottom: 160px;
        left: 65%;
        width: 105px;
        @include breakpointMax(1450) {
          bottom: 174px;
          left: 64%;
        }
        @include breakpointMax(1400) {
          bottom: 170px;
          left: 64%;
        }
        @include breakpointMax(1350) {
          bottom: 160px;
          left: 64%;
        }
        @include breakpointMax(1300) {
          bottom: 153px;
          left: 64%;
        }
        @include breakpointMax(1230) {
          bottom: 138px;
          left: 64%;
        }
        @include breakpointMax(1150) {
          bottom: 124px;
          left: 62%;
        }
        @include breakpointMax(1130) {
          bottom: 131px;
          left: 59%;
        }
        @include breakpointMax(1024) {
          bottom: 106px;
          left: 61%;
        }
        @include breakpointMax(1023) {
          bottom: 117px;
          left: 57%;
        }
        & .round-box {
          width: 97px;
          height: 97px;
          background: #ffffff;
          border-radius: 100%;
          box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
          display: table;
          padding: 0 5px;
          @include breakpointMax(1150) {
            width: 90px;
            height: 90px;
          }
          @include breakpointMax(1130) {
            width: 85px;
            height: 85px;
          }
          @include breakpointMax(1023) {
            width: 80px;
            height: 80px;
          }
          & .round-number {
            display: table-cell;
            vertical-align: middle;
            font-size: 19px;
            @include breakpointMax(1150) {
              font-size: 17px;
            }
            @include breakpointMax(1130) {
              font-size: 16px;
            }
            @include breakpointMax(1023) {
              font-size: 15px;
            }
          }
        }
        & .round-text {
          width: 100%;
          margin-bottom: 6px;
          font-size: 13px;
          line-height: 18px;
          @include breakpointMax(1100) {
            line-height: 14px;
          }
          @include breakpointMax(1024) {
            margin-bottom: 3px;
          }
        }
      }
      /* left three */
      & .leftside-top-round {
        position: absolute;
        top: -20px;
        right: 6%;
        width: 130px;
        text-align: center;
        @include breakpointMax(1450) {
          top: -18px;
          right: 6%;
        }
        @include breakpointMax(1400) {
          top: -20px;
          right: 6%;
        }
        @include breakpointMax(1350) {
          top: -24px;
          right: 6%;
        }
        @include breakpointMax(1300) {
          top: -24px;
          right: 6%;
        }
        @include breakpointMax(1230) {
          top: -30px;
          right: 6%;
        }
        @include breakpointMax(1150) {
          top: -31px;
          right: 3%;
        }
        @include breakpointMax(1130) {
          top: -30px;
          right: 3%;
        }
        @include breakpointMax(1024) {
          top: -35px;
          right: 3%;
        }
        @include breakpointMax(1023) {
          top: -34px;
          right: 0;
        }
        & .round-box {
          width: 97px;
          height: 97px;
          background: #ffffff;
          border-radius: 100%;
          box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
          display: table;
          padding: 0 5px;
          @include breakpointMax(1150) {
            width: 90px;
            height: 90px;
          }
          @include breakpointMax(1130) {
            width: 85px;
            height: 85px;
          }
          @include breakpointMax(1023) {
            width: 80px;
            height: 80px;
          }
          & .round-number {
            display: table-cell;
            vertical-align: middle;
            font-size: 19px;
            @include breakpointMax(1150) {
              font-size: 17px;
            }
            @include breakpointMax(1130) {
              font-size: 16px;
            }
            @include breakpointMax(1023) {
              font-size: 15px;
            }
          }
        }
        & .round-text {
          width: 100%;
          margin-bottom: 6px;
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
    & .midside-banner {
      width: 33.33%;
      position: absolute;
      left: 33.33%;
      top: 0;
      height: 100%;
      z-index: 10;
      /* mid one */
      & .midside-top-round {
        position: absolute;
        top: 7px;
        left: 11%;
        width: 105px;
        @include breakpointMax(1450) {
          top: 7px;
          left: 11%;
        }
        @include breakpointMax(1400) {
          top: 3px;
          left: 10%;
        }
        @include breakpointMax(1350) {
          top: -2px;
          left: 10%;
        }
        @include breakpointMax(1300) {
          top: -2px;
          left: 10%;
        }
        @include breakpointMax(1230) {
          top: -12px;
          left: 10%;
        }
        @include breakpointMax(1150) {
          top: -12px;
          left: 9%;
        }
        @include breakpointMax(1130) {
          top: -9px;
          left: 8%;
        }
        @include breakpointMax(1024) {
          top: -20px;
          left: 9%;
        }
        @include breakpointMax(1023) {
          top: -17px;
          left: 6%;
        }
        & .round-box {
          width: 97px;
          height: 97px;
          background: #ffffff;
          border-radius: 100%;
          box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
          display: table;
          padding: 0 5px;
          @include breakpointMax(1150) {
            width: 90px;
            height: 90px;
          }
          @include breakpointMax(1130) {
            width: 85px;
            height: 85px;
          }
          @include breakpointMax(1023) {
            width: 80px;
            height: 80px;
          }
          & .round-number {
            display: table-cell;
            vertical-align: middle;
            font-size: 19px;
            @include breakpointMax(1150) {
              font-size: 17px;
            }
            @include breakpointMax(1130) {
              font-size: 16px;
            }
            @include breakpointMax(1023) {
              font-size: 15px;
            }
          }
        }
        & .round-text {
          width: 100%;
          margin-bottom: 6px;
          font-size: 13px;
          line-height: 18px;
        }
      }
      /* mid two */
      & .midside-mid-round {
        position: absolute;
        top: 65px;
        right: 9%;
        width: 102px;
        @include breakpointMax(1450) {
          top: 67px;
          right: 8%;
        }
        @include breakpointMax(1400) {
          top: 60px;
          right: 8%;
        }
        @include breakpointMax(1350) {
          top: 54px;
          right: 7%;
        }
        @include breakpointMax(1300) {
          top: 48px;
          right: 7%;
        }
        @include breakpointMax(1230) {
          top: 38px;
          right: 7%;
        }
        @include breakpointMax(1150) {
          top: 38px;
          right: 7%;
        }
        @include breakpointMax(1130) {
          top: 37px;
          right: 5%;
        }
        @include breakpointMax(1024) {
          top: 19px;
          right: 4%;
        }
        @include breakpointMax(1023) {
          top: 24px;
          right: 3%;
        }
        & .round-box {
          width: 97px;
          height: 97px;
          background: #ffffff;
          border-radius: 100%;
          box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
          display: table;
          padding: 0 5px;
          @include breakpointMax(1150) {
            width: 90px;
            height: 90px;
          }
          @include breakpointMax(1130) {
            width: 85px;
            height: 85px;
          }
          @include breakpointMax(1023) {
            width: 80px;
            height: 80px;
          }
          & .round-number {
            display: table-cell;
            vertical-align: middle;
            font-size: 19px;
            @include breakpointMax(1150) {
              font-size: 17px;
            }
            @include breakpointMax(1130) {
              font-size: 16px;
            }
            @include breakpointMax(1023) {
              font-size: 15px;
            }
          }
        }
        & .round-text {
          width: 100%;
          margin-bottom: 6px;
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
    & .rightside-banner {
      width: 33.33%;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      z-index: 10;
      & .rightside-btm-round {
        position: absolute;
        bottom: 82px;
        left: 25%;
        width: 105px;
        @include breakpointMax(1450) {
          bottom: 94px;
          left: 25%;
        }
        @include breakpointMax(1400) {
          bottom: 96px;
          left: 25%;
        }
        @include breakpointMax(1350) {
          bottom: 88px;
          left: 25%;
        }
        @include breakpointMax(1300) {
          bottom: 90px;
          left: 24%;
        }
        @include breakpointMax(1230) {
          bottom: 82px;
          left: 25%;
        }
        @include breakpointMax(1150) {
          bottom: 70px;
          left: 24%;
        }
        @include breakpointMax(1130) {
          bottom: 77px;
          left: 23%;
        }
        @include breakpointMax(1024) {
          bottom: 53px;
        }
        @include breakpointMax(1023) {
          bottom: 67px;
          left: 22%;
        }
        & .round-box {
          width: 97px;
          height: 97px;
          background: #ffffff;
          border-radius: 100%;
          box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
          display: table;
          padding: 0 5px;
          @include breakpointMax(1150) {
            width: 90px;
            height: 90px;
          }
          @include breakpointMax(1130) {
            width: 85px;
            height: 85px;
          }
          @include breakpointMax(1023) {
            width: 80px;
            height: 80px;
          }
          & .round-number {
            display: table-cell;
            vertical-align: middle;
            font-size: 19px;
            @include breakpointMax(1150) {
              font-size: 17px;
            }
            @include breakpointMax(1130) {
              font-size: 16px;
            }
            @include breakpointMax(1023) {
              font-size: 15px;
            }
          }
        }
        & .round-text {
          width: 100%;
          margin-bottom: 6px;
          font-size: 13px;
          line-height: 18px;
        }
      }
      /* left two */
      & .rightside-mid-round {
        position: absolute;
        bottom: 189px;
        left: 62%;
        width: 115px;
        @include breakpointMax(1450) {
          bottom: 203px;
          left: 63%;
        }
        @include breakpointMax(1400) {
          bottom: 200px;
          left: 63%;
        }
        @include breakpointMax(1350) {
          bottom: 187px;
          left: 63%;
        }
        @include breakpointMax(1300) {
          bottom: 184px;
          left: 63%;
        }
        @include breakpointMax(1230) {
          bottom: 170px;
          left: 63%;
        }
        @include breakpointMax(1150) {
          bottom: 153px;
          left: 61%;
        }
        @include breakpointMax(1130) {
          bottom: 156px;
          left: 61%;
        }
        @include breakpointMax(1024) {
          bottom: 123px;
        }
        @include breakpointMax(1023) {
          bottom: 138px;
          left: 60%;
        }
        & .round-box {
          width: 97px;
          height: 97px;
          background: #ffffff;
          border-radius: 100%;
          box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
          display: table;
          padding: 0 5px;
          @include breakpointMax(1150) {
            width: 90px;
            height: 90px;
          }
          @include breakpointMax(1130) {
            width: 85px;
            height: 85px;
          }
          @include breakpointMax(1023) {
            width: 80px;
            height: 80px;
          }
          & .round-number {
            display: table-cell;
            vertical-align: middle;
            font-size: 19px;
            @include breakpointMax(1150) {
              font-size: 17px;
            }
            @include breakpointMax(1130) {
              font-size: 16px;
            }
            @include breakpointMax(1023) {
              font-size: 15px;
            }
          }
        }
        & .round-text {
          width: 100%;
          margin-bottom: 6px;
          font-size: 13px;
          line-height: 18px;
        }
      }
      /* left three */
      & .rightside-top-round {
        position: absolute;
        top: -10px;
        left: 8%;
        width: 130px;
        text-align: center;
        @include breakpointMax(1450) {
          top: -7px;
          left: 8%;
        }
        @include breakpointMax(1400) {
          top: -11px;
          left: 8%;
        }
        @include breakpointMax(1350) {
          top: -14px;
          left: 8%;
        }
        @include breakpointMax(1300) {
          top: -18px;
          left: 7%;
        }
        @include breakpointMax(1230) {
          top: -23px;
          left: 7%;
        }
        @include breakpointMax(1150) {
          top: -31px;
          left: 5%;
        }
        @include breakpointMax(1130) {
          top: -20px;
          left: 4%;
        }
        @include breakpointMax(1024) {
          top: -33px;
        }
        @include breakpointMax(1023) {
          top: -26px;
          left: 2%;
        }
        & .round-box {
          width: 97px;
          height: 97px;
          background: #ffffff;
          border-radius: 100%;
          box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
          display: table;
          padding: 0 5px;
          @include breakpointMax(1150) {
            width: 90px;
            height: 90px;
          }
          @include breakpointMax(1130) {
            width: 85px;
            height: 85px;
          }
          @include breakpointMax(1023) {
            width: 80px;
            height: 80px;
          }
          & .round-number {
            display: table-cell;
            vertical-align: middle;
            font-size: 19px;
            @include breakpointMax(1150) {
              font-size: 17px;
            }
            @include breakpointMax(1130) {
              font-size: 16px;
            }
            @include breakpointMax(1023) {
              font-size: 15px;
            }
          }
        }
        & .round-text {
          width: 100%;
          margin-bottom: 6px;
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
    @include breakpointMax(767) {
      display: none;
    }
  }
  & .mb-img-banner {
    display: none;
    @include breakpointMax(991) {
      display: inline-block;
      position: relative;
      z-index: 9;
      & img {
        position: relative;
        z-index: 9;
      }
      /* &:after {
				position:absolute;
				content:'';
				right: calc(100% - 39.85%);
				top: 5px;
				background: linear-gradient(180deg, rgba(0,173,187,0) 0%, rgba(0,173,187,0.17) 100%);
				width:100%;
				height:100%;
				z-index: 1;
				
			} */
      /* &:before {
				position:absolute;
				content:'';
				left: calc(100% - 41%);
				top: 5px;
				background: linear-gradient(180deg, rgba(34,91,118,0) 0%, rgba(34,91,118,0.31) 100%);
				width:100%;
				height:100%;
				z-index: 1;
			} */
      & .mb-banner-left-gradient {
        position: absolute;
        left: calc(100% - 41%);
        top: 5px;
        background: linear-gradient(
          180deg,
          rgba(34, 91, 118, 0) 0%,
          rgba(34, 91, 118, 0.31) 100%
        );
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      & .mb-banner-right-gradient {
        position: absolute;
        right: calc(100% - 39.85%);
        top: 5px;
        background: linear-gradient(
          180deg,
          rgba(0, 173, 187, 0) 0%,
          rgba(0, 173, 187, 0.17) 100%
        );
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }
    @include breakpointMax(736) {
      margin-top: 20px;
    }
    @include breakpointMax(580) {
      margin-top: 40px;
    }
    & .mb-tlc-price-amount {
      position: absolute;
      bottom: 144px;
      left: 33%;
      font-size: 39px;
      width: 326px;
      height: 77px;
      line-height: 74px;
      text-align: center;
      z-index: 9;
      @include breakpointMax(970) {
        bottom: 140px;
        width: 318px;
      }
      @include breakpointMax(950) {
        bottom: 136px;
        width: 315px;
      }
      @include breakpointMax(930) {
        bottom: 130px;
        width: 304px;
      }
      @include breakpointMax(900) {
        bottom: 129px;
        width: 298px;
      }
      @include breakpointMax(840) {
        bottom: 116px;
        width: 298px;
      }
      @include breakpointMax(800) {
        font-size: 28px;
        bottom: 115px;
        width: 263px;
        height: 63px;
        line-height: 68px;
      }
      @include breakpointMax(812) {
        font-size: 28px;
        bottom: 118px;
        height: 63px;
        line-height: 65px;
        width: 266px;
      }
      @include breakpointMax(770) {
        font-size: 27px;
        bottom: 111px;
        height: 59px;
        line-height: 60px;
        width: 253px;
      }
      @include breakpointMax(768) {
        bottom: 108px;
        width: 250px;
        font-size: 28px;
        height: 60px;
        line-height: 55px;
      }
      @include breakpointMax(736) {
        bottom: 104px;
        height: 58px;
        width: 243px;
      }
      @include breakpointMax(710) {
        bottom: 103px;
        height: 55px;
        width: 234px;
        font-size: 24px;
      }
      @include breakpointMax(680) {
        bottom: 101px;
        height: 50px;
        width: 223px;
        font-size: 24px;
      }
      @include breakpointMax(667) {
        line-height: 50px;
        width: 218px;
        bottom: 100px;
        height: 47px;
        font-size: 22px;
      }
      @include breakpointMax(640) {
        width: 210px;
        bottom: 95px;
      }
      @include breakpointMax(620) {
        width: 202px;
        font-size: 20px;
        height: 40px;
        line-height: 40px;
      }
      @include breakpointMax(588) {
        width: 195px;
        bottom: 86px;
        height: 46px;
        line-height: 45px;
      }
      @include breakpointMax(560) {
        width: 183px;
        bottom: 81px;
        height: 41px;
        line-height: 41px;
      }
      @include breakpointMax(520) {
        width: 171px;
        bottom: 75px;
      }
      @include breakpointMax(500) {
        width: 163px;
        bottom: 74px;
        height: 36px;
        font-size: 19px;
      }
      @include breakpointMax(460) {
        font-size: 17px;
        width: 150px;
        line-height: 35px;
        bottom: 68px;
        height: 35px;
      }
      @include breakpointMax(420) {
        line-height: 30px;
        width: 134px;
        bottom: 61px;
        height: 30px;
        font-size: 17px;
      }
      @include breakpointMax(380) {
        width: 121px;
        bottom: 54px;
        font-size: 17px;
      }
      @include breakpointMax(360) {
        width: 113px;
        bottom: 50px;
      }
      @include breakpointMax(332) {
        width: 107px;
        height: 27px;
      }
      @include breakpointMax(321) {
        line-height: 30px;
        width: 104px;
        bottom: 47px;
        height: 26px;
        font-size: 15px;
      }
    }
    & .mb-tlc-mortgage-text {
      position: absolute;
      top: 210px;
      left: 22.5%;
      font-size: 24px;
      text-transform: uppercase;
      color: #b6c5cf;
      transform: rotate(450deg);
      z-index: 9;
      @include breakpointMax(900) {
        left: 21.9%;
      }
      @include breakpointMax(770) {
        left: 20.5%;
        font-size: 23px;
      }
      @include breakpointMax(768) {
        top: 190px;
        left: 20.5%;
      }
      @include breakpointMax(736) {
        top: 170px;
        left: 20.1%;
      }
      @include breakpointMax(680) {
        top: 158px;
        left: 19%;
      }
      @include breakpointMax(640) {
        top: 148px;
      }
      @include breakpointMax(620) {
        font-size: 22px;
      }
      @include breakpointMax(580) {
        font-size: 20px;
        top: 138px;
      }
      @include breakpointMax(520) {
        top: 90px;
      }
      @include breakpointMax(500) {
        top: 70px;
        left: 18%;
        font-size: 19px;
      }
      @include breakpointMax(480) {
        top: 60px;
      }
      @include breakpointMax(460) {
        font-size: 16px;
        top: 70px;
        left: 19%;
      }
      @include breakpointMax(414) {
        font-size: 16px;
        top: 53px;
        left: 18%;
      }
      @include breakpointMax(375) {
        top: 10px;
        left: 17%;
      }
      @include breakpointMax(350) {
        left: 16%;
      }
      @include breakpointMax(320) {
        font-size: 16px;
        top: 20px;
        left: 14%;
      }
    }
    & .mb-tlc-mortgage-round {
      position: absolute;
      top: 4%;
      left: 24%;
      width: 110px;
      text-align: center;
      z-index: 9;
      @include breakpointMax(736) {
        top: 2%;
      }
      @include breakpointMax(680) {
        top: -1%;
        left: 22%;
      }
      @include breakpointMax(630) {
        left: 22%;
      }
      @include breakpointMax(580) {
        left: 20%;
        top: -3%;
      }
      @include breakpointMax(520) {
        top: inherit;
        bottom: 50%;
        width: auto;
        min-width: 85px;
        left: 17%;
      }
      @include breakpointMax(480) {
        left: 13%;
      }
      @include breakpointMax(460) {
        left: 12%;
      }

      @include breakpointMax(420) {
        width: auto;
        max-width: 85px;
        left: 9%;
      }
      @include breakpointMax(375) {
        left: 7%;
      }
      @include breakpointMax(330) {
        left: 5%;
      }
      & .mb-round-box {
        width: 85px;
        height: 85px;
        background: #ffffff;
        border-radius: 100%;
        box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
        display: table;
        padding: 0 5px;
        @include breakpointMax(520) {
          border-radius: inherit;
          height: auto;
          padding: 5px 5px;
          min-width: 85px;
          width: auto;
        }
        & .mb-round-number {
          display: table-cell;
          vertical-align: middle;
          font-size: 19px;
          @include breakpointMax(520) {
            font-size: 15px;
          }
          @include breakpointMax(320) {
            font-size: 14px;
          }
        }
      }
    }
    & .mb-tlc-utilities-round {
      position: absolute;
      top: 4%;
      left: 43%;
      width: 110px;
      text-align: center;
      z-index: 9;
      @include breakpointMax(736) {
        top: 2%;
      }
      @include breakpointMax(680) {
        top: -1%;
      }
      @include breakpointMax(630) {
        left: 41%;
      }
      @include breakpointMax(580) {
        left: 40%;
        top: -3%;
      }
      @include breakpointMax(520) {
        left: 42%;
        top: inherit;
        bottom: 50%;
        width: auto;
        min-width: 85px;
      }
      @include breakpointMax(480) {
        left: 41%;
      }
      @include breakpointMax(375) {
        left: 37%;
      }
      @include breakpointMax(320) {
        left: 36%;
      }
      & .mb-round-box {
        width: 85px;
        height: 85px;
        background: #ffffff;
        border-radius: 100%;
        box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
        display: table;
        padding: 0 5px;
        @include breakpointMax(520) {
          border-radius: inherit;
          height: auto;
          padding: 5px 5px;
          min-width: 85px;
          width: auto;
        }
        & .mb-round-number {
          display: table-cell;
          vertical-align: middle;
          font-size: 19px;
          @include breakpointMax(520) {
            font-size: 15px;
          }
          @include breakpointMax(320) {
            font-size: 14px;
          }
        }
      }
    }
    & .mb-tlc-commute-round {
      position: absolute;
      top: 4%;
      right: 23%;
      width: 110px;
      text-align: center;
      z-index: 9;
      @include breakpointMax(736) {
        top: 2%;
      }
      @include breakpointMax(680) {
        top: -1%;
      }
      @include breakpointMax(630) {
        right: 20%;
      }
      @include breakpointMax(580) {
        top: -3%;
      }
      @include breakpointMax(520) {
        top: inherit;
        bottom: 50%;
        right: 17%;
        width: auto;
        min-width: 85px;
      }
      @include breakpointMax(480) {
        right: 12%;
      }
      @include breakpointMax(460) {
        right: 11%;
      }
      @include breakpointMax(375) {
        right: 8%;
      }
      @include breakpointMax(350) {
        right: 6%;
      }
      @include breakpointMax(320) {
        right: 4%;
      }
      & .mb-round-box {
        width: 85px;
        height: 85px;
        background: #ffffff;
        border-radius: 100%;
        box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
        display: table;
        padding: 0 5px;
        @include breakpointMax(520) {
          border-radius: inherit;
          height: auto;
          padding: 5px 5px;
          min-width: 85px;
          width: auto;
        }
        & .mb-round-number {
          display: table-cell;
          vertical-align: middle;
          font-size: 19px;
          @include breakpointMax(520) {
            font-size: 15px;
          }
          @include breakpointMax(320) {
            font-size: 14px;
          }
        }
      }
    }
    & .mb-tlc-utilities-text {
      position: absolute;
      top: 195px;
      left: 42.5%;
      font-size: 24px;
      text-transform: uppercase;
      color: #b6c5cf;
      transform: rotate(450deg);
      z-index: 9;
      @include breakpointMax(900) {
        left: 43.5%;
      }
      @include breakpointMax(770) {
        left: 42.5%;
        font-size: 23px;
      }
      @include breakpointMax(768) {
        top: 175px;
      }
      @include breakpointMax(736) {
        top: 155px;
      }
      @include breakpointMax(680) {
        top: 145px;
      }
      @include breakpointMax(640) {
        top: 135px;
      }
      @include breakpointMax(620) {
        font-size: 22px;
        left: 41%;
      }
      @include breakpointMax(580) {
        font-size: 20px;
        top: 125px;
      }
      @include breakpointMax(520) {
        top: 78px;
      }
      @include breakpointMax(500) {
        top: 58px;
      }
      @include breakpointMax(480) {
        top: 50px;
      }
      @include breakpointMax(460) {
        left: 40%;
      }
      @include breakpointMax(500) {
        font-size: 19px;
        /* top:95px; */
      }
      @include breakpointMax(460) {
        font-size: 16px;
        left: 41%;
        top: 62px;
      }
      @include breakpointMax(414) {
        font-size: 16px;
        left: 41%;
        top: 45px;
      }
      @include breakpointMax(375) {
        left: 40%;
        top: 3px;
      }
      @include breakpointMax(350) {
      }
      @include breakpointMax(320) {
        font-size: 16px;
        left: 38%;
        top: 12px;
      }
    }
    & .mb-tlc-commute-text {
      position: absolute;
      top: 200px;
      right: 21.5%;
      font-size: 24px;
      text-transform: uppercase;
      color: #b6c5cf;
      transform: rotate(450deg);
      z-index: 9;
      @include breakpointMax(900) {
        right: 22.6%;
      }
      @include breakpointMax(770) {
        right: 22%;
        font-size: 23px;
      }
      @include breakpointMax(768) {
        top: 180px;
      }
      @include breakpointMax(736) {
        top: 160px;
      }
      @include breakpointMax(680) {
        top: 150px;
      }
      @include breakpointMax(640) {
        top: 140px;
        right: 20%;
      }
      @include breakpointMax(620) {
        font-size: 22px;
        right: 20%;
      }
      @include breakpointMax(580) {
        font-size: 20px;
        top: 130px;
      }
      @include breakpointMax(520) {
        top: 82px;
      }
      @include breakpointMax(500) {
        font-size: 19px;
        top: 62px;
      }
      @include breakpointMax(480) {
        top: 52px;
      }
      @include breakpointMax(460) {
        font-size: 16px;
        top: 65px;
        right: 20%;
      }
      @include breakpointMax(414) {
        font-size: 16px;
        top: 47px;
        right: 20%;
      }
      @include breakpointMax(375) {
        top: 5px;
        right: 16%;
      }
      @include breakpointMax(350) {
      }
      @include breakpointMax(320) {
        font-size: 16px;
        right: 17%;
        top: 16px;
      }
    }
  }
}
/**********************************************************************************************************New Style start**********************************************/

.tlc-how-works {
  background: linear-gradient(
    180deg,
    rgba(34, 91, 118, 0) 0%,
    rgba(34, 91, 118, 0.31) 100%
  );
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  @include breakpointMax(1450) {
    overflow: hidden;
  }
  & .work-text {
    float: left;
    width: 100%;
    margin-top: 40px;
    @include breakpointMax(767) {
      position: relative;
      top: inherit;
      left: inherit;
      padding-top: 50px;
    }
    @include breakpointMax(480) {
      padding-top: 20px;
      padding-bottom: 30px;
    }
  }
  /**********************************Remove start************************/
  & .banner-left-gradient {
    display: none;
  }
  & .banner-right-gradient {
    display: none;
  }
  /******************************Remove End*****************************/
  & .proptlchelpbanner {
    display: inline-block;
  }
  & .desktop-img-banner {
    height: 100%;
    position: relative;
    width: 1450px;
    float: none;
    margin: 60px auto 0;
    display: inline-block;
    @include breakpointMax(1450) {
      width: 100%;
    }
    @include breakpointMax(999) {
      display: none;
    }
    /********************************************************************* left***************************************************/
    & .leftside-banner {
      width: 33.33%;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      z-index: 10;
      & .leftside-top-round {
        top: 160px;
        left: 40%;
        position: absolute;
        width: 130px;
        text-align: center;
        @include breakpointMax(1450) {
          top: 156px;
          left: 39%;
        }
        @include breakpointMax(1300) {
          left: 38%;
        }
        @include breakpointMax(1190) {
          left: 36%;
          top: 121px;
        }
        @include breakpointMax(1100) {
          top: 108px;
          left: 35%;
        }
        @include breakpointMax(1085) {
          left: 35%;
        }
        @include breakpointMax(1030) {
          top: 95px;
          left: 33%;
        }
      }
      & .leftside-mid-round {
        bottom: 153px;
        left: 23%;
        position: absolute;
        width: 105px;
        @include breakpointMax(1450) {
          bottom: 153px;
          left: 20%;
        }
        @include breakpointMax(1399) {
          bottom: 147px;
        }
        @include breakpointMax(1380) {
          bottom: 144px;
          left: 20%;
        }
        @include breakpointMax(1280) {
          bottom: 135px;
        }
        @include breakpointMax(1100) {
          bottom: 100px;
          left: 13%;
        }
        @include breakpointMax(1185) {
          bottom: 120px;
        }
        @include breakpointMax(1170) {
          left: 18%;
        }
        @include breakpointMax(1120) {
          left: 17%;
          bottom: 112px;
        }
        @include breakpointMax(1030) {
          bottom: 102px;
          left: 15%;
        }
      }
      & .leftside-btm-round {
        bottom: 309px;
        left: 4%;
        position: absolute;
        width: 105px;
        @include breakpointMax(1380) {
          bottom: 294px;
        }
        @include breakpointMax(1310) {
          bottom: 284px;
        }
        @include breakpointMax(1280) {
          bottom: 273px;
        }
        @include breakpointMax(1250) {
          bottom: 270px;
        }
        @include breakpointMax(1200) {
          bottom: 256px;
        }
        @include breakpointMax(1100) {
          bottom: 236px;
        }
        @include breakpointMax(1130) {
          bottom: 241px;
        }
        @include breakpointMax(1085) {
          bottom: 228px;
        }
        @include breakpointMax(1030) {
          bottom: 218px;
        }
      }
    }
    /********************************************************************* left***************************************************/
    /*********************************************************************Mid***************************************************/
    & .midside-banner {
      width: 33.33%;
      position: absolute;
      left: 33.33%;
      top: 0;
      height: 100%;
      z-index: 10;
      & .midside-top-round {
        display: none;
      }
      & .midside-mid-round {
        top: 84px;
        right: 16%;
        width: 230px;
        position: absolute;
        @include breakpointMax(1399) {
          top: 80px;
        }
        @include breakpointMax(1300) {
          top: 75px;
        }
        @include breakpointMax(1100) {
          top: 85px;
          right: 13%;
        }
        @include breakpointMax(1280) {
          top: 75px;
          right: 15%;
        }
        @include breakpointMax(1200) {
          top: 64px;
        }
        @include breakpointMax(1085) {
          top: 57px;
        }
        @include breakpointMax(1030) {
          top: 53px;
          right: 13%;
        }
        & .round-text {
          width: 120px;
          display: inline-block;
          vertical-align: middle;
          margin-bottom: 0;
          padding-right: 10px;
        }
      }
      & .midside-property-photo {
        width: 290px;
        height: 190px;
        position: absolute;
        top: 366px;
        left: 97px;
        border: 3px solid #fff;
        overflow: hidden;
        min-height: 190px;
        background: #ffffff;
        & img {
          width: 100%;
          position: absolute;
          height: auto;
          margin: auto;
          top: -100%;
          right: -100%;
          bottom: -100%;
          left: -100%;
          min-height: 190px;
        }
        @include breakpointMax(1450) {
          left: calc(100% - 385px);
        }
        @include breakpointMax(1439) {
          left: calc(100% - 384px);
        }
        @include breakpointMax(1426) {
          left: calc(100% - 382px);
        }
        @include breakpointMax(1415) {
          left: calc(100% - 380px);
          top: 359px;
        }
        @include breakpointMax(1400) {
          left: calc(100% - 378px);
        }
        @include breakpointMax(1390) {
          left: calc(100% - 377px);
          top: 355px;
        }
        @include breakpointMax(1389) {
          left: calc(100% - 375px);
          top: 355px;
        }
        @include breakpointMax(1380) {
          top: 352px;
        }
        @include breakpointMax(1374) {
          top: 350px;
          left: calc(100% - 374px);
        }
        @include breakpointMax(1370) {
          top: 350px;
          left: calc(100% - 373px);
        }
        @include breakpointMax(1365) {
          top: 347px;
          left: calc(100% - 372px);
        }
        @include breakpointMax(1360) {
          left: calc(100% - 362px);
          top: 346px;
          width: 270px;
          height: 170px;
          min-height: 170px;
          & img {
            min-height: 170px;
          }
        }
        @include breakpointMax(1355) {
          left: calc(100% - 360px);
          top: 343px;
        }
        @include breakpointMax(1340) {
          left: calc(100% - 358px);
          top: 340px;
        }
        @include breakpointMax(1320) {
          left: calc(100% - 355px);
          top: 336px;
        }
        @include breakpointMax(1300) {
          left: calc(100% - 352px);
          top: 332px;
        }
        @include breakpointMax(1290) {
          width: 260px;
          height: 160px;
          min-height: 160px;
          left: calc(100% - 344px);
          top: 329px;
          & img {
            min-height: 160px;
          }
        }
        @include breakpointMax(1270) {
          left: calc(100% - 342px);
          top: 326px;
        }
        @include breakpointMax(1260) {
          left: calc(100% - 340px);
        }
        @include breakpointMax(1250) {
          left: calc(100% - 338px);
          top: 319px;
        }
        @include breakpointMax(1240) {
          width: 240px;
          height: 140px;
          min-height: 140px;
          left: calc(100% - 327px);
          top: 316px;
          & img {
            min-height: 140px;
          }
        }
        @include breakpointMax(1220) {
          left: calc(100% - 324px);
          top: 308px;
        }
        @include breakpointMax(1200) {
          left: calc(100% - 320px);
          top: 305px;
        }
        @include breakpointMax(1190) {
          left: calc(100% - 318px);
        }
        @include breakpointMax(1180) {
          left: calc(100% - 317px);
          top: 300px;
        }
        @include breakpointMax(1160) {
          left: calc(100% - 312px);
          top: 292px;
        }
        @include breakpointMax(1140) {
          left: calc(100% - 310px);
          top: 290px;
        }
        @include breakpointMax(1130) {
          width: 220px;
          height: 120px;
          min-height: 120px;
          top: 263px;
          left: calc(100% - 298px);
          top: 288px;
          & img {
            min-height: 120px;
          }
        }
        @include breakpointMax(1120) {
          left: calc(100% - 297px);
          top: 284px;
        }
        @include breakpointMax(1110) {
          left: calc(100% - 295px);
        }
        @include breakpointMax(1100) {
          left: calc(100% - 293px);
        }
        @include breakpointMax(1085) {
          left: calc(100% - 291px);
          top: 276px;
        }
        @include breakpointMax(1080) {
          left: calc(100% - 290px);
        }
        @include breakpointMax(1070) {
          left: calc(100% - 288px);
          top: 274px;
        }
        @include breakpointMax(1060) {
          left: calc(100% - 286px);
          top: 270px;
        }
        @include breakpointMax(1040) {
          left: calc(100% - 283px);
          top: 266px;
        }
        @include breakpointMax(1050) {
          left: calc(100% - 285px);
        }
        @include breakpointMax(1030) {
          width: 200px;
          height: 100px;
          min-height: 100px;
          top: 263px;
          left: calc(100% - 271px);
          top: 263px;
          & img {
            min-height: 100px;
          }
        }
        @include breakpointMax(1025) {
          left: calc(100% - 272px);
        }
      }
    }
    /*********************************************************************Mid***************************************************/
    /*********************************************************************right***************************************************/
    & .rightside-banner {
      width: 33.33%;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      z-index: 10;
      & .rightside-top-round {
        top: 156px;
        left: 33%;
        position: absolute;
        width: 154px;
        text-align: center;
        @include breakpointMax(1450) {
          top: 156px;
          left: 33%;
        }
        @include breakpointMax(1190) {
          top: 121px;
          left: 31%;
        }
        @include breakpointMax(1080) {
          left: 29%;
        }
        @include breakpointMax(1030) {
          top: 95px;
          left: 29%;
        }
      }
      & .rightside-mid-round {
        bottom: 314px;
        left: 73%;
        position: absolute;
        width: 115px;
        @include breakpointMax(1450) {
          bottom: 307px;
        }
        @include breakpointMax(1380) {
          bottom: 294px;
          left: 71%;
        }
        @include breakpointMax(1310) {
          bottom: 284px;
          left: 70%;
        }
        @include breakpointMax(1280) {
          bottom: 273px;
        }
        @include breakpointMax(1250) {
          bottom: 270px;
        }
        @include breakpointMax(1200) {
          bottom: 256px;
          left: 68%;
        }
        @include breakpointMax(1130) {
          bottom: 241px;
          left: 66%;
        }
        @include breakpointMax(1085) {
          bottom: 230px;
          left: 66%;
        }
        @include breakpointMax(1030) {
          bottom: 220px;
          left: 64%;
        }
      }
      & .rightside-btm-round {
        bottom: 155px;
        left: 59%;
        position: absolute;
        width: 105px;
        @include breakpointMax(1399) {
          bottom: 147px;
        }
        @include breakpointMax(1380) {
          bottom: 144px;
          left: 59%;
        }
        @include breakpointMax(1300) {
          left: 57%;
        }
        @include breakpointMax(1280) {
          bottom: 135px;
        }
        @include breakpointMax(1180) {
          bottom: 120px;
        }
        @include breakpointMax(1120) {
          bottom: 112px;
        }
        @include breakpointMax(1030) {
          bottom: 102px;
          left: 56%;
        }
      }
    }
    /*********************************************************************right End***************************************************/
    /*********************************************************************tlc price amount start***************************************************/
    & .tlc-price-amount {
      position: absolute;
      background: #ffffff;
      width: 210px;
      height: 40px;
      left: 43%;
      bottom: 86px;
      line-height: 30px;
      font-size: 20px;
      padding: 3px 5px;
      z-index: 10;
      @include breakpointMax(1429) {
        bottom: 83px;
      }
      @include breakpointMax(1420) {
        bottom: 80px;
      }
      @include breakpointMax(1415) {
        bottom: 80px;
      }
      @include breakpointMax(1410) {
        bottom: 76px;
      }
      @include breakpointMax(1400) {
        bottom: 75px;
      }
      @include breakpointMax(1390) {
        bottom: 78px;
      }
      @include breakpointMax(1385) {
        bottom: 77px;
      }
      @include breakpointMax(1360) {
        bottom: 84px;
      }
      @include breakpointMax(1330) {
        bottom: 80px;
        width: 205px;
      }
      @include breakpointMax(1300) {
        bottom: 75px;
        width: 200px;
      }
      @include breakpointMax(1290) {
        font-size: 18px;
      }
      @include breakpointMax(1250) {
        bottom: 74px;
        width: 192px;
      }
      @include breakpointMax(1255) {
        bottom: 72px;
        width: 195px;
      }
      @include breakpointMax(1240) {
        bottom: 84px;
      }
      @include breakpointMax(1190) {
        bottom: 76px;
        width: 190px;
      }
      @include breakpointMax(1150) {
        bottom: 70px;
        width: 180px;
      }
      @include breakpointMax(1130) {
        bottom: 82px;
        width: 180px;
      }
      @include breakpointMax(1110) {
        bottom: 78px;
        width: 175px;
      }
      @include breakpointMax(1070) {
        bottom: 70px;
        width: 165px;
      }
      @include breakpointMax(1050) {
        bottom: 66px;
        font-size: 17px;
      }
      @include breakpointMax(1040) {
        bottom: 66px;
        font-size: 17px;
      }
      @include breakpointMax(1030) {
        width: 160px;
        bottom: 74px;
      }
      @include breakpointMax(1010) {
        bottom: 69px;
      }
    }
    /*********************************************************************tlc price amount End***************************************************/
    /*********************************************************************start***************************************************/
    & .tlchelp-banner {
      position: relative;
      & img {
        width: auto;
        position: relative;
        z-index: 9;
        display: inline-block;
        vertical-align: bottom;
        @include breakpointMax(1450) {
          width: 100%;
          & img {
            width: 100%;
          }
        }
      }
    }
    /*********************************************************************End***************************************************/
    /*********************************************************************start***************************************************/
    & .tlc-home-smallbox {
      width: 270px;
      height: 40px;
      background: #ffffff;
      position: absolute;
      text-align: left;
      & .sub-box-inner {
        display: table;
        vertical-align: middle;
        height: 30px;
        width: 100%;
        & .box-info {
          display: table-cell;
          vertical-align: middle;
          font-size: 13px;
          width: 100%;
          @include breakpointMax(1260) {
            font-size: 12px;
          }
          & .info-highlight {
            display: inline-block;
            &.first-address-box {
              display: block;
              line-height: normal;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              height: 15px;
              width: 184px;
              @include breakpointMax(1180) {
                width: 178px;
              }
              @include breakpointMax(1150) {
                width: 170px;
              }
              @include breakpointMax(1100) {
                width: 170px;
              }
              @include breakpointMax(1110) {
                width: 164px;
              }
              @include breakpointMax(1090) {
                width: 160px;
              }
              @include breakpointMax(1060) {
                width: 154px;
              }
            }
          }
          & .second-address-box {
            font-size: 12px;
            padding-top: 3px;
            display: block;
            line-height: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 15px;
            width: 182px;
            @include breakpointMax(1385) {
              width: 174px;
            }
            @include breakpointMax(1300) {
              width: 164px;
            }
            @include breakpointMax(1180) {
              width: 158px;
            }
            @include breakpointMax(1150) {
              width: 152px;
            }
            @include breakpointMax(1110) {
              width: 144px;
            }
            @include breakpointMax(1100) {
              width: 144px;
            }
            @include breakpointMax(1090) {
              width: 140px;
            }
            @include breakpointMax(1060) {
              width: 132px;
            }
          }
          & .second-info-box {
            display: inline-block;
            padding-left: 5px;
            @include breakpointMax(1180) {
              /* display:block;
							padding-left:0px; */
            }
          }
        }
      }
      &.property-full-address {
        top: 328px;
        left: 22%;
        padding: 5px 70px 5px 12px;
        text-align: right;
        @include breakpointMax(1450) {
          padding: 5px 55px 5px 12px;
        }
        @include breakpointMax(1430) {
          top: 325px;
        }
        @include breakpointMax(1410) {
          top: 316px;
        }
        @include breakpointMax(1400) {
          padding: 5px 70px 5px 12px;
        }
        @include breakpointMax(1385) {
          top: 316px;
        }
        @include breakpointMax(1380) {
          top: 314px;
        }
        @include breakpointMax(1375) {
          top: 310px;
        }
        @include breakpointMax(1355) {
          top: 305px;
        }
        @include breakpointMax(1330) {
          top: 300px;
        }
        @include breakpointMax(1300) {
          top: 295px;
          padding: 5px 70px 5px 8px;
        }
        @include breakpointMax(1280) {
          top: 290px;
          left: 21%;
        }
        @include breakpointMax(1250) {
          top: 283px;
        }
        @include breakpointMax(1220) {
          top: 275px;
        }
        @include breakpointMax(1200) {
          top: 270px;
        }
        @include breakpointMax(1180) {
          top: 267px;
        }
        @include breakpointMax(1150) {
          top: 260px;
        }
        @include breakpointMax(1130) {
          top: 256px;
        }
        @include breakpointMax(1110) {
          top: 253px;
          padding: 5px 110px 5px 8px;
        }
        @include breakpointMax(1100) {
          top: 249px;
        }
        @include breakpointMax(1085) {
          top: 245px;
          & .sub-box-inner {
            & .box-info {
              & .second-address-box {
                font-size: 11px;
              }
            }
          }
        }
        @include breakpointMax(1060) {
          top: 240px;
        }
        @include breakpointMax(1050) {
          top: 236px;
        }
        @include breakpointMax(1030) {
          top: 230px;
        }
        @include breakpointMax(1020) {
          top: 225px;
        }
      }
      &.property-full-price {
        top: 222px;
        left: 34%;
        padding: 5px 48px 5px 12px;
        width: 215px;
        text-align: right;
        @include breakpointMax(1450) {
          padding: 5px 48px 5px 12px;
        }
        @include breakpointMax(1430) {
          top: 217px;
        }
        @include breakpointMax(1410) {
          top: 215px;
        }
        @include breakpointMax(1400) {
          padding: 5px 48px 5px 12px;
        }
        @include breakpointMax(1389) {
          top: 209px;
        }
        @include breakpointMax(1366) {
          top: 203px;
        }
        @include breakpointMax(1350) {
          top: 201px;
        }
        @include breakpointMax(1310) {
          top: 198px;
          padding: 5px 54px 5px 12px;
        }
        @include breakpointMax(1300) {
          top: 196px;
          padding: 5px 58px 5px 8px;
        }
        @include breakpointMax(1280) {
          top: 190px;
          left: 33%;
        }
        @include breakpointMax(1240) {
          top: 185px;
        }
        @include breakpointMax(1220) {
          top: 179px;
          width: 205px;
          padding: 5px 48px 5px 8px;
        }
        @include breakpointMax(1180) {
          top: 175px;
          width: 200px;
        }
        @include breakpointMax(1170) {
          top: 173px;
        }
        @include breakpointMax(1150) {
          top: 169px;
          width: 196px;
        }
        @include breakpointMax(1130) {
          top: 166px;
          width: 190px;
        }
        @include breakpointMax(1100) {
          top: 161px;
          left: 33%;
        }
        @include breakpointMax(1070) {
          top: 159px;
          right: 33%;
          padding: 5px 54px 5px 8px;
        }
        @include breakpointMax(1060) {
          top: 158px;
          width: 180px;
        }
        @include breakpointMax(1050) {
          top: 154px;
        }
        @include breakpointMax(1030) {
          top: 153px;
          width: 179px;
        }
        @include breakpointMax(1020) {
          top: 149px;
        }
        @include breakpointMax(1000) {
          top: 146px;
          width: 169px;
          padding: 5px 46px 5px 8px;
        }
      }
      &.property-full-mls {
        top: 222px;
        right: 34%;
        padding: 5px 12px 5px 48px;
        text-align: left;
        width: 215px;
        @include breakpointMax(1450) {
          padding: 5px 12px 5px 48px;
        }
        @include breakpointMax(1430) {
          top: 217px;
        }
        @include breakpointMax(1410) {
          top: 215px;
        }
        @include breakpointMax(1400) {
          padding: 5px 12px 5px 52px;
        }
        @include breakpointMax(1389) {
          top: 209px;
        }
        @include breakpointMax(1366) {
          top: 203px;
        }
        @include breakpointMax(1350) {
          top: 201px;
        }
        @include breakpointMax(1310) {
          top: 198px;
          padding: 5px 8px 5px 56px;
        }
        @include breakpointMax(1300) {
          top: 196px;
          padding: 5px 8px 5px 62px;
        }
        @include breakpointMax(1280) {
          top: 190px;
          right: 33%;
          padding: 5px 8px 5px 52px;
        }
        @include breakpointMax(1240) {
          top: 185px;
          padding: 5px 8px 5px 58px;
        }
        @include breakpointMax(1220) {
          top: 179px;
          width: 205px;
          padding: 5px 8px 5px 50px;
        }
        @include breakpointMax(1180) {
          top: 175px;
          width: 200px;
        }
        @include breakpointMax(1170) {
          top: 173px;
        }
        @include breakpointMax(1150) {
          top: 169px;
          width: 196px;
        }
        @include breakpointMax(1130) {
          top: 166px;
          width: 190px;
        }
        @include breakpointMax(1100) {
          top: 161px;
          right: 33%;
        }
        @include breakpointMax(1070) {
          top: 159px;
          right: 33%;
          padding: 5px 8px 5px 54px;
        }
        @include breakpointMax(1060) {
          top: 158px;
          width: 180px;
        }
        @include breakpointMax(1050) {
          top: 154px;
        }
        @include breakpointMax(1040) {
          top: 152px;
          width: 180px;
        }
        @include breakpointMax(1030) {
          top: 153px;
          width: 179px;
        }
        @include breakpointMax(1020) {
          top: 149px;
        }
        @include breakpointMax(1000) {
          top: 146px;
          width: 169px;
          padding: 5px 8px 5px 48px;
        }
        & .sub-box-inner {
          & .box-info {
            & .second-info-box {
              padding-left: 5px;
            }
          }
        }
      }
      &.property-full-bed {
        top: 328px;
        right: 22%;
        padding: 5px 12px 5px 62px;
        text-align: left;
        & .sub-box-inner {
          & .box-info {
            & .second-info-box {
              padding-left: 5px;
              @include breakpointMax(1400) {
                padding-left: 3px;
              }
              @include breakpointMax(1110) {
                padding-left: 5px;
              }
            }
          }
        }
        @include breakpointMax(1450) {
          padding: 5px 12px 5px 62px;
        }
        @include breakpointMax(1430) {
          top: 325px;
        }
        @include breakpointMax(1410) {
          top: 316px;
        }
        @include breakpointMax(1400) {
          padding: 5px 12px 5px 64px;
        }
        @include breakpointMax(1385) {
          top: 316px;
          padding: 5px 12px 5px 74px;
        }
        @include breakpointMax(1380) {
          top: 314px;
        }
        @include breakpointMax(1375) {
          top: 310px;
        }
        @include breakpointMax(1355) {
          top: 305px;
        }
        @include breakpointMax(1330) {
          top: 300px;
        }
        @include breakpointMax(1300) {
          top: 295px;
          padding: 5px 8px 5px 84px;
        }
        @include breakpointMax(1280) {
          top: 290px;
          right: 21%;
          padding: 5px 8px 5px 80px;
        }
        @include breakpointMax(1260) {
          padding: 5px 8px 5px 82px;
        }
        @include breakpointMax(1250) {
          top: 283px;
        }
        @include breakpointMax(1220) {
          top: 275px;
        }
        @include breakpointMax(1200) {
          top: 270px;
          padding: 5px 8px 5px 86px;
        }
        @include breakpointMax(1180) {
          top: 267px;
          padding: 5px 8px 5px 88px;
        }
        @include breakpointMax(1150) {
          top: 260px;
          padding: 5px 8px 5px 96px;
        }
        @include breakpointMax(1130) {
          top: 256px;
          padding: 5px 8px 5px 108px;
        }
        @include breakpointMax(1110) {
          top: 253px;
          padding: 5px 8px 5px 112px;
        }
        @include breakpointMax(1100) {
          top: 249px;
        }
        @include breakpointMax(1085) {
          top: 245px;
        }
        @include breakpointMax(1060) {
          top: 240px;
          padding: 5px 8px 5px 118px;
        }
        @include breakpointMax(1050) {
          top: 236px;
        }
        @include breakpointMax(1030) {
          top: 230px;
          padding: 5px 8px 5px 122px;
        }
        @include breakpointMax(1020) {
          top: 228px;
        }
        @include breakpointMax(1000) {
          top: 227px;
          padding: 5px 8px 5px 128px;
        }
      }
    }
    /*********************************************************************End***************************************************/
    /*********************************************************************start***************************************************/
    & .tlc-mortgage-text {
      top: 20px;
      position: absolute;
      left: 12%;
      font-size: 24px;
      text-transform: uppercase;
      color: #b6c5cf;
      z-index: 10;
      @include breakpointMax(1200) {
        top: 15px;
      }
      @include breakpointMax(1190) {
        font-size: 21px;
      }
      @include breakpointMax(1100) {
        top: 10px;
      }
      @include breakpointMax(1025) {
        left: 9%;
      }
    }
    & .tlc-utilities-text {
      top: 20px;
      position: absolute;
      left: 46%;
      font-size: 24px;
      text-transform: uppercase;
      color: #b6c5cf;
      z-index: 10;
      @include breakpointMax(1200) {
        top: 15px;
      }
      @include breakpointMax(1190) {
        font-size: 21px;
      }
      @include breakpointMax(1100) {
        top: 10px;
      }
      @include breakpointMax(1025) {
        font-size: 21px;
      }
    }
    & .tlc-commute-text {
      top: 20px;
      position: absolute;
      right: 13%;
      font-size: 24px;
      text-transform: uppercase;
      color: #b6c5cf;
      z-index: 10;
      @include breakpointMax(1200) {
        top: 15px;
      }
      @include breakpointMax(1190) {
        font-size: 21px;
      }
      @include breakpointMax(1100) {
        top: 10px;
      }
      @include breakpointMax(1025) {
        right: 11%;
        font-size: 21px;
      }
    }
    /*********************************************************************End***************************************************/
    & .round-text {
      width: 100%;
      margin-bottom: 6px;
      font-size: 13px;
      line-height: 18px;
      word-break: break-word;
      @include breakpointMax(1130) {
        font-size: 11px;
      }
    }
    /*********************************************************************End***************************************************/
    & .round-box {
      width: 97px;
      height: 97px;
      background: #ffffff;
      border-radius: 100%;
      box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
      display: table;
      padding: 0 5px;
      @include breakpointMax(1389) {
        width: 90px;
        height: 90px;
      }
      @include breakpointMax(1350) {
        width: 85px;
        height: 85px;
      }
      @include breakpointMax(1100) {
        width: 80px;
        height: 80px;
      }
      & .round-number {
        display: table-cell;
        vertical-align: middle;
        font-size: 19px;
        @include breakpointMax(1389) {
          font-size: 17px;
        }
        @include breakpointMax(1350) {
          font-size: 16px;
        }
        @include breakpointMax(1100) {
          font-size: 15px;
        }
      }
    }
    /*********************************************************************End***************************************************/
  }

  & .mb-img-tlcbanner {
    display: none;
    @include breakpointMax(999) {
      display: inline-block;
      position: relative;
      z-index: 9;
      padding-top: 120px;
      & img {
        position: relative;
        z-index: 9;
      }
    }
    @include breakpointMax(800) {
      padding-top: 180px;
    }
    @include breakpointMax(736) {
      margin-top: 20px;
    }
    @include breakpointMax(640) {
      padding-top: 200px;
    }
    @include breakpointMax(610) {
      padding-top: 210px;
    }
    @include breakpointMax(580) {
      margin-top: 80px;
    }
    @include breakpointMax(530) {
      padding-top: 210px;
    }
    & .mb-tlc-price-amount {
      position: absolute;
      bottom: 144px;
      left: 33%;
      font-size: 20px;
      width: 200px;
      height: 40px;
      line-height: 30px;
      text-align: center;
      z-index: 9;
      background: #ffffff;
      padding: 3px 5px;
      @include breakpointMax(970) {
        bottom: 140px;
        width: 318px;
      }
      @include breakpointMax(950) {
        bottom: 136px;
        width: 315px;
      }
      @include breakpointMax(930) {
        bottom: 130px;
        width: 304px;
      }
      @include breakpointMax(900) {
        bottom: 129px;
        width: 298px;
      }
      @include breakpointMax(840) {
        bottom: 116px;
        width: 298px;
      }
      @include breakpointMax(800) {
        font-size: 28px;
        bottom: 115px;
        width: 263px;
        height: 63px;
        line-height: 68px;
      }
      @include breakpointMax(812) {
        font-size: 28px;
        bottom: 118px;
        height: 63px;
        line-height: 65px;
        width: 266px;
      }
      @include breakpointMax(770) {
        font-size: 27px;
        bottom: 111px;
        height: 59px;
        line-height: 60px;
        width: 253px;
      }
      @include breakpointMax(768) {
        bottom: 108px;
        width: 250px;
        font-size: 28px;
        height: 60px;
        line-height: 55px;
      }
      @include breakpointMax(736) {
        bottom: 104px;
        height: 58px;
        width: 243px;
      }
      @include breakpointMax(710) {
        bottom: 103px;
        height: 55px;
        width: 234px;
        font-size: 24px;
      }
      @include breakpointMax(680) {
        bottom: 101px;
        height: 50px;
        width: 223px;
        font-size: 24px;
      }
      @include breakpointMax(667) {
        line-height: 50px;
        width: 218px;
        bottom: 100px;
        height: 47px;
        font-size: 22px;
      }
      @include breakpointMax(640) {
        width: 210px;
        bottom: 95px;
      }
      @include breakpointMax(620) {
        width: 202px;
        font-size: 20px;
        height: 40px;
        line-height: 40px;
      }
      @include breakpointMax(588) {
        width: 195px;
        bottom: 86px;
        height: 46px;
        line-height: 45px;
      }
      @include breakpointMax(560) {
        width: 183px;
        bottom: 81px;
        height: 41px;
        line-height: 41px;
      }
      @include breakpointMax(520) {
        width: 171px;
        bottom: 75px;
      }
      @include breakpointMax(500) {
        width: 163px;
        bottom: 74px;
        height: 36px;
        font-size: 19px;
      }
      @include breakpointMax(460) {
        font-size: 17px;
        width: 150px;
        line-height: 35px;
        bottom: 68px;
        height: 35px;
      }
      @include breakpointMax(420) {
        line-height: 30px;
        width: 134px;
        bottom: 61px;
        height: 30px;
        font-size: 17px;
      }
      @include breakpointMax(380) {
        width: 121px;
        bottom: 54px;
        font-size: 17px;
      }
      @include breakpointMax(360) {
        width: 113px;
        bottom: 50px;
      }
      @include breakpointMax(332) {
        width: 107px;
        height: 27px;
      }
      @include breakpointMax(321) {
        line-height: 30px;
        width: 104px;
        bottom: 47px;
        height: 26px;
        font-size: 15px;
      }
    }
    & .mb-tlc-mortgage-text {
      position: absolute;
      top: 210px;
      left: 20.5%;
      font-size: 24px;
      text-transform: uppercase;
      color: #b6c5cf;
      transform: rotate(450deg);
      z-index: 9;
      @include breakpointMax(930) {
        left: 19.5%;
      }
      @include breakpointMax(820) {
        left: 18.5%;
      }
      @include breakpointMax(770) {
        font-size: 23px;
      }
      @include breakpointMax(768) {
        top: 190px;
      }
      @include breakpointMax(736) {
        top: 170px;
      }
      @include breakpointMax(730) {
        left: 17.5%;
      }
      @include breakpointMax(680) {
        top: 158px;
        left: 16.9%;
      }
      @include breakpointMax(640) {
        top: 148px;
      }
      @include breakpointMax(620) {
        font-size: 22px;
      }
      @include breakpointMax(590) {
        left: 16%;
      }
      @include breakpointMax(580) {
        font-size: 20px;
        top: 138px;
        left: 16.5%;
      }
      @include breakpointMax(530) {
        left: 16%;
      }
      @include breakpointMax(520) {
        top: 0px;
      }
      @include breakpointMax(500) {
        left: 16%;
        font-size: 19px;
      }

      @include breakpointMax(460) {
        font-size: 16px;
        left: 16%;
        top: -3px;
      }
      @include breakpointMax(414) {
        font-size: 16px;
        /* top:53px; */
        left: 16%;
      }
      @include breakpointMax(390) {
        left: 15%;
      }
      @include breakpointMax(375) {
        top: -35px;
        left: 15%;
      }
      @include breakpointMax(360) {
        left: 15%;
      }
      @include breakpointMax(350) {
        left: 13%;
      }
      @include breakpointMax(320) {
        font-size: 16px;
        left: 13%;
      }
    }
    & .mb-tlc-mortgage-round {
      position: absolute;
      top: 4%;
      left: 22%;
      width: 110px;
      text-align: center;
      z-index: 9;
      @include breakpointMax(900) {
        left: 21%;
      }
      @include breakpointMax(736) {
        top: 2%;
      }
      @include breakpointMax(730) {
        left: 20%;
      }
      @include breakpointMax(680) {
        top: -1%;
        left: 19%;
      }
      @include breakpointMax(630) {
        left: 19%;
      }
      @include breakpointMax(580) {
        left: 18%;
        top: -3%;
      }
      @include breakpointMax(530) {
        left: 17%;
      }
      @include breakpointMax(520) {
        top: inherit;
        bottom: 80%;
        width: auto;
        min-width: 85px;
        left: 17%;
      }
      @include breakpointMax(480) {
        left: 13%;
      }
      @include breakpointMax(460) {
        left: 12%;
      }

      @include breakpointMax(420) {
        width: auto;
        max-width: 85px;
        left: 9%;
      }
      @include breakpointMax(375) {
        left: 7%;
        /* bottom:32%; */
      }
      @include breakpointMax(330) {
        left: 5%;
      }
      & .mb-round-box {
        width: 85px;
        height: 85px;
        background: #ffffff;
        border-radius: 100%;
        box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
        display: table;
        padding: 0 5px;
        @include breakpointMax(520) {
          border-radius: inherit;
          height: auto;
          padding: 5px 5px;
          min-width: 85px;
          width: auto;
        }
        & .mb-round-number {
          display: table-cell;
          vertical-align: middle;
          font-size: 17px;
          @include breakpointMax(520) {
            font-size: 15px;
          }
          @include breakpointMax(320) {
            font-size: 14px;
          }
        }
      }
    }
    & .mb-tlc-utilities-round {
      position: absolute;
      top: 4%;
      left: 44%;
      width: 110px;
      text-align: center;
      z-index: 9;
      @include breakpointMax(800) {
        left: 43%;
      }
      @include breakpointMax(736) {
        top: 2%;
      }
      @include breakpointMax(680) {
        top: -1%;
      }
      @include breakpointMax(630) {
        left: 41%;
      }
      @include breakpointMax(580) {
        left: 40%;
        top: -3%;
      }
      @include breakpointMax(520) {
        left: 42%;
        top: inherit;
        bottom: 80%;
        width: auto;
        min-width: 85px;
      }
      @include breakpointMax(480) {
        left: 41%;
      }
      @include breakpointMax(375) {
        left: 37%;
        /* bottom:32%; */
      }
      @include breakpointMax(320) {
        left: 36%;
      }
      & .mb-round-box {
        width: 85px;
        height: 85px;
        background: #ffffff;
        border-radius: 100%;
        box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
        display: table;
        padding: 0 5px;
        @include breakpointMax(520) {
          border-radius: inherit;
          height: auto;
          padding: 5px 5px;
          min-width: 85px;
          width: auto;
        }
        & .mb-round-number {
          display: table-cell;
          vertical-align: middle;
          font-size: 17px;
          @include breakpointMax(520) {
            font-size: 15px;
          }
          @include breakpointMax(320) {
            font-size: 14px;
          }
        }
      }
    }
    & .mb-tlc-commute-round {
      position: absolute;
      top: 4%;
      right: 23%;
      width: 110px;
      text-align: center;
      z-index: 9;
      @include breakpointMax(900) {
        right: 22%;
      }
      @include breakpointMax(790) {
        right: 21%;
      }
      @include breakpointMax(736) {
        top: 2%;
      }
      @include breakpointMax(690) {
        right: 20%;
      }
      @include breakpointMax(680) {
        top: -1%;
      }
      @include breakpointMax(630) {
        right: 20%;
      }
      @include breakpointMax(610) {
        right: 19%;
      }
      @include breakpointMax(580) {
        top: -3%;
      }
      @include breakpointMax(530) {
        right: 17%;
      }
      @include breakpointMax(520) {
        top: inherit;
        bottom: 80%;
        right: 17%;
        width: auto;
        min-width: 85px;
      }
      @include breakpointMax(480) {
        right: 12%;
      }
      @include breakpointMax(460) {
        right: 11%;
      }
      @include breakpointMax(375) {
        right: 8%;
        /* bottom:32%; */
      }
      @include breakpointMax(350) {
        right: 6%;
      }
      @include breakpointMax(320) {
        right: 4%;
      }
      & .mb-round-box {
        width: 85px;
        height: 85px;
        background: #ffffff;
        border-radius: 100%;
        box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.1);
        display: table;
        padding: 0 5px;
        @include breakpointMax(520) {
          border-radius: inherit;
          height: auto;
          padding: 5px 5px;
          min-width: 85px;
          width: auto;
        }
        & .mb-round-number {
          display: table-cell;
          vertical-align: middle;
          font-size: 17px;
          @include breakpointMax(520) {
            font-size: 15px;
          }
          @include breakpointMax(320) {
            font-size: 14px;
          }
        }
      }
    }
    & .mb-tlc-utilities-text {
      position: absolute;
      top: 195px;
      left: 43.5%;
      font-size: 24px;
      text-transform: uppercase;
      color: #b6c5cf;
      transform: rotate(450deg);
      z-index: 9;
      @include breakpointMax(820) {
        left: 42.5%;
      }
      @include breakpointMax(770) {
        left: 42.5%;
        font-size: 23px;
      }
      @include breakpointMax(768) {
        top: 175px;
      }
      @include breakpointMax(736) {
        top: 155px;
      }
      @include breakpointMax(680) {
        top: 145px;
      }
      @include breakpointMax(640) {
        top: 135px;
      }
      @include breakpointMax(630) {
        left: 41%;
      }
      @include breakpointMax(620) {
        font-size: 22px;
        left: 41%;
      }
      @include breakpointMax(580) {
        font-size: 20px;
        top: 125px;
      }
      @include breakpointMax(520) {
        top: -11px;
      }
      @include breakpointMax(500) {
        /* top:58px; */
        font-size: 19px;
      }
      /* @include breakpointMax(480) {
				top:50px;
			} */
      @include breakpointMax(460) {
        left: 40%;
      }
      @include breakpointMax(460) {
        font-size: 16px;
        left: 41%;
        /* top:62px; */
      }
      @include breakpointMax(414) {
        font-size: 16px;
        left: 41%;
        /* top:45px; */
      }
      @include breakpointMax(375) {
        left: 40%;
        top: -44px;
      }
      @include breakpointMax(320) {
        font-size: 16px;
        left: 38%;
        /* top:12px; */
      }
    }
    & .mb-tlc-commute-text {
      position: absolute;
      top: 200px;
      right: 21.5%;
      font-size: 24px;
      text-transform: uppercase;
      color: #b6c5cf;
      transform: rotate(450deg);
      z-index: 9;
      @include breakpointMax(900) {
        right: 20.5%;
      }
      @include breakpointMax(790) {
        right: 19.5%;
      }
      @include breakpointMax(770) {
        font-size: 23px;
      }
      @include breakpointMax(768) {
        top: 180px;
      }
      @include breakpointMax(736) {
        top: 160px;
      }
      @include breakpointMax(680) {
        top: 150px;
      }
      @include breakpointMax(640) {
        top: 140px;
        right: 18%;
      }
      @include breakpointMax(620) {
        font-size: 22px;
      }
      @include breakpointMax(580) {
        font-size: 20px;
        top: 130px;
        right: 18%;
      }
      @include breakpointMax(530) {
        right: 18%;
      }
      @include breakpointMax(520) {
        top: -8px;
      }
      @include breakpointMax(500) {
        font-size: 19px;
        /* top:62px; */
      }
      @include breakpointMax(480) {
        /* top:52px; */
      }
      @include breakpointMax(460) {
        font-size: 16px;
        right: 19%;
      }
      @include breakpointMax(440) {
        right: 18%;
      }
      @include breakpointMax(414) {
        font-size: 16px;
        right: 18%;
      }
      @include breakpointMax(390) {
        right: 17%;
      }
      @include breakpointMax(375) {
        top: -41px;
        right: 17%;
      }
      @include breakpointMax(360) {
        right: 16%;
      }
      @include breakpointMax(350) {
        right: 15%;
      }
      @include breakpointMax(320) {
        font-size: 16px;
        right: 15%;
      }
    }
    & .mb-tlc-home-smallbox {
      width: 270px;
      height: 40px;
      background: #ffffff;
      position: absolute;
      text-align: left;
      z-index: 9;
      & .sub-box-inner {
        display: table;
        vertical-align: middle;
        height: 30px;
        width: 100%;
        & .box-info {
          display: table-cell;
          vertical-align: middle;
          font-size: 13px;
          width: 100%;
          & .info-highlight {
            display: inline-block;
            &.first-address-box {
              display: block;
              line-height: normal;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              height: 15px;
              width: 184px;
              @include breakpointMax(999) {
                width: 250px;
              }
              @include breakpointMax(650) {
                width: 230px;
              }
              @include breakpointMax(600) {
                width: 200px;
              }
              @include breakpointMax(530) {
                width: 190px;
              }
              @include breakpointMax(520) {
                width: 230px;
              }
            }
          }
          & .second-address-box {
            font-size: 12px;
            padding-top: 3px;
            display: block;
            line-height: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 15px;
            width: 182px;
            @include breakpointMax(999) {
              width: 250px;
            }
            @include breakpointMax(650) {
              width: 230px;
            }
            @include breakpointMax(600) {
              width: 200px;
            }
            @include breakpointMax(530) {
              width: 190px;
            }
            @include breakpointMax(520) {
              width: 230px;
            }
          }
          & .second-info-box {
            display: inline-block;
            padding-left: 5px;
          }
        }
      }
      &.mb-property-full-tlc {
        left: calc(50% - 135px);
        top: 35%;
        padding: 5px 10px;
        text-align: center;
        & .sub-box-inner {
          & .box-info {
            font-size: 18px;
            line-height: 28px;
          }
        }
        @include breakpointMax(800) {
          top: 34%;
        }
        @include breakpointMax(650) {
          left: calc(50% - 125px);
          width: 250px;
          top: 31.8%;
        }
        @include breakpointMax(600) {
          left: calc(50% - 110px);
          width: 220px;
          top: 31.8%;
        }
        @include breakpointMax(530) {
          left: calc(50% - 106px);
          width: 210px;
          top: 31.8%;
        }
        @include breakpointMax(520) {
          top: 27.7%;
          left: calc(50% - 125px);
          width: 250px;
        }
        @include breakpointMax(450) {
          top: 29%;
        }
        @include breakpointMax(400) {
          top: 30.9%;
        }
        @include breakpointMax(375) {
          top: 26.4%;
        }
      }
      &.mb-property-full-price {
        left: calc(50% - 135px);
        top: 40%;
        padding: 5px 10px;
        text-align: center;
        @include breakpointMax(800) {
          top: 40%;
        }
        @include breakpointMax(650) {
          left: calc(50% - 125px);
          width: 250px;
          top: 38.9%;
        }
        @include breakpointMax(600) {
          left: calc(50% - 110px);
          width: 220px;
          top: 38.9%;
        }
        @include breakpointMax(530) {
          left: calc(50% - 106px);
          width: 210px;
          top: 38.9%;
        }
        @include breakpointMax(520) {
          top: 35.7%;
          left: calc(50% - 125px);
          width: 250px;
        }
        @include breakpointMax(450) {
          top: 37%;
        }
        @include breakpointMax(400) {
          top: 39%;
        }
        @include breakpointMax(375) {
          top: 35.6%;
        }
      }
      &.mb-property-full-mls {
        left: calc(50% - 135px);
        top: 45%;
        padding: 5px 10px;
        text-align: center;
        @include breakpointMax(800) {
          top: 46%;
        }
        @include breakpointMax(650) {
          left: calc(50% - 125px);
          width: 250px;
          top: 45.9%;
        }
        @include breakpointMax(600) {
          left: calc(50% - 110px);
          width: 220px;
          top: 45.9%;
        }
        @include breakpointMax(530) {
          left: calc(50% - 106px);
          width: 210px;
          top: 45.9%;
        }
        @include breakpointMax(520) {
          top: 43.8%;
          left: calc(50% - 125px);
          width: 250px;
        }
        @include breakpointMax(450) {
          top: 45%;
        }
        @include breakpointMax(400) {
          top: 46.9%;
        }
        @include breakpointMax(375) {
          top: 44.9%;
        }
      }
      &.mb-property-full-address {
        left: calc(50% - 135px);
        top: 50%;
        padding: 5px 10px;
        text-align: center;
        @include breakpointMax(800) {
          top: 52%;
        }
        @include breakpointMax(650) {
          left: calc(50% - 125px);
          width: 250px;
          top: 53%;
        }
        @include breakpointMax(600) {
          left: calc(50% - 110px);
          width: 220px;
          top: 53%;
        }
        @include breakpointMax(530) {
          left: calc(50% - 106px);
          width: 210px;
          top: 53%;
        }
        @include breakpointMax(520) {
          top: 52%;
          left: calc(50% - 125px);
          width: 250px;
        }
        @include breakpointMax(450) {
          top: 53%;
        }
        @include breakpointMax(400) {
          top: 55%;
        }
        @include breakpointMax(375) {
          top: 54%;
        }
      }
      &.mb-property-full-bed {
        left: calc(50% - 135px);
        top: 55%;
        padding: 5px 10px;
        text-align: center;
        & .sub-box-inner {
          & .box-info {
            & .second-info-box {
              padding-left: 5px;
            }
          }
        }
        @include breakpointMax(800) {
          top: 58%;
        }
        @include breakpointMax(650) {
          left: calc(50% - 125px);
          width: 250px;
          top: 60%;
        }
        @include breakpointMax(600) {
          left: calc(50% - 110px);
          width: 220px;
          top: 60%;
        }
        @include breakpointMax(530) {
          left: calc(50% - 106px);
          width: 210px;
          top: 60%;
        }
        @include breakpointMax(520) {
          top: 60%;
          left: calc(50% - 125px);
          width: 250px;
        }
        @include breakpointMax(450) {
          top: 61%;
        }
        @include breakpointMax(400) {
          top: 63%;
        }
        @include breakpointMax(375) {
          top: 63%;
        }
      }
    }
    & .mb-property-photo {
      width: 240px;
      height: 140px;
      position: absolute;
      bottom: 149px;
      left: calc(50% - 123px);
      border: 3px solid #fff;
      overflow: hidden;
      min-height: 140px;
      background: #ffffff;
      z-index: 9;
      & img {
        width: 100%;
        position: absolute;
        height: auto;
        margin: auto;
        top: -100%;
        right: -100%;
        bottom: -100%;
        left: -100%;
        min-height: 140px;
      }
      @include breakpointMax(990) {
        left: calc(50% - 122px);
      }
      @include breakpointMax(970) {
        left: calc(50% - 121px);
        bottom: 145px;
      }
      @include breakpointMax(950) {
        left: calc(50% - 112px);
        bottom: 160px;
        width: 220px;
        height: 120px;
        min-height: 120px;
        & img {
          min-height: 120px;
        }
      }
      @include breakpointMax(930) {
        bottom: 155px;
      }
      @include breakpointMax(920) {
        bottom: 150px;
      }
      @include breakpointMax(900) {
        bottom: 145px;
      }
      @include breakpointMax(890) {
        left: calc(50% - 111px);
        bottom: 143px;
      }
      @include breakpointMax(880) {
        bottom: 140px;
      }
      @include breakpointMax(870) {
        left: calc(50% - 106px);
        bottom: 147px;
        width: 210px;
        height: 110px;
        min-height: 110px;
        & img {
          min-height: 110px;
        }
      }
      @include breakpointMax(860) {
        bottom: 145px;
      }
      @include breakpointMax(850) {
        bottom: 140px;
      }
      @include breakpointMax(840) {
        bottom: 135px;
      }
      @include breakpointMax(820) {
        left: calc(50% - 102px);
        bottom: 138px;
        width: 200px;
        height: 100px;
        min-height: 100px;
        & img {
          min-height: 100px;
        }
      }
      @include breakpointMax(800) {
        bottom: 135px;
      }
      @include breakpointMax(790) {
        bottom: 133px;
        left: calc(50% - 91px);
        width: 180px;
        height: 90px;
        min-height: 100px;
        & img {
          min-height: 90px;
        }
      }
      @include breakpointMax(780) {
        bottom: 130px;
      }
      @include breakpointMax(770) {
        bottom: 128px;
      }
      @include breakpointMax(760) {
        bottom: 125px;
      }
      @include breakpointMax(750) {
        bottom: 123px;
      }
      @include breakpointMax(740) {
        left: calc(50% - 80px);
        bottom: 135px;
        width: 160px;
        height: 80px;
        min-height: 80px;
        & img {
          min-height: 80px;
        }
      }
      @include breakpointMax(720) {
        bottom: 130px;
      }
      @include breakpointMax(700) {
        bottom: 125px;
      }
      @include breakpointMax(680) {
        bottom: 120px;
        left: calc(50% - 81px);
      }
      @include breakpointMax(670) {
        bottom: 117px;
      }
      @include breakpointMax(650) {
        bottom: 111px;
      }
      @include breakpointMax(640) {
        bottom: 117px;
        left: calc(50% - 71px);
        width: 140px;
        height: 70px;
        min-height: 70px;
        & img {
          min-height: 70px;
        }
      }
      @include breakpointMax(630) {
        bottom: 113px;
      }
      @include breakpointMax(610) {
        bottom: 110px;
      }
      @include breakpointMax(600) {
        bottom: 105px;
      }
      @include breakpointMax(590) {
        bottom: 103px;
      }
      @include breakpointMax(580) {
        bottom: 100px;
        left: calc(50% - 72px);
      }
      @include breakpointMax(570) {
        bottom: 98px;
      }
      @include breakpointMax(560) {
        bottom: 92px;
        left: calc(50% - 71px);
      }
      @include breakpointMax(550) {
        left: calc(50% - 60px);
        bottom: 100px;
        width: 120px;
        height: 60px;
        min-height: 60px;
        & img {
          min-height: 60px;
        }
      }
      @include breakpointMax(530) {
        left: calc(50% - 62px);
        bottom: 95px;
      }
      @include breakpointMax(520) {
        left: calc(50% - 62px);
        bottom: 88px;
        width: 120px;
        height: 60px;
        min-height: 60px;
        & img {
          min-height: 60px;
        }
      }
      @include breakpointMax(510) {
        left: calc(50% - 61px);
        bottom: 85px;
      }
      @include breakpointMax(490) {
        left: calc(50% - 56px);
        bottom: 85px;
        width: 110px;
        height: 55px;
        min-height: 55px;
        & img {
          min-height: 55px;
        }
      }
      @include breakpointMax(470) {
        bottom: 80px;
      }
      @include breakpointMax(450) {
        bottom: 73px;
      }
      @include breakpointMax(440) {
        left: calc(50% - 51px);
        bottom: 73px;
        width: 100px;
        height: 50px;
        min-height: 50px;
        & img {
          min-height: 50px;
        }
      }
      @include breakpointMax(420) {
        left: calc(50% - 50.5px);
        bottom: 71px;
      }
      @include breakpointMax(400) {
        left: calc(50% - 45px);
        bottom: 70px;
        width: 90px;
        height: 45px;
        min-height: 45px;
        & img {
          min-height: 45px;
        }
      }
      @include breakpointMax(390) {
        left: calc(50% - 45.5px);
        bottom: 67px;
      }
      @include breakpointMax(380) {
        left: calc(50% - 45px);
        bottom: 66px;
      }
      @include breakpointMax(375) {
        left: calc(50% - 46px);
        bottom: 60px;
        width: 90px;
        height: 45px;
        min-height: 45px;
        & img {
          min-height: 45px;
        }
      }
      @include breakpointMax(360) {
        left: calc(50% - 40px);
        bottom: 63px;
        width: 80px;
        height: 40px;
        min-height: 40px;
        & img {
          min-height: 40px;
        }
      }
      @include breakpointMax(350) {
        left: calc(50% - 40.5px);
        bottom: 61px;
      }
      @include breakpointMax(340) {
        left: calc(50% - 40px);
        bottom: 58px;
      }
      @include breakpointMax(330) {
        bottom: 55px;
      }
      @include breakpointMax(320) {
        bottom: 53px;
      }
    }
    & .mb-tlc-plus-left {
      position: absolute;
      top: 195px;
      right: calc(50% - 120px);
      width: 24px;
      @include breakpointMax(870) {
        right: calc(50% - 113px);
      }
      @include breakpointMax(850) {
        right: calc(50% - 105px);
      }
      @include breakpointMax(800) {
        right: calc(50% - 100px);
      }
      @include breakpointMax(760) {
        top: 185px;
      }
      @include breakpointMax(750) {
        right: calc(50% - 95px);
      }
      @include breakpointMax(736) {
        top: 170px;
      }
      @include breakpointMax(750) {
        right: calc(50% - 90px);
      }
      @include breakpointMax(680) {
        top: 160px;
      }
      @include breakpointMax(650) {
        right: calc(50% - 85px);
      }
      @include breakpointMax(640) {
        top: 150px;
        width: 20px;
      }
      @include breakpointMax(600) {
        right: calc(50% - 79px);
      }
      @include breakpointMax(580) {
        top: 140px;
      }
      @include breakpointMax(520) {
        right: calc(50% - 68px);
        top: -4px;
      }
      @include breakpointMax(480) {
        right: calc(50% - 62px);
        width: 18px;
      }
      @include breakpointMax(400) {
        right: calc(50% - 55px);
        width: 17px;
      }
      @include breakpointMax(350) {
        top: -40px;
      }
      @include breakpointMax(330) {
        right: calc(50% - 49px);
        width: 14px;
      }
      @include breakpointMax(330) {
        right: calc(50% - 45px);
        width: 14px;
      }
    }
    & .mb-tlc-plus-right {
      position: absolute;
      top: 195px;
      left: calc(50% - 120px);
      width: 24px;
      @include breakpointMax(870) {
        left: calc(50% - 113px);
      }
      @include breakpointMax(850) {
        left: calc(50% - 105px);
      }
      @include breakpointMax(800) {
        left: calc(50% - 100px);
      }
      @include breakpointMax(760) {
        top: 185px;
      }
      @include breakpointMax(750) {
        left: calc(50% - 95px);
      }
      @include breakpointMax(736) {
        top: 170px;
      }
      @include breakpointMax(700) {
        left: calc(50% - 90px);
      }
      @include breakpointMax(680) {
        top: 160px;
      }
      @include breakpointMax(650) {
        left: calc(50% - 85px);
      }
      @include breakpointMax(640) {
        top: 150px;
        width: 20px;
      }
      @include breakpointMax(600) {
        left: calc(50% - 79px);
      }
      @include breakpointMax(580) {
        top: 140px;
      }
      @include breakpointMax(520) {
        left: calc(50% - 68px);
        top: -4px;
      }
      @include breakpointMax(480) {
        left: calc(50% - 62px);
        width: 18px;
      }
      @include breakpointMax(400) {
        left: calc(50% - 50px);
        width: 17px;
      }
      @include breakpointMax(350) {
        top: -40px;
      }
      @include breakpointMax(330) {
        left: calc(50% - 44px);
        width: 14px;
      }
      @include breakpointMax(320) {
        left: calc(50% - 42px);
        width: 14px;
      }
    }
  }
}
/**********************************************************************************************************New Style End**********************************************/

/*****************************TLC Help End***********************************/

/*****************************Properties card start***********************************/
.prop-card {
  width: 290px;
  float: none;
  display: inline-block;
  box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  margin-bottom: 30px;
  text-align: left;
  @include breakpointMax(630) {
    margin-bottom: 20px;
  }
  & .slider-img-box {
    width: 100%;
    float: left;
    position: relative;
    & .oval-icon {
      height: 34px;
      width: 34px;
      background-color: #ffffff;
      border-radius: 100%;
      box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.11);
      text-align: center;
      line-height: normal;
      & a {
        display: block;
      }
      & i {
        font-size: 24px;
        line-height: 36px;
      }
      &.heart-icon {
        position: absolute;
        top: 8px;
        left: 8px;
        &.heart-second-icon {
          left: 50px;
        }
      }
      &.compare-icon {
        position: absolute;
        top: 8px;
        right: 50px;
        &.compare-last-icon {
          right: 8px;
        }
      }
      &.close-icon {
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }
    & .prop-open-house {
      height: 23px;
      max-width: 190px;
      opacity: 0.6;
      font-size: 10px;
      text-align: left;
      padding: 5px 10px 5px 10px;
      position: absolute;
      bottom: 6px;
      right: 0;
      border-radius: 10px 0 0px 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background: rgba(3, 50, 88, 50);
      color: #fff;
      &.prop-open-house-full {
        max-width: 275px;
      }
    }
    & .slider-img {
      width: 100%;
      display: block;
      position: relative;
      overflow: hidden;
      min-height: 190px;
      background: #ffffff;
      border-radius: 8px 8px 0 0;
      & img {
        width: 100%;
        position: absolute;
        height: auto;
        margin: auto;
        top: -100%;
        right: -100%;
        bottom: -100%;
        left: -100%;
        min-height: 190px;
      }
      & .slide {
        & img {
          width: 100%;
          position: inherit;
          height: 190px;
          margin: auto;
          top: inherit;
          right: inherit;
          bottom: inherit;
          left: inherit;
          min-height: 190px;
        }
      }
      & .carousel-slider {
        & .slider-wrapper {
          & .slider {
            & .slide {
              & a {
                display: block;
              }
              & img {
                border-radius: 8px 8px 0 0;
              }
            }
          }
        }
        & .control-arrow {
          top: calc(50% - 25px);
          height: 50px;
          padding: 0px 5px;
          display: table;
          width: 30px;
          text-align: center;
          background: rgba(0, 0, 0, 0);

          background-repeat: repeat-x;
          line-height: normal;
          opacity: 1;
          &.control-prev {
            background-image: linear-gradient(
              90deg,
              rgba(0, 0, 0, 0.5) 0,
              rgba(0, 0, 0, 0.0001)
            );
            border-radius: 0 4px 4px 0;
            &:before {
              content: "\f104";
              font-family: "FontAwesome";
              border-top: none;
              border-bottom: none;
              border-right: none;
              font-size: 20px;
              line-height: normal;
              display: table-cell;
              vertical-align: middle;
              text-align: center;
              width: 30px;
            }
          }
          &.control-next {
            background-image: linear-gradient(
              270deg,
              rgba(0, 0, 0, 0.5) 0,
              rgba(0, 0, 0, 0.0001)
            );
            border-radius: 4px 0 0 4px;
            &:before {
              content: "\f105";
              font-family: "FontAwesome";
              border-top: none;
              border-bottom: none;
              border-left: none;
              font-size: 20px;
              line-height: normal;
              display: table-cell;
              vertical-align: middle;
              text-align: center;
              width: 30px;
            }
          }
        }
      }
    }
  }
  & .ads-card {
    cursor: pointer;
    & img {
      border-radius: 8px;
    }
  }
  & .card-prop-info {
    width: 100%;
    float: left;
    padding: 12px 14px 12px 14px;
    /* min-height:100px; */
    min-height: 177px;
    & .card-prop-amount {
      width: 43%;
      float: left;
      text-align: left;
      padding: 0px;
      font-size: 14px;
      line-height: normal;
      height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &.card-prop-amount-full {
        width: 100%;
      }
    }
    & .card-tlc-amount {
      width: auto;
      float: right;
      font-size: 12px;
      line-height: 14px;
      padding: 2px 4px;
      text-align: left;
      max-width: 147px;
      border: 1px solid;
      border-radius: 2px;
    }
    & .prop-info-basic {
      width: 100%;
      float: left;
      padding-top: 7px;
      & .card-add-lt {
        width: 63%;
        display: inline-block;
        vertical-align: middle;
        & .address-line {
          height: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: normal;
          font-size: 10px;
          &:first-child {
            font-size: 12px;
            margin-bottom: 2px;
            height: 17px;
          }
        }
        &.card-add-lt-full {
          width: 100%;
        }
      }
      & .card-add-rt {
        width: 37%;
        display: inline-block;
        vertical-align: top;
        & .card-mls-number {
          font-size: 10px;
          margin-bottom: 10px;
          text-align: right;
        }
        & .card-virtual-icon {
          width: 100%;
          display: inline-block;
          vertical-align: top;
          text-align: right;
          & .card-camera-icon {
            width: 25px;
            display: inline-block;
            vertical-align: middle;
          }
          & .card-tour-icon {
            width: 25px;
            display: inline-block;
            vertical-align: middle;
            margin-left: 7px;
          }
          & .card-ecomm-icon {
            width: 25px;
            display: inline-block;
            vertical-align: middle;
            margin-left: 7px;
          }
        }
      }
      & .prop-info-beds {
        width: 100%;
        float: left;
        line-height: normal;
        font-size: 10px;
        margin-top: 2px;
        & span {
          display: inline-block;
          vertical-align: top;
          margin-left: 7px;
          &:first-child {
            margin-left: 0px;
          }
        }
      }
      & .prop-info-ext {
        width: 100%;
        display: block;
        margin-top: 6px;
        & .prop-open-house-ext {
          font-size: 10px;
          line-height: 13px;
          background: #dee2e6;
          padding: 6px;
          border-radius: 4px;
          margin-top: 6px;
          text-align: center;
          vertical-align: middle;
          min-height: 38px;
          display: table;
          width: 100%;
          & .prop-open-house-cnt {
            display: table-cell;
            vertical-align: middle;
            width: 100%;
          }
        }
        & .courtesy-txt-ext {
          font-size: 10px;
          line-height: 13px;
          min-height: 26px;
          width: 100%;
          display: block;
        }
      }
    }
  }
  & .card-status {
    position: absolute;
    bottom: 6px;
    left: 6px;
    margin: 0px;
    padding: 5px 10px;
    border-radius: 11px;
    font-size: 10px;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    min-height: 23px;
    max-width: 86px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.card-status-full {
      max-width: 230px;
    }
  }
  & .no-photo-overlay {
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    z-index: 0;
    position: absolute;
    bottom: 35px;
    /* padding-top: 9px;
		padding-bottom: 9px; */
    text-align: center;
    color: #ffffff;
    padding: 12px;
    line-height: 20px;
  }
  & .card-map-marker {
    margin-right: 5px;
  }
  & .mls-logo-overlay {
    /* top: 0px;
		left: calc(50% - 24px);
		border-radius: 0 0 4px 4px; */
    bottom: 6px;
    right: 6px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.6);
    z-index: 0;
    position: absolute;
    text-align: center;
    padding: 4px;
    & img {
      width: 48px;
    }
  }
}

.tooltip-tlc-summary-box {
  background-color: #f7f7f7 !important;
  border: 2px solid #555 !important;
  width: 270px;
  &::before {
    border-top: 8px solid #555 !important;
    border-width: 8px 8px 0;
  }
  &::after {
    border-top: 8px solid #f7f7f7 !important;
    border-width: 8px 8px 0;
  }
  & .summary-wrapper {
    font-size: 13px !important;
    color: #5a687b !important;
    & .summary-total {
      font-weight: bold;
    }
  }
  @include breakpointMax(768) {
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }
}

/*****************************Properties card End***********************************/
.horizontal-prop-card {
  display: inline-block;
  /* @include breakpointMax(550) {
		text-align:center;
	} */
  & .current-prop-box {
    width: 100%;
    float: left;
    background: #fff;
    box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
    margin-bottom: 25px;
    min-height: 110px;
    border-radius: 8px;
    @include breakpointMax(576) {
      width: 100%;
      float: none;
      display: inline-block;
    }
    & .current-prop-img {
      width: 164px;
      float: left;
      overflow: hidden;
      min-height: 110px;
      position: relative;
      background: #f7f7f7;
      border-radius: 8px 0 0 8px;
      @include breakpointMax(576) {
        width: 100%;
        border-radius: 8px 8px 0px 0px;
      }
      & img {
        width: 100%;
        position: absolute;
        height: auto;
        margin: auto;
        top: -100%;
        right: -100%;
        bottom: -100%;
        left: -100%;
        min-height: 110px;
        border-radius: 8px 0 0 8px;
      }
      & .oval-icon {
        height: 34px;
        width: 34px;
        background-color: #ffffff;
        border-radius: 100%;
        box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.11);
        text-align: center;
        line-height: normal;
        & a {
          display: block;
        }
        & i {
          font-size: 22px;
          line-height: 34px;
        }
        &.heart-icon {
          position: absolute;
          top: 8px;
          left: 8px;
          &.heart-second-icon {
            top: 50px;
          }
        }
      }
    }
    & .current-prop-dt {
      width: calc(75% - 164px);
      float: left;
      padding: 5px 0 0 16px;
      min-height: 110px;
      @include breakpointMax(1199) {
        width: calc(80% - 164px);
      }
      @include breakpointMax(700) {
        width: calc(74% - 164px);
      }
      @include breakpointMax(576) {
        width: 62%;
        text-align: left;
        padding: 5px 0 0 7px;
      }
      @include breakpointMax(330) {
        padding: 5px 0 5px 7px;
      }
      & .current-mls-number {
        font-size: 10px;
        width: 100%;
        float: left;
        padding: 3px 0 8px 0;
      }
      & .current-pro-price {
        font-size: 14px;
        width: 100%;
        float: left;
        & .current-amount {
          display: inline-block;
          vertical-align: middle;
          padding-right: 8px;
        }
        & .status-active {
          max-width: 84px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 3px 9px;
          font-size: 9px;
          text-transform: uppercase;
        }
      }
      & .current-pro-address {
        font-size: 12px;
        width: 100%;
        float: left;
        padding-top: 5px;
        height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: normal;
      }
      & .current-pro-subaddress {
        font-size: 10px;
        width: 100%;
        float: left;
        padding-top: 5px;
        height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: normal;
      }
      & .current-pro-beds {
        width: 100%;
        float: left;
        line-height: normal;
        font-size: 10px;
        margin-top: 2px;
        & span {
          display: inline-block;
          vertical-align: top;
          margin-left: 7px;
          &:first-child {
            margin-left: 0px;
          }
        }
      }
    }
    & .current-prop-right {
      width: 25%;
      float: left;
      text-align: left;
      padding: 40px 0 0 15px;
      @include breakpointMax(1199) {
        width: 20%;
      }
      @include breakpointMax(700) {
        width: 26%;
      }
      @include breakpointMax(576) {
        width: 38%;
        float: right;
        padding: 30px 7px 0 5px;
      }
      & .current-tlc-amount {
        width: auto;
        float: left;
        font-size: 12px;
        line-height: 14px;
        padding: 2px 4px;
        text-align: left;
        max-width: 100%;
        border-radius: 2px;
        border: 1px solid;
        @include breakpointMax(550) {
          float: right;
          font-size: 11px;
        }
      }
    }
    & .card-status {
      border-radius: 11px;
      padding: 3px 10px;
      font-size: 8px;
      display: inline-block;
      line-height: normal;
      vertical-align: middle;
      min-height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 72px;
      text-transform: uppercase;
      text-align: center;
    }
  }
  &.checkbox-prop-card {
    & .current-prop-main {
      width: 100%;
      float: left;
      & .common-checkbox {
        display: inline-block;
        margin-top: 10px;
      }
      & .checkbox-lt {
        width: 5%;
        float: left;
        text-align: right;
        @include breakpointMax(1199) {
          text-align: left;
        }
        @include breakpointMax(576) {
          width: 7%;
        }
        @include breakpointMax(374) {
          width: 100%;
          margin-bottom: 7px;
        }
      }
      & .current-prop-box {
        width: 95%;
        @include breakpointMax(576) {
          width: 93%;
        }
        @include breakpointMax(374) {
          width: 100%;
        }
      }
    }
  }
}
/*****************************Featured Properties start***********************************/
.home-featured-listing {
  margin-top: 25px;
  padding: 0 20px;
  width: 100%;
  float: left;
  &.extra-space {
    margin-top: 55px;
  }
  @include breakpointMax(1300) {
    padding: 0 10px;
  }
  @include breakpointMax(1280) {
    padding: 0 6px;
  }
  @include breakpointMax(1199) {
    padding: 0 20px;
  }
  @include breakpointMax(630) {
    padding: 0 15px;
  }
  @include breakpointMax(320) {
    padding: 0 5px;
  }
  & .white-card-content {
    background: #fff;
    border-radius: 8px;
    padding: 37px 0px 30px 0px;
    width: 100%;
    display: block;
    & .section-cards {
      text-align: center;
      margin-top: 30px;
      & .cards-scroll {
        @include breakpointMax(630) {
          max-width: 600px;
          overflow-x: scroll;
          overflow-y: hidden;
          /* height:320px; */
          height: 380px;
          /* white-space:nowrap; */
          white-space: normal;
          margin-right: -15px;
          padding-left: 12px;
        }
        @include breakpointMax(320) {
          margin-right: -5px;
        }
      }
    }
    & .container {
      max-width: 100%;
      padding: 0 50px;
      @include breakpointMax(1367) {
        padding: 0 15px;
      }
      @include breakpointMax(1280) {
        padding: 0 4px;
      }
      @include breakpointMax(1199) {
        padding: 0 15px;
      }
      @include breakpointMax(700) {
        padding: 0 15px;
      }
      @include breakpointMax(320) {
        padding: 0 5px;
      }
      & .row {
        @include breakpointMax(1280) {
          margin-left: -3px;
          margin-right: -3px;
        }
        @include breakpointMax(1199) {
          margin-left: -15px;
          margin-right: -15px;
        }
        @include breakpointMax(630) {
          flex-wrap: inherit;
        }
        @include breakpointMax(320) {
          margin-left: -5px;
          margin-right: -5px;
        }
        & .custom-col {
          @include breakpointMax(1280) {
            padding-left: 3px;
            padding-right: 3px;
          }
          @include breakpointMax(1199) {
            padding-left: 15px;
            padding-right: 15px;
          }
          @include breakpointMax(990) {
            flex: 0 0 50%;
            max-width: 50%;
          }
          @include breakpointMax(700) {
            padding-left: 3px;
            padding-right: 3px;
          }
          @include breakpointMax(630) {
            display: inline-block;
            flex: 0 0 20%;
            max-width: 100%;
            padding-left: 12px;
            padding-right: 11px;
          }
          @include breakpointMax(320) {
            padding-left: 0px;
            padding-right: 4px;
          }
        }
        &.openhouse-row {
          & .custom-col {
            @include breakpointMax(630) {
              padding-left: 11px;
              padding-right: 11px;
            }
            @include breakpointMax(320) {
              padding-left: 0px;
              padding-right: 0px;
            }
          }
        }
      }
    }
    &.pp-card-content {
      padding-top: 0;
      padding-bottom: 0;
      & .pp-title {
        margin: auto;
        @include breakpointMax(575) {
          padding: 16px 0;
        }
      }
      & .container {
        max-width: 100%;
        padding: 0 50px;
        @include breakpointMax(1367) {
          padding: 0 15px;
        }
        @include breakpointMax(1280) {
          padding: 0 4px;
        }
        @include breakpointMax(1199) {
          padding: 0 15px;
        }
        @include breakpointMax(700) {
          padding: 0 15px;
        }
        @include breakpointMax(320) {
          padding: 0 5px;
        }
        & .row {
          @include breakpointMax(630) {
            flex-wrap: wrap;
          }
        }
      }
    }
    & .more-listing {
      text-align: right;
      @include breakpointMax(767) {
        text-align: left;
        padding-top: 15px;
      }
      a {
        font-family: "GothamMedium";
        font-size: 14px;
      }
    }
  }
}
/*****************************Latest mls start***********************************/
.home-explain-listing {
  margin-top: 25px;
  margin-bottom: 50px;
  padding: 0 20px;
  width: 100%;
  float: left;
  & .white-card-content {
    background: #fff;
    border-radius: 8px;
    width: 100%;
    display: block;
    padding: 30px 0px 30px 0px;
    & .container {
      padding: 0 0 0 15px;
      & .section-title {
        margin-bottom: 50px;
        padding: 0 15px 0 0;
        & h1 {
          @include breakpointMax(730) {
            line-height: 28px;
          }
        }
        & .explain-logo {
          width: 100%;
          display: block;
          margin-bottom: 15px;
          & img {
            width: auto;
          }
        }
        & h2 {
          font-size: 20px;
        }
      }
    }
  }

  & .tlc-explain-box {
    width: 100%;
    display: block;
    position: relative;
    & .explain-banner {
      width: 100%;
      display: block;
      & img {
        width: 100%;
        position: relative;
        z-index: 1;
        @include breakpointMax(1023) {
          margin-top: 30px;
        }
      }
    }
    & .explain-content-box {
      width: calc(100% - 52%);
      position: absolute;
      top: 55px;
      left: 52%;
      z-index: 0;
      @include breakpointMax(1400) {
        top: 51px;
      }
      @include breakpointMax(1398) {
        top: 54px;
      }
      @include breakpointMax(1390) {
        top: 53px;
      }
      @include breakpointMax(1370) {
        top: 52px;
      }
      @include breakpointMax(1340) {
        top: 51px;
      }
      @include breakpointMax(1320) {
        top: 50px;
      }
      @include breakpointMax(1300) {
        top: 49px;
      }
      @include breakpointMax(1200) {
        top: 46px;
      }
      @include breakpointMax(1210) {
        top: 45px;
      }
      @include breakpointMax(1250) {
        top: 48px;
      }
      @include breakpointMax(1230) {
        top: 47px;
      }
      @include breakpointMax(1190) {
        top: 45px;
      }
      @include breakpointMax(1160) {
        top: 45px;
      }
      @include breakpointMax(1150) {
        top: 44px;
      }
      @include breakpointMax(1120) {
        top: 40px;
      }
      @include breakpointMax(1100) {
        top: 41px;
      }
      @include breakpointMax(1080) {
        top: 41px;
      }
      @include breakpointMax(1087) {
        top: 42px;
      }
      @include breakpointMax(1060) {
        top: 40px;
      }
      @include breakpointMax(1045) {
        top: 39px;
      }
      @include breakpointMax(1030) {
        top: 39px;
      }
      @include breakpointMax(1024) {
        top: 39px;
      }
      @include breakpointMax(1023) {
        position: inherit;
        width: 100%;
        display: block;
        top: inherit;
        left: inherit;
        z-index: inherit;
        margin-bottom: 5px;
      }
      /* Red start */
      & .stoplight-red-cnt {
        width: 72%;
        display: block;
        background: linear-gradient(
          90deg,
          #d74d4d 0%,
          rgba(225, 225, 225, 0) 100%
        );
        height: 58px;
        color: #000000;
        padding: 0 0 0 72px;
        font-size: 16px;
        overflow: hidden;
        & .red-cnt-box {
          display: table;
          height: 58px;
          width: 70%;
          @include breakpointMax(1360) {
            height: 56px;
          }
          @include breakpointMax(1320) {
            height: 55px;
          }
          @include breakpointMax(1310) {
            height: 52px;
          }
          @include breakpointMax(1300) {
            height: 54px;
          }
          @include breakpointMax(1280) {
            height: 51px;
          }
          @include breakpointMax(1230) {
            height: 50px;
          }
          @include breakpointMax(1200) {
            height: 49px;
            width: 78%;
          }
          @include breakpointMax(1180) {
            height: 47px;
          }
          @include breakpointMax(1100) {
            height: 44px;
          }
          @include breakpointMax(1120) {
            height: 46px;
          }
          @include breakpointMax(1060) {
            height: 42px;
          }
          @include breakpointMax(1030) {
            height: 42px;
            width: 90%;
          }
          @include breakpointMax(1024) {
            height: 41px;
          }
          @include breakpointMax(1023) {
            height: auto;
          }
          & .red-box {
            display: table-cell;
            vertical-align: middle;
          }
        }
        @include breakpointMax(1360) {
          height: 56px;
        }
        @include breakpointMax(1320) {
          height: 55px;
          width: 76%;
        }
        @include breakpointMax(1310) {
          font-size: 15px;
          height: 52px;
        }
        @include breakpointMax(1300) {
          height: 54px;
          font-size: 15px;
        }
        @include breakpointMax(1280) {
          height: 51px;
        }
        @include breakpointMax(1230) {
          height: 50px;
        }
        @include breakpointMax(1200) {
          height: 49px;
          padding: 0 0 0 60px;
        }
        @include breakpointMax(1180) {
          height: 47px;
        }
        @include breakpointMax(1100) {
          top: 41px;
          height: 44px;
        }
        @include breakpointMax(1120) {
          height: 46px;
        }
        @include breakpointMax(1080) {
          width: 80%;
        }
        @include breakpointMax(1060) {
          height: 42px;
          font-size: 14px;
        }
        @include breakpointMax(1030) {
          height: 42px;
          font-size: 14px;
          width: 80%;
        }
        @include breakpointMax(1024) {
          height: 41px;
          font-size: 14px;
          width: 80%;
        }
        @include breakpointMax(1023) {
          position: inherit;
          width: 100%;
          display: block;
          top: inherit;
          left: inherit;
          height: auto;
          padding: 15px;
          margin-bottom: 10px;
          font-size: 16px;
        }
      }
      /* yellow start */
      & .stoplight-yellow-cnt {
        width: 72%;
        display: block;
        background: linear-gradient(
          90deg,
          #f6df83 0%,
          rgba(225, 225, 225, 0) 100%
        );
        height: 58px;
        color: #000000;
        padding: 0 0 0 72px;
        font-size: 16px;
        overflow: hidden;
        top: 71px;
        position: absolute;
        @include breakpointMax(1415) {
          top: 69px;
        }
        @include breakpointMax(1360) {
          height: 56px;
          top: 66px;
        }
        @include breakpointMax(1320) {
          top: 66px;
          width: 76%;
          height: 55px;
        }
        @include breakpointMax(1310) {
          top: 65px;
          height: 52px;
          font-size: 15px;
        }
        @include breakpointMax(1300) {
          height: 54px;
          font-size: 15px;
          top: 64px;
        }
        @include breakpointMax(1280) {
          top: 63px;
          height: 51px;
        }
        @include breakpointMax(1250) {
          top: 62px;
        }
        @include breakpointMax(1230) {
          height: 49px;
        }
        @include breakpointMax(1200) {
          height: 49px;
          top: 58px;
          padding: 0 0 0 60px;
        }
        @include breakpointMax(1180) {
          height: 47px;
        }
        @include breakpointMax(1150) {
          top: 56px;
        }
        @include breakpointMax(1100) {
          top: 52px;
          height: 46px;
        }
        @include breakpointMax(1120) {
          top: 54px;
        }
        @include breakpointMax(1080) {
          width: 80%;
          height: 46px;
        }
        @include breakpointMax(1060) {
          top: 51px;
          height: 42px;
          font-size: 14px;
        }
        @include breakpointMax(1030) {
          height: 42px;
          top: 49px;
          font-size: 14px;
          width: 80%;
        }
        @include breakpointMax(1024) {
          height: 41px;
        }
        @include breakpointMax(1023) {
          position: inherit;
          width: 100%;
          display: block;
          top: inherit;
          left: inherit;
          height: auto;
          padding: 15px;
          margin-bottom: 10px;
          font-size: 16px;
        }
        & .red-cnt-box {
          display: table;
          height: 58px;
          width: 70%;
          @include breakpointMax(1360) {
            height: 56px;
          }
          @include breakpointMax(1320) {
            height: 55px;
          }
          @include breakpointMax(1310) {
            height: 52px;
          }
          @include breakpointMax(1280) {
            height: 51px;
          }
          @include breakpointMax(1230) {
            height: 49px;
          }
          @include breakpointMax(1200) {
            height: 49px;
            width: 78%;
          }
          @include breakpointMax(1180) {
            height: 47px;
          }
          @include breakpointMax(1100) {
            height: 46px;
          }
          @include breakpointMax(1080) {
            height: 46px;
          }
          @include breakpointMax(1060) {
            height: 42px;
          }
          @include breakpointMax(1030) {
            height: 42px;
            width: 90%;
          }
          @include breakpointMax(1024) {
            height: 41px;
          }
          @include breakpointMax(1023) {
            height: auto;
          }
          & .red-box {
            display: table-cell;
            vertical-align: middle;
          }
        }
      }
      /* yellow End */
      /* green start */
      & .stoplight-green-cnt {
        width: 72%;
        display: block;
        background: linear-gradient(
          90deg,
          #b4eb81 0%,
          rgba(225, 225, 225, 0) 100%
        );
        height: 58px;
        color: #000000;
        padding: 0 0 0 72px;
        font-size: 16px;
        overflow: hidden;
        top: 143px;
        position: absolute;
        @include breakpointMax(1415) {
          top: 139px;
        }
        @include breakpointMax(1390) {
          top: 137px;
        }
        @include breakpointMax(1370) {
          top: 135px;
          height: 56px;
        }
        @include breakpointMax(1350) {
          top: 132px;
        }
        @include breakpointMax(1340) {
          top: 130px;
        }
        @include breakpointMax(1320) {
          width: 76%;
          top: 130px;
          height: 55px;
        }
        @include breakpointMax(1310) {
          height: 52px;
          font-size: 15px;
        }
        @include breakpointMax(1300) {
          height: 53px;
          font-size: 15px;
          top: 127px;
        }
        @include breakpointMax(1280) {
          height: 52px;
          top: 125px;
        }
        @include breakpointMax(1260) {
          height: 51px;
          top: 122px;
        }
        @include breakpointMax(1230) {
          height: 50px;
          top: 121px;
        }
        @include breakpointMax(1210) {
          top: 119px;
        }
        @include breakpointMax(1200) {
          height: 49px;
          padding: 0 0 0 60px;
          top: 117px;
        }
        @include breakpointMax(1180) {
          height: 47px;
        }
        @include breakpointMax(1160) {
          top: 113px;
        }
        @include breakpointMax(1150) {
          top: 110px;
        }
        @include breakpointMax(1100) {
          top: 106px;
          height: 45px;
        }
        @include breakpointMax(1080) {
          width: 80%;
          height: 43px;
        }
        @include breakpointMax(1060) {
          top: 103px;
          font-size: 14px;
        }
        @include breakpointMax(1045) {
          height: 42px;
        }
        @include breakpointMax(1040) {
          height: 41px;
          top: 101px;
        }
        @include breakpointMax(1030) {
          height: 42px;
          top: 100px;
          font-size: 14px;
          width: 80%;
        }
        @include breakpointMax(1024) {
          height: 40px;
          top: 99px;
        }
        @include breakpointMax(1023) {
          position: inherit;
          width: 100%;
          display: block;
          top: inherit;
          left: inherit;
          height: auto;
          padding: 15px;
          margin-bottom: 10px;
          font-size: 16px;
        }
        & .red-cnt-box {
          display: table;
          height: 58px;
          width: 70%;
          @include breakpointMax(1370) {
            height: 56px;
          }
          @include breakpointMax(1320) {
            height: 56px;
          }
          @include breakpointMax(1310) {
            height: 52px;
          }
          @include breakpointMax(1300) {
            height: 54px;
          }
          @include breakpointMax(1280) {
            height: 52px;
          }
          @include breakpointMax(1260) {
            height: 51px;
          }
          @include breakpointMax(1200) {
            height: 49px;
            width: 78%;
          }
          @include breakpointMax(1230) {
            height: 50px;
          }
          @include breakpointMax(1180) {
            height: 47px;
          }
          @include breakpointMax(1100) {
            height: 45px;
          }
          @include breakpointMax(1030) {
            height: 42px;
            width: 90%;
          }
          @include breakpointMax(1080) {
            height: 43px;
          }
          @include breakpointMax(1045) {
            height: 42px;
          }
          @include breakpointMax(1040) {
            height: 41px;
          }
          @include breakpointMax(1024) {
            height: 40px;
          }
          @include breakpointMax(1023) {
            height: auto;
          }
          & .red-box {
            display: table-cell;
            vertical-align: middle;
          }
        }
      }
      /* green End */
    }
  }
}
/*****************************Latest mls start***********************************/
.latest-mls-update {
  width: 100%;
  float: left;
  position: relative;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center bottom;
  /* background-image:url(../img/nd/latest-mls-bg.png); */
  position: relative;
  min-height: 550px;
  @include breakpointMax(1600) {
    min-height: 500px;
  }
  @include breakpointMax(1025) {
    min-height: 430px;
  }
  & .update-mls-text {
    padding-top: 80px;
    & .update-time {
      text-align: center;
      display: block;
      width: 100%;
      margin-top: 45px;
      & .large-time {
        font-family: "GothamLight";
        font-size: 108px;
        line-height: 90px;
        display: inline-block;
        @include breakpointMax(1366) {
          font-size: 90px;
        }
        @include breakpointMax(1025) {
          font-size: 70px;
          line-height: 60px;
        }
      }
      & .small-time {
        font-size: 30px;
        line-height: 36px;
        display: inline-block;
        vertical-align: bottom;
        padding-left: 10px;
      }
      & .small-text {
        font-size: 14px;
        margin-top: 20px;
        line-height: normal;
      }
    }
  }
}
/*****************************Newest Listings start***********************************/
.newest-listing-bg {
  width: 100%;
  float: left;
  background: linear-gradient(180deg, #b6ccd3 0%, rgba(220, 232, 235, 0) 100%);
  margin: -1px 0 0 0;
  padding-top: 25px;
  @include breakpointMax(630) {
    margin: -1px 0 30px 0;
  }
}
/*****************************What Can I Afford?***********************************/
.afford-cost-clc {
  width: 100%;
  float: left;
  padding: 90px 0 65px 0;
  & .cost-range-text {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
  }
  & .cost-range-box {
    margin-top: 45px;
    margin-bottom: 50px;
    @include breakpointMax(480) {
      padding: 0 15px;
    }
    @include breakpointMax(576) {
      padding: 0 30px;
      width: 100%;
    }
    & .input-range__track {
      /* background:rgba(0,173,187,0.15); */
      & .input-range__label--value {
        & .input-range__label-container {
          font-family: "GothamBold";
          font-size: 18px;
          bottom: 16px;
        }
      }
    }
    & .input-range {
      @include breakpointMax(767) {
        width: 90%;
        margin: 0px auto;
      }
      @include breakpointMax(480) {
        width: 75%;
      }
      @include breakpointMax(320) {
        width: 63%;
      }
      & .input-range__label--min {
        & .input-range__label-container {
          font-family: "GothamBold";
          font-size: 16px;
          top: 12px;
        }
      }
      & .input-range__label--max {
        & .input-range__label-container {
          font-family: "GothamBold";
          font-size: 16px;
          top: 12px;
        }
      }
    }
  }
  & .cost-range-titel {
    font-size: 14px;
  }
  & .afford-filter {
    & .afford-input {
      margin: 25px 0 30px 0;
      min-height: 55px;
      box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
      @include breakpointMax(767) {
        font-size: 13px;
        min-height: 50px;
        padding: 10px 10px;
        margin: 25px 0 25px 0;
        width: 100%;
        /* width:calc(100% - 97px) */
      }
    }
    & .afford-button {
      width: 152px;
      min-height: 55px;
      font-size: 17px;
      @include breakpointMax(767) {
        min-height: 50px;
        padding: 10px 10px;
        font-size: 13px;
        width: 120px;
      }
    }
  }
}

/*****************************Explore Neighborhoods start***********************************/
.home-neighborhood {
  width: 100%;
  display: inline-block;
  background: #ffffff;
  padding: 40px 0 60px 0;
  & .container {
    max-width: 100%;
    padding: 0 50px;
    @include breakpointMax(1025) {
      padding: 0 20px;
    }
    @include breakpointMax(350) {
      padding: 0 15px;
    }
  }
  & .neighborhood-listing {
    width: 100%;
    float: left;
    margin-top: 30px;
    @include breakpointMax(320) {
      width: 298px;
    }
    & .neighborhood-scroll {
      @include breakpointMax(630) {
        max-width: 600px;
        overflow-x: scroll;
        overflow-y: hidden;
        /* height:240px; */
        white-space: nowrap;
        margin-right: -15px;
        padding-left: 0px;
      }
      /* @include breakpointMax(576) {
				height:220px;
			} */
      @include breakpointMax(320) {
        margin-right: -7px;
      }
    }
    & .neighborhood-prop {
      width: 25%;
      float: left;
      padding: 0 0 30px 30px;
      @include breakpointMax(1280) {
        width: 33.33%;
        padding: 0 15px 40px 15px;
      }
      @include breakpointMax(1025) {
        width: 33%;
      }
      @include breakpointMax(800) {
        width: 50%;
      }
      @include breakpointMax(630) {
        width: 500px;
        float: none;
        display: inline-block;
        vertical-align: top;
      }
      @include breakpointMax(576) {
        width: 300px;
      }
      @include breakpointMax(320) {
        width: 280px;
      }
      &:first-child {
        width: 50%;
        float: left;
        padding: 0 0 30px 0px;
        @include breakpointMax(1280) {
          width: 33.33%;
          padding: 0 15px 40px 15px;
        }
        @include breakpointMax(1025) {
          width: 33%;
        }
        @include breakpointMax(800) {
          width: 50%;
        }
        @include breakpointMax(630) {
          width: 500px;
          float: none;
          display: inline-block;
          vertical-align: top;
        }
        @include breakpointMax(576) {
          width: 300px;
        }
        @include breakpointMax(320) {
          width: 280px;
        }
        & .neighborhood-card {
          & .slider-img-box {
            & .slider-img {
              min-height: 358px;
              @include breakpointMax(1280) {
                min-height: 135px;
              }
              @include breakpointMax(630) {
                min-height: 160px;
              }
              @include breakpointMax(576) {
                min-height: 135px;
              }
              & img {
                min-height: 365px;
                @include breakpointMax(1280) {
                  min-height: 135px;
                }
                @include breakpointMax(630) {
                  min-height: 160px;
                }
                @include breakpointMax(576) {
                  min-height: 135px;
                }
              }
            }
          }
        }
      }
    }
    & .neighborhood-card {
      background-color: #ffffff;
      box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
      width: 100%;
      display: inline-block;
      vertical-align: top;
      padding: 0;
      border-radius: 8px;
      & .slider-img-box {
        width: 100%;
        float: left;
        position: relative;
        & .slider-img {
          width: 100%;
          display: block;
          position: relative;
          overflow: hidden;
          min-height: 135px;
          background: #ffffff;
          border-radius: 8px 8px 0 0;
          @include breakpointMax(630) {
            min-height: 160px;
          }
          @include breakpointMax(576) {
            min-height: 135px;
          }
          & img {
            width: 100%;
            position: absolute;
            height: auto;
            margin: auto;
            top: -100%;
            right: -100%;
            bottom: -100%;
            left: -100%;
            min-height: 135px;
          }
        }
      }
      & .neighborhood-name {
        width: 100%;
        float: left;
        padding: 20px 14px 20px 14px;
        text-align: left;
        font-size: 15px;
        height: 58px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: normal;
      }
    }
  }
}
/**************Explore Neighborhoods*****************/

.home-realtor {
  width: 100%;
  float: left;
  background: #ffffff;
  padding: 40px 0 60px 0;
  & .container {
    max-width: 100%;
    padding: 0 50px;
    @include breakpointMax(1025) {
      padding: 0 20px;
    }
    @include breakpointMax(350) {
      padding: 0 15px;
    }
    & .realtor-card {
      background-color: #ffffff;
      box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
      width: 100%;
      display: inline-block;
      vertical-align: top;
      padding: 0;
      border-radius: 8px;
      margin-bottom: 16px;
      & .slider-img-box {
        width: 100%;
        float: left;
        position: relative;
        & .slider-img {
          width: 100%;
          display: block;
          position: relative;
          overflow: hidden;
          min-height: 358px;
          background: #ffffff;
          border-radius: 8px;
          @include breakpointMax(1280) {
            min-height: 135px;
          }
          @include breakpointMax(630) {
            min-height: 160px;
          }
          @include breakpointMax(576) {
            min-height: 135px;
          }
          & img {
            width: 100%;
            min-height: 365px;
            @include breakpointMax(1280) {
              min-height: 135px;
            }
            @include breakpointMax(630) {
              min-height: 160px;
            }
            @include breakpointMax(576) {
              min-height: 135px;
            }
          }
        }
      }
    }
    & .realtor-sub-text {
      display: inline-block;
      width: calc(100% - 75px);
      vertical-align: top;
      text-align: left;
      margin-bottom: 36px;
    }
    & .realtor-sub-img {
      display: inline-block;
      width: 75px;
      vertical-align: top;
      text-align: right;
      padding-top: 14px;
      margin-bottom: 36px;
      & img {
        width: 55px;
        height: auto;
      }
    }
    & .realtor-sub-btn {
      & .realtor-button {
        width: 152px;
        min-height: 55px;
        font-size: 17px;
        @include breakpointMax(767) {
          min-height: 50px;
          padding: 10px 10px;
          font-size: 13px;
          width: 120px;
        }
      }
    }
  }
}
.home-optimalb {
  width: 100%;
  float: left;
  padding: 45px 0 45px 0;
}

/****************************************************Neighborhoods Full page*******************************/
.neighborhood-full-page {
  /**************Neighborhoods top spaces start*****************/
  & .neighborhood-top-pd {
    padding-top: 110px;
    @include breakpointMax(1024) {
      padding-top: 80px;
    }
    @include breakpointMax(767) {
      padding-top: 50px;
    }
  }
  /**************Neighborhoods top spaces End*****************/

  /**************Neighborhoods comm changes start*****************/

  & .home-neighborhood {
    padding: 0px;
    margin: 90px 0 0 0;
    background: none;
    @include breakpointMax(1024) {
      margin: 40px 0 0 0;
    }
  }
  & .neighborhood-listing {
    margin-top: 0px;
    /**************Neighborhoods 1 full column start*****************/
    &:nth-child(odd) {
      & .neighborhood-prop {
        padding: 0 0 30px 30px;
        @include breakpointMax(1280) {
          padding: 0 15px 40px 15px;
        }
        &:first-child {
          float: left;
          padding: 0 0 30px 0px;
          @include breakpointMax(1280) {
            padding: 0 15px 40px 15px;
          }
        }
      }
    }
    /**************Neighborhoods 2 column start*****************/
    &:nth-child(even) {
      & .neighborhood-prop {
        padding: 0 30px 30px 0;
        @include breakpointMax(1280) {
          padding: 0 15px 40px 15px;
        }
        &:first-child {
          float: right;
          padding: 0 0 30px 30px;
          @include breakpointMax(1280) {
            padding: 0 15px 40px 15px;
            float: left;
          }
        }
        &:nth-child(2) {
          margin-left: -15px;
          padding: 0 15px 30px 15px;
          @include breakpointMax(1280) {
            margin-left: 0px;
            padding: 0 15px 40px 15px;
          }
        }
        &:nth-child(3) {
          margin-right: 0px;
          float: right;
          padding-right: 0;
          padding-left: 0px;
          @include breakpointMax(1280) {
            float: left;
            padding-right: 15px;
            padding-left: 15px;
          }
          @include breakpointMax(630) {
            float: none;
          }
        }
        &:nth-child(4) {
          margin-left: -15px;
          padding: 0 15px 30px 15px;
          @include breakpointMax(1280) {
            margin-left: 0px;
            padding: 0 15px 40px 15px;
          }
        }
        &:nth-child(5) {
          margin-right: 0px;
          float: right;
          padding-right: 0px;
          padding-left: 0px;
          @include breakpointMax(1280) {
            float: left;
            padding-right: 15px;
            padding-left: 15px;
          }
          @include breakpointMax(630) {
            float: none;
          }
        }
      }
    }
  }
  /**************Neighborhoods shortening list start*****************/
  & .all-neighborhood-list {
    width: 50%;
    display: inline-block;
    vertical-align: middle;
    @include breakpointMax(1024) {
      width: 80%;
    }
    @include breakpointMax(767) {
      width: 100%;
    }
    & h4 {
      font-size: 14px;
    }
    & ul {
      margin: 35px 0 0 0;
      padding: 0px;
      width: 100%;
      display: block;
      text-align: left;
    }
    & li {
      list-style: none;
      width: 33%;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 15px;
      text-align: center;
      @include breakpointMax(767) {
        width: 50%;
      }
      & a {
        font-size: 14px;
      }
    }
  }
  /**************Neighborhoods shortening list start*****************/
}

/**************Neighborhoods page*****************/

/*****************************home page  End**********************************************************/

/*********************pagination start*************/
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 0;
  border-radius: 4px;
  & li {
    display: inline-block;
    & a {
      position: relative;
      float: none;
      display: inline-block;
      padding: 0px 8px;
      line-height: normal;
      text-decoration: none;
      color: #9b9b9b;
      background: #fff;
      border: none;
      margin-left: -1px;
    }
    &.active,
    &:hover {
      & a {
        font-weight: bold;
      }
    }
  }
}

/*********Find office Result page  End**********/

/*****************************Find Agent Result page  End**********************************************************/

/*****************************Agent detail page Start**********************************************************/

/**********Agent detail info start**********/

.agent-detail-bg {
  width: 100%;
  display: block;
  background: #ffffff;
  padding: 50px 0 45px 0;
  margin-bottom: 40px;
  @include breakpointMax(1190) {
    padding: 35px 0 35px 0;
  }
  & .detail-cnt-view {
    padding: 0 20px;
  }
  & .container {
    padding: 0 0 0 25px;
    max-width: 100%;
    @include breakpointMax(1366) {
      padding: 0;
    }
    @include breakpointMax(768) {
      padding: 0 15px 0 15px;
    }
  }
  & .profile-agent-photo {
    border-radius: 5px;
    position: relative;
    border: 1px solid #ddd;
    background: #f7f7f7;
    overflow: hidden;
    height: 200px;
    width: 200px;
    display: inline-block;
    @include breakpointMax(1450) {
      height: 190px;
      width: 190px;
    }
    @include breakpointMax(1350) {
      height: 180px;
      width: 180px;
    }
    @include breakpointMax(1280) {
      height: 170px;
      width: 170px;
    }
    @include breakpointMax(1199) {
      height: 200px;
      width: 200px;
    }
    @include breakpointMax(991) {
      height: 180px;
      width: 180px;
    }
    @include breakpointMax(880) {
      height: 150px;
      width: 150px;
    }
    @include breakpointMax(767) {
      width: 240px;
      height: 240px;
      margin-bottom: 20px;
    }
    & img {
      border-radius: 5px;
      object-fit: cover;
      min-height: 100%;
      width: auto;
      max-width: 100%;
    }
    & .edit-photo-btn {
      color: #ffffff;
      position: absolute;
      bottom: -5px;
      right: -5px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      border-radius: 50%;
      font-size: 18px;
      cursor: pointer;
      text-align: center;
      @include breakpointMax(1199) {
        width: 25px;
        height: 25px;
        line-height: 25px;
        font-size: 15px;
      }
      @include breakpointMax(1000) {
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 13px;
      }
    }
    &.edit-photo-box {
      background: #d8d8d8;
      width: 100%;
      display: table;
      text-align: center;
      min-height: 190px;
      @include breakpointMax(1024) {
        min-height: 120px;
      }
      @include breakpointMax(767) {
        width: 200px;
      }
      & .profile-st-box {
        width: 100%;
        position: relative;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        & .profile-st-names {
          font-size: 35px;
          width: 100px;
          height: 100px;
          line-height: 100px;
          background: #f1f1f1;
          text-transform: uppercase;
          border: 1px solid #fff;
          border-radius: 100%;
          margin: 0px auto;
          @include breakpointMax(1199) {
            width: 80px;
            height: 80px;
            line-height: 80px;
            font-size: 25px;
          }
          @include breakpointMax(1024) {
            width: 60px;
            height: 60px;
            line-height: 60px;
            font-size: 20px;
          }
          @include breakpointMax(767) {
            width: 70px;
            height: 70px;
            line-height: 70px;
            font-size: 23px;
          }
        }
      }
    }
    & .profile-st-box {
      display: none;
    }
  }
  & .agent-biodata-left {
    display: block;
    width: 100%;
    & h2 {
      font-size: 26px;
      @include breakpointMax(1190) {
        font-size: 22px;
      }
      @include breakpointMax(1000) {
        font-size: 20px;
      }
      @include breakpointMax(900) {
        font-size: 18px;
      }
    }
    & h3 {
      font-size: 16px;
      margin-top: 13px;
      margin-bottom: 13px;
      color: #657388;
      @include breakpointMax(1190) {
        font-size: 15px;
      }
      @include breakpointMax(900) {
        font-size: 14px;
      }
    }
    /*************Agent content start********/
    & .agent-small-ct {
      line-height: 22px;
      font-size: 14px;
      padding-top: 10px;
      @include breakpointMax(1400) {
        font-size: 13px;
      }
    }
    /*************Agent content End********/

    /*************Specialties list start*********/
    & .agent-specialties-list {
      width: 100%;
      display: block;
      & ul {
        margin: 10px 0 0 0;
        padding: 0px;
        & li {
          list-style: none;
          display: inline-block;
          padding: 0 10px 12px 0;
          font-size: 14px;
          line-height: 20px;
          width: 40%;
          vertical-align: top;
          word-break: break-all;
          @include breakpointMax(1199) {
            width: 50%;
          }
          @include breakpointMax(991) {
            width: 100%;
            padding: 0 0px 15px 0;
          }
          &.full-specialties {
            width: 100%;
            display: block;
            padding: 0 0 12px 0;
          }
          & .agent-profile-lable {
            width: 120px;
            display: inline-block;
            vertical-align: top;
          }
          & .agent-profile-lable-ct {
            width: calc(100% - 120px);
            display: inline-block;
            vertical-align: top;
            & .material-icons {
              vertical-align: middle;
            }
          }
          & .material-icons {
            font-size: 15px;
          }
        }
      }
    }
    /****************************Specialties list end******************/
  }
  & .professional-infobox {
    width: 100%;
    float: left;
    border-radius: 5px;
    padding: 24px 30px;
    font-size: 14px;
    word-break: break-word;
    line-height: 20px;
    & a {
      font-size: 14px;
    }
    @include breakpointMax(1400) {
      font-size: 13px;
      & a {
        font-size: 13px;
      }
    }
    @include breakpointMax(1025) {
      padding: 15px 15px;
    }
    @include breakpointMax(767) {
      margin-top: 25px;
    }

    /*************Contact Agent/ tour button start********/
    & .theme-btn {
      width: 100%;
      @include breakpointMax(990) {
        font-size: 12px;
        padding: 10px;
        //min-height:40px;
      }
      @include breakpointMax(767) {
        padding: 10px 10px;
        font-size: 13px;
      }
    }
    & .theme-white-btn {
      width: 100%;
      padding: 9px 15px;
      @include breakpointMax(990) {
        font-size: 12px;
        padding: 9px 10px;
      }
      @include breakpointMax(767) {
        padding: 9px 10px;
        font-size: 13px;
      }
    }
    /*************Contact Agent/ tour button End********/
  }
  & .small-sub-title {
    font-size: 14px;
    padding-bottom: 7px;
  }
}
/**********Agent detail info End**********/

/**********Agent detail Reviews/Current Listings**********/
.agent-listing-info {
  width: 100%;
  display: block;
  padding: 0;
  margin-bottom: 30px;
  & .recent-list {
    width: 100%;
    float: left;
    background: #ffffff;
    box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
    padding: 32px 40px 32px 40px;
    border-radius: 8px;
    @include breakpointMax(1366) {
      padding: 32px 25px 32px 25px;
    }
    @include breakpointMax(1199) {
      margin-bottom: 30px;
      padding: 32px 15px 32px 15px;
    }
    @include breakpointMax(767) {
      padding: 20px 15px 20px 15px;
    }
    @include breakpointMax(576) {
      padding: 15px 15px 15px 15px;
    }
    & h3 {
      color: #657388;
      font-size: 20px;
      @include breakpointMax(767) {
        font-size: 18px;
      }
    }
  }
  & .tab-list {
    margin-top: 6px;
  }
  & .horizontal-prop-card {
    width: 100%;
  }
  & .listing-property-view {
    width: 100%;
    padding: 0 20px;
    margin-top: 10px;
  }
  /**********Agent detail Reviews/Current Listings pagination start**********/
  & .pagination-reviews {
    margin-top: 10px;
    width: 100%;
    float: left;
    text-align: right;
  }
  /**********Agent detail Reviews/Current Listings pagination end**********/
}
.agent-other-info {
  width: 100%;
  float: left;
  padding: 0;
  margin-bottom: 30px;
  & .container {
    padding: 0 50px;
    max-width: 100%;
    @include breakpointMax(1230) {
      padding: 0 38px;
    }
    @include breakpointMax(1190) {
      padding: 0 50px 0 50px;
    }
    @include breakpointMax(1000) {
      padding: 0 30px 0 30px;
    }
    @include breakpointMax(768) {
      padding: 0 20px 0 20px;
    }
    @include breakpointMax(576) {
      padding: 0 15px 0 15px;
    }
  }
  & .recent-list {
    width: 100%;
    float: left;
    background: #ffffff;
    box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
    padding: 40px;
    border-radius: 8px;
    @include breakpointMax(1366) {
      padding: 32px 25px 32px 25px;
    }
    @include breakpointMax(1199) {
      margin-bottom: 30px;
      padding: 32px 15px 32px 15px;
    }
    @include breakpointMax(767) {
      padding: 20px 15px 20px 15px;
    }
    @include breakpointMax(576) {
      padding: 15px 15px 15px 15px;
    }
    & h3 {
      color: #657388;
      font-size: 20px;
      @include breakpointMax(767) {
        font-size: 18px;
      }
    }
  }
  & .pagination-reviews {
    margin-top: 10px;
    width: 100%;
    float: left;
    text-align: right;
  }
}
/*****************************Agent detail page End**********************************************************/

/***************************************modal start*****************************************/
.modal-dialog {
  &.modal-95w {
    @include breakpointMax(1023) {
      width: 95%;
      max-width: none !important;
    }
    @include breakpointMax(767) {
      width: auto;
      max-width: 100% !important;
    }
  }
}
.modal-content {
  border-radius: 8px;
  border: none;
  padding: 30px 35px;
  font-size: 14px;
  @include breakpointMax(768) {
    padding: 20px 25px;
  }
  & .modal-header {
    border-bottom: none;
    padding: 0px;
    margin: -5px 0px 0px 0px;
    padding-right: 10px;
    font-family: "GothamBook";
    display: inline-block;
    &.header-border {
      border-bottom: 1px solid #dddddd;
      padding-bottom: 10px;
    }
    & h1 {
      font-size: 28px;
      font-family: "GothamBook";
      @include breakpointMax(1024) {
        font-size: 25px;
      }
    }
    & h2 {
      font-size: 20px;
      font-family: "GothamBook";
      @include breakpointMax(767) {
        font-size: 18px;
      }
    }
    & .modal-title {
      padding-right: 15px;
    }
  }
  & .modal-body {
    padding: 10px 0 0 0;
    font-family: "GothamBook";
    & [type="text"] {
      font-family: "GothamBook";
    }
    & .typeahead-search {
      & .form-control {
        font-family: "RobotoRegular";
        font-size: 13px;
      }
    }
    & h2 {
      font-size: 18px;
      font-family: "GothamBook";
    }
    & label {
      font-size: 13px;
      color: #5a687b;
      font-weight: normal;
    }
    & .sub-label {
      font-family: "GothamLight";
    }
    & .modal-small-title {
      font-size: 13px;
      font-family: "GothamMedium";
    }
    & .payment-cost {
      width: 100px;
      display: inline-block;
      vertical-align: middle;
      font-size: 13px;
      & .cost-input {
        padding: 5px 7px;
        min-height: 33px;
      }
      &.extra-long-cost {
        width: 180px;
      }
      & .harf-edit {
        width: calc(100% - 50px);
        display: inline-block;
        vertical-align: middle;
        &.dp-amt {
          width: calc(100% - 62px);
        }
        & .cost-input {
          border-radius: 4px 0 0px 4px;
        }
      }
      & .harf-select {
        width: 50px;
        display: inline-block;
        vertical-align: middle;
        & .type-select {
          margin-left: -2px;
          & .react-select__control {
            min-height: 33px;
            border-radius: 0 4px 4px 0;
            & .react-select__single-value {
              max-width: 100%;
            }
            & .react-select__value-container {
              padding: 0px 5px;
              height: 31px;
            }
            & .css-b8ldur-Input {
              padding: 0px;
              margin: 0px;
            }
            & .react-select__indicator {
              &:after {
                display: none;
              }
            }
          }
        }
        &.dp-per {
          width: 62px;
          position: relative;
          & .post-fix {
            position: absolute;
            right: 8px;
            top: 9px;
          }
        }
      }
    }
    & .available-cost {
      width: 100%;
      border-top: 1px solid #dddddd;
      display: block;
      padding-top: 15px;
    }
    & .browse-photo-content {
      font-size: 15px;
      width: 220px;
      display: inline-block;
      vertical-align: top;
      & .browse-photo {
        padding: 0;
        font-size: 12px;
      }
    }
    /* browse photo */
    & .browse-photo-upload {
      width: 290px;
      display: block;
      height: 190px;
      margin-top: 15px;
      &.browse-agent-photo {
        width: 190px;
        height: 190px;
        & img {
          width: 190px;
          height: 190px;
        }
      }
      &.browse-home-banner-ads {
        width: 300px;
        height: 50px;
        & img {
          width: 300px;
          height: 50px;
        }
      }
      &.browse-property-card-ads {
        width: 290px;
        height: 290px;
        & img {
          width: 290px;
          height: 290px;
        }
      }
      &.browse-photo-ads-upload {
        width: 100%;
        height: 50px;
        & img {
          width: 100%;
          height: 50px;
        }
        &.ad-300x50 {
          width: 300px;
          height: 50px;
        }
        &.ad-290x290 {
          width: 290px;
          height: 290px;
        }
        &.ad-728x90 {
          width: 350px;
          height: 44px;
        }
        &.ad-700x90 {
          width: 350px;
          height: 45px;
        }
        &.ad-921x450 {
          width: 350px;
          height: 172px;
        }
      }
      &.browse-mortgage-card-ads {
        width: 278px;
        height: 220px;
        & img {
          width: 278px;
          height: 220px;
        }
      }
      &.browse-large-banner-card-ads {
        width: 100%;
        height: 200px;
        & img {
          width: 100%;
          height: 200px;
        }
      }
    }
    & .modal-short-description {
      font-size: 14px;
      line-height: 22px;
      display: inline-block;
    }
    & .tlc-calculat-edit {
      & lable {
        color: #5a687b;
      }
      & .est-cost {
        color: #9b9b9b;
      }
    }
  }
  & .modal-footer {
    border-top: none;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    width: 100%;
    display: block;
    & .theme-btn {
      padding: 10px 15px;
      min-width: 80px;
    }
    &.custom-footer {
      display: block;
      margin-top: 10px;
      & > * {
        &.row {
          margin-left: -15px;
          margin-right: -15px;
        }
      }
    }
  }
  & .close {
    margin: 0;
    position: absolute;
    top: 13px;
    right: 18px;
    font-weight: normal;
    width: 30px;
    height: 30px;
    background: #ffffff;
    padding: 0px;
    border-radius: 100%;
    opacity: 1 !important;
    font-size: 25px;
    box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
    line-height: 30px;
  }
}
.office-send-modal {
  & .office-send-message {
    & label {
      font-family: "GothamBold";
    }
  }
}
/**********modal End**********/

/***********************************************Add Client**************************************************/
.add-client-form {
  width: 600px;
  margin: 0px auto;
  float: none;
  @include breakpointMax(650) {
    width: 100%;
    margin: 0px;
    float: left;
  }
  & .client-add-card {
    background: #fff;
    border-radius: 8px;
    padding: 20px 20px 20px 20px;
    width: 100%;
    float: left;
    box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
    & h2 {
      font-size: 20px;
      margin-bottom: 30px;
    }
  }
  & label {
    font-size: 13px;
    display: block;
    margin-bottom: 7px;
  }
  & .addclient-btn {
    margin: 0 5px;
    @include breakpointMax(480) {
      margin: 7px 8px;
    }
  }
}

.myoffers_button {
  position: fixed;
  left: 10px;
  bottom: 60px;
  cursor: pointer;
  z-index: 9999;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  & img {
    width: 80px;
  }
  @include breakpointMax(1024) {
    bottom: 10px;
    z-index: 999999;
  }
}
/**********error-tooltip Start**********/
.error-tooltip-box {
  width: 360px;
  background: #ffffff !important;
  border: 2px solid #ff0000 !important;
  color: #033258 !important;
  line-height: 22px;
  border-radius: 6px;
  opacity: 1 !important;
  position: relative;
  @include breakpointMax(767) {
    width: 280px;
  }
  &:after {
    position: absolute;
    content: "";
    bottom: 26px;
    border-top: 8px solid #ffffff !important;
    border-width: 8px 8px 0 8px;
  }
  &:before {
    border-top: 8px solid #ff0000 !important;
    border-width: 8px 8px 0 8px;
  }
  &.warning {
    border: 2px solid #ce8530 !important;
    &:before {
      border-top: 8px solid #ce8530 !important;
      border-width: 8px 8px 0 8px;
    }
  }
}
/**********error-tooltip End**********/

/****************************************tlc calculat Edit start******************************************/
.tlc-calculat-edit {
  width: 100%;
  display: block;
  background: #f8f7f6;
  border-radius: 8px;
  padding: 24px;
  min-height: 950px;
  &.new-tlc {
    min-height: 750px;
  }
  @include breakpointMax(991) {
    padding: 24px 15px;
    min-height: inherit;
  }
  &.tlc-modal {
    background: #ffffff;
    padding: 24px 0;
  }
  & h3 {
    font-size: 14px;
  }
  & .calculat-label {
    font-size: 13px;
    margin-bottom: 0px;
  }
  & label {
    font-size: 13px;
    display: inline-block;
    margin-bottom: 0px;
    color: #5a687b;
    font-weight: inherit !important;
    word-break: break-word;
    & span {
      font-size: 10px;
    }
  }
  & .est-cost {
    width: auto;
    display: inline-block;
    vertical-align: middle;
    font-size: 10px;
    color: #9b9b9b;
  }
  & .tlc-cost {
    width: auto;
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
  }
  & .final-cost {
    width: 70px;
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    &.profile-edit {
      width: 120px;
    }
    & .final-cost-input {
      padding: 5px 7px;
      text-align: right;
      min-height: 30px;
      font-size: 13px;
    }
    &.has-post-fix {
      position: relative;
      & .final-cost-input {
        padding-right: 16px;
      }
      & .post-fix {
        position: absolute;
        top: 9px;
        right: 8px;
      }
    }
    &.combined-cost-input {
      width: 100%;
      margin-left: 0px;
      & .harf-edit {
        width: calc(100% - 70px);
        display: inline-block;
        vertical-align: middle;
        & .input-with-border {
          border-radius: 4px 0 0 4px;
        }
      }
      & .harf-select {
        width: 70px;
        text-align: left;
        display: inline-block;
        vertical-align: middle;
        & .type-select {
          & .react-select__control {
            min-height: 37px;
            border-left: none;
            border-radius: 0 4px 4px 0;
          }
        }
      }
      & .harf-edit-Percent {
        width: 70px;
        text-align: left;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        & .input-with-border {
          border-radius: 0px 4px 4px 0px;
          border-left: none;
          min-height: 37px;
        }
        & .percent-symbol {
          position: absolute;
          top: 10px;
          right: 7px;
          font-size: 12px;
          width: auto;
        }
      }
    }
  }
  & .tlc-calculat-label {
    font-size: 13px;
    margin-bottom: 0px;
  }
  &.mortgage-calculat {
    & label {
      margin-bottom: 0px;
    }
    & .final-cost {
      width: 133px;
      text-align: left;
      margin-left: 0px;
      @include breakpointMax(1440) {
        width: 100px;
      }
      &.full-final-cost {
        width: 100%;
      }
      &.extra-long-cost {
        width: 170px;
      }
      & .final-cost-input {
        text-align: left;
        min-height: 33px;
      }
      & .harf-edit {
        width: calc(100% - 50px);
        display: inline-block;
        vertical-align: middle;
        &.dp-amt {
          width: calc(100% - 62px);
        }
        & .final-cost-input {
          border-radius: 4px 0 0px 4px;
        }
      }
      & .harf-select {
        width: 50px;
        display: inline-block;
        vertical-align: middle;
        & .type-select {
          margin-left: -2px;
          & .react-select__control {
            min-height: 33px;
            border-radius: 0 4px 4px 0;
            & .react-select__single-value {
              max-width: 100%;
            }
            & .react-select__value-container {
              padding: 0px 5px;
              height: 31px;
            }
            & .css-b8ldur-Input {
              padding: 0px;
              margin: 0px;
            }
            & .react-select__indicator {
              &:after {
                display: none;
              }
            }
          }
        }
        & .final-cost-input {
          border-radius: 0 4px 4px 0;
          margin-left: -2px;
          width: 100%;
        }
        &.dp-per {
          width: 62px;
          position: relative;
          & .final-cost-input {
            padding-right: 16px;
          }
          & .post-fix {
            position: absolute;
            right: 8px;
            top: 9px;
          }
        }
      }
    }
  }
  & .price-range-box {
    margin-top: 16px;
  }
  & .vertical-mid-content {
    display: table-cell;
    &.indent-left {
      padding-left: 18px;
    }
  }
}
.OpensansFont {
  & .tlc-calculat-edit {
    & .final-cost {
      & [type="text"] {
        line-height: 15px;
      }
    }
  }
}
/****************************************tlc calculat Edit End******************************************/

/*****************************************Agent box start***********************************************/
.agent-dt-box {
  box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
  width: 100%;
  float: left;
  /* height:121px; */
  min-height: 130px;
  border-radius: 8px;
  background: #ffffff;
  @include breakpointMax(1023) {
    min-height: inherit;
    text-align: left;
    padding: 10px 15px;
  }
  & .agent-dt-left {
    width: 140px;
    height: 140px;
    float: left;
    overflow: hidden;
    border-radius: 8px 0 0 8px;
    background: #f7f7f7;
    & img {
      border-radius: 8px 0 0 8px;
      min-height: 100%;
      object-fit: cover;
      @include breakpointMax(1199) {
        border-radius: 8px 0 0 8px;
      }
      @include breakpointMax(1023) {
        border-radius: 100%;
        width: 100%;
        height: 100%;
        padding: 2px;
      }
    }

    @include breakpointMax(1270) {
      width: 135px;
      height: 150px;
    }
    @include breakpointMax(1023) {
      width: 88px;
      height: 88px;
      border-radius: 100%;
      float: left;
      display: inline-block;
      border: 1px solid #dddddd;
    }
    @include breakpointMax(680) {
      width: 70px;
      height: 70px;
    }
  }
  & .agent-dt-right {
    width: calc(100% - 150px);
    float: left;
    min-height: 121px;
    padding: 10px 10px 6px 15px;
    @include breakpointMax(1270) {
      width: calc(100% - 135px);
      padding: 10px 7px 6px 7px;
    }
    @include breakpointMax(1199) {
      padding: 10px 15px 6px 15px;
    }
    @include breakpointMax(1023) {
      width: auto;
      display: block;
      overflow: hidden;
      float: none;
      min-height: inherit;
      padding: 8px 15px 0px 15px;
    }
    @include breakpointMax(350) {
      width: calc(100% - 70px);
    }
    & a {
      font-size: 11px;
    }
    & .agent-company-name {
      font-size: 11px;
      display: block;
      width: 100%;
      line-height: normal;
      @include breakpointMax(1023) {
        max-height: inherit;
        font-size: 13px;
      }
    }
    & .agent-name {
      font-size: 14px;
      display: block;
      width: 100%;
      padding-top: 5px;
      padding-bottom: 8px;
      max-height: inherit;
      /* height:27px;
			overflow: hidden;
			text-overflow:ellipsis;
			white-space:nowrap; */
      line-height: normal;
      & a {
        font-size: 14px;
      }
      @include breakpointMax(1023) {
        max-height: inherit;
      }
    }
    & .agent-phone {
      display: block;
      width: 100%;
      font-size: 10px;
      padding-bottom: 2px;
      & .number-box {
        width: auto;
        display: inline-block;
        /* @include breakpointMax(1270) {
					padding-left:3px;	
				} */
        &:first-child {
          padding-right: 5px;
        }
      }
    }
    & .agent-website {
      font-size: 11px;
      display: block;
      width: 100%;
      padding-top: 2px;
      & span {
        display: inline-block;
        vertical-align: middle;
        word-break: break-all;
        line-height: normal;
        width: calc(100% - 20px);
      }
    }
    & i {
      vertical-align: middle;
      font-size: 12px;
      padding-right: 2px;
    }
    & .message-agent {
      display: block;
      width: 100%;
      margin-top: 12px;
    }
  }
}

/*****************************************Agent box End***********************************************/

/*****************************************My account start***********************************************/
.account-fullinfo {
  & .est-payment-text {
    color: #7b8aa0;
  }
}
.commute-calculat-edit {
  & .typeahead-search {
    height: 33px;
    & .dropdown-menu {
      top: 0px;
      padding: 0px;
    }
    & .form-control {
      font-size: 13px;
      border: 1px solid #ddd;
      padding: 10px;
    }
  }
}
/*****************************************My account End***********************************************/

.custom-modal {
  &.full-modal {
    width: 90%;
    max-width: 90%;
    @include breakpointMax(768) {
      width: 97%;
      max-width: 98%;
    }
  }
  & .property-address {
    margin-top: 5px;
    & h1 {
      font-size: 22px;
    }
    & .sub-address {
      font-size: 22px;
      display: block;
      @include breakpointMax(1024) {
        font-size: 18px;
      }
      @include breakpointMax(767) {
        font-size: 16px;
      }
    }
  }
  & .property-beds {
    width: 100%;
    display: block;
    line-height: normal;
    font-size: 14px;
    padding-top: 4px;
    & span {
      margin-right: 5px;
    }
  }
}

.demographic-disc {
  font-size: 10px !important;
  font-style: italic !important;
  text-align: right !important;
  float: none !important;
  vertical-align: middle !important;
  line-height: 10px !important;
  width: 100% !important;
  @include breakpointMax(590) {
    display: block !important;
    text-align: left !important;
    float: none !important;
  }
}

.nearby-list {
  width: 100%;
  float: left;
  background: #ffffff;
  box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
  padding: 30px 40px;
  border-radius: 8px;
  margin-bottom: 26px;
  @include breakpointMax(1190) {
    padding: 20px 20px;
  }
  @include breakpointMax(1024) {
    font-size: 17px;
    padding: 25px 20px;
  }
  @include breakpointMax(767) {
    font-size: 15px;
    padding: 20px 20px;
  }
  & .header-name {
    color: #657388;
    font-size: 20px;
    @include breakpointMax(1024) {
      font-size: 17px;
    }
    @include breakpointMax(767) {
      font-size: 15px;
    }
  }
  & .nearby-prop-cards {
    margin-top: 30px;
    text-align: left;
    width: 100%;
    @include breakpointMax(350) {
      & .prop-card {
        width: 100%;
      }
    }
  }
}

.pd-loader-wrapper {
  display: block;
  width: 375px;
  margin: 155px auto;
  @include breakpointMax(400) {
    width: 315px;
  }
  & .pd-loader-icon {
    display: inline-block;
    vertical-align: top;
    margin-right: 30px;
    @include breakpointMax(400) {
      margin-right: 20px;
    }
    & .loader {
      width: 80px !important;
      height: 80px !important;
      @include breakpointMax(400) {
        width: 70px !important;
        height: 70px !important;
      }
    }
    & .fa {
      font-size: 106px;
      @include breakpointMax(400) {
        font-size: 84px;
      }
    }
  }
  & .pd-loader-text {
    display: inline-block;
    vertical-align: top;
    & .pd-loader-text-t1 {
      font-size: 35px;
      margin-bottom: 16px;
      @include breakpointMax(400) {
        margin-bottom: 10px;
      }
    }
    & .pd-loader-text-t2 {
      font-size: 26px;
      @include breakpointMax(400) {
        font-size: 22px;
      }
    }
  }
}
.submit-meg-box {
  width: 100%;
  line-height: normal;
  margin-bottom: 15px;
  display: block;
  padding: 5px 5px;
  border-radius: 3px;
  text-align: center;
  font-size: 14px;
}

.commute-profile-box {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 13px;
  & .commute-profile-box-label {
    margin: -18px 0 0 -5px;
    font-size: 13px;
    padding: 0 0 0 2px;
    & span {
      background: #fff;
      padding: 0 3px 0 3px;
    }
  }
  & .commute-profile-box-content {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 4px;
    & .commute-name-box {
      width: 208px;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      vertical-align: middle;
    }
    & .commute-name-select {
      display: inline-block;
      margin-left: 5px;
      padding: 2px 8px 1px 8px;
      cursor: pointer;
      color: #fff;
      font-size: 12px;
      border-radius: 4px;
      vertical-align: middle;
    }
  }
  & .commute-update-profile-box {
    font-size: 13px;
    line-height: 21px;
    margin-top: 8px;
    border-top: 1px solid #ddd;
    padding: 8px 0 5px 0;
    & .commute-update-profile-btn {
      padding: 2px 8px 1px 8px;
      cursor: pointer;
      font-size: 12px;
      vertical-align: middle;
      margin-left: 12px;
      border-radius: 4px;
    }
  }
}
/************************************************************************************/

.notification {
  border: none;
  border-top: none !important;
  font-size: 15px !important;
  line-height: 22px !important;
  transform: rotate(0deg) !important;
  will-change: auto !important;
  height: auto !important;
  padding: 20px 47px 20px 20px !important;
  border-radius: 4px !important;
  color: #000000 !important;
  box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07) !important;
  background-color: #ffffff !important;

  & .notification-dismiss {
    margin: 5px 0 0 0 !important;
    font-weight: normal !important;
    width: 30px !important;
    height: 30px !important;
    background: #ffffff !important;
    padding: 0px !important;
    border-radius: 100% !important;
    opacity: 1 !important;
    font-size: 25px !important;
    box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
    line-height: 30px !important;
  }
  & .notification_btn {
    margin: 25px 0 6px 0;
    width: calc(100% + 27px);
  }
  & .white-fill-color-button {
    /* color: #00ADBB;
    	background: #ffffff; */
    min-height: 30px;
    padding: 5px 15px;
    display: inline-block;
    border-radius: 4px;
    border: 1px solid;
    min-width: 110px;
    font-weight: normal;
    margin: 0 8px 0 0px;
    font-size: 15px;
    /* &:hover, &:focus {
			background: #00939f;
			color: #ffffff;
			border:1px solid #00939f;
		} */
  }
  & .fill-color-button {
    /* color: #ffffff;
    	background: #00ADBB; */
    min-height: 30px;
    padding: 5px 15px;
    display: inline-block;
    border-radius: 4px;
    border: 1px solid;
    min-width: 110px;
    font-weight: normal;
    margin: 0 0 0 8px;
    font-size: 15px;
    /* &:hover, &:focus {
			background: #00939f;
			color: #ffffff;
		} */
  }
}
/**********************************print piece****************************/
.print-piece-view {
  width: 100%;
  padding: 0 20px;
  margin-top: 50px;
}
.filter-search-header {
  font-size: 14px;
  background: transparent;
  margin: 0;
  padding: 0;
  @include breakpointMax(990) {
    font-size: 17px;
  }
  & .tag {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    padding-right: 10px;
    cursor: pointer;
    font-size: 14px;
    position: relative;
    background: transparent;
    margin-top: 3px;
    margin-bottom: 3px;
    &:after {
      position: absolute;
      content: "|";
      top: 0px;
      right: 0px;
      font-size: 14px;
    }
    &:hover {
      &:after {
        content: "";
      }
    }
    &:last-child {
      &:after {
        content: "";
      }
      &:hover {
        &:after {
          content: "";
        }
      }
    }
    & .tag-copy {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: transform 1s;
      &:after {
        position: absolute;
        content: "";
        top: 0px;
        right: 0px;
        font-size: 14px;
      }
      &:hover {
        opacity: 1;
        background: #ffffff;
        border: 1px solid #dddddd;
        border-radius: 4px;
        padding: 6px 24px 6px 8px;
        transform: scale(1.15);
        top: -7px;
        left: inherit;
        right: -24px;
        z-index: 9;
        &:after {
          content: "X";
          top: 6px;
          right: 6px;
        }
      }
      &:last-child {
        &:hover {
          &:after {
            content: "X";
          }
        }
      }
    }
    &.tagdisabled {
      cursor: inherit;
      &:hover {
        &:after {
          content: "|";
        }
      }
      &:last-child {
        &:hover {
          &:after {
            content: "";
          }
        }
      }
      & .tag-copy {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: transform 1s;
        &:hover {
          opacity: 0;
          &:after {
            content: "";
          }
        }
        &:last-child {
          &:hover {
            &:after {
              content: "";
            }
          }
        }
      }
      &:after {
        display: none;
      }
      & .tag-label {
        display: inline-block;
        position: relative;
        vertical-align: bottom;
        &:after {
          position: absolute;
          content: "|";
          bottom: 0px;
          right: -8px;
          font-size: 14px;
        }
      }
      &:last-child {
        & .tag-label {
          &:after {
            content: "";
          }
        }
      }
      &.tag {
        padding-right: 8px;
      }
    }
    & .tag-label {
      font-weight: normal;
    }
  }
}
.tour_button {
  display: none;
}
.small-banner {
  width: 100%;
  display: block;
  margin-bottom: 36px;
  & img {
    border-radius: 4px;
  }
}
.opt-out-msg {
  color: #ce8530 !important;
  & .material-icons {
    color: #ce8530 !important;
    margin-right: 5px;
    vertical-align: middle;
  }
  & label {
    color: #ce8530 !important;
  }
  & h3 {
    color: #ce8530 !important;
  }
}
/***************************************************************/
.tooltip_box {
  max-width: 300px;
  margin: 0px;
  word-break: break-word;
  white-space: inherit !important;
  line-height: 20px;
}

.react-bs-table {
  & table {
    & td {
      white-space: inherit !important;
      word-break: break-word;
    }
    & th {
      white-space: inherit !important;
      word-break: break-word;
    }
  }
}

.chart_tooltip {
  width: 400px !important;
  max-width: 400px !important;
  @include breakpointMax(1024) {
    left: 0px !important;
  }
  @include breakpointMax(767) {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.chart_ratebox {
  width: 100%;
  text-align: left;
  padding: 0px;
  float: left;
  color: #000;
  background: #fff;
  border: 1px solid #e7e7e8;
  & .rate_header {
    width: 100%;
    float: left;
    font-size: 11px;
    &.header_top {
      color: #fff;
      background: #4c4c4c;
      font-size: 11px;
    }
    & .header_box1 {
      width: 11%;
      float: left;
      padding: 5px 7px;
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
    }
    & .header_box2 {
      width: 45%;
      float: left;
      padding: 5px 7px;
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
    }
    & .header_box3 {
      width: 44%;
      float: left;
      padding: 5px 7px;
      border-bottom: 1px solid #ddd;
    }
    &:last-child {
      & .header_box1 {
        border-bottom: none;
      }
      & .header_box2 {
        border-bottom: none;
      }
      & .header_box3 {
        border-bottom: none;
      }
    }
  }
}
/*************************************************************************************************/
/***************************************Sitetour**********************************************************/
.sitetour {
  & .joyride-tooltip__header {
    border-bottom: none !important;
  }
  & .joyride-tooltip {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1) !important;
  }
  & .joyride-tooltip__button {
    padding: 12px !important;
    min-width: 80px !important;
    border-radius: 4px;
    &.joyride-tooltip__button--skip {
      background-color: inherit;
      color: #ccc;
      min-width: inherit !important;
      border-radius: inherit;
      padding: 12px 0px !important;
      &:hover {
        background-color: inherit;
        color: #ccc;
        min-width: inherit !important;
        border-radius: inherit;
      }
      &:focus {
        background-color: inherit;
        color: #ccc;
        min-width: inherit !important;
        border-radius: inherit;
      }
    }
    &.joyride-tooltip__button--secondary {
      background-color: inherit;
      min-width: inherit !important;
      border-radius: inherit;
      padding: 12px 0px !important;
      margin-right: 36px;
      &:hover {
        background-color: inherit;
        min-width: inherit !important;
        border-radius: inherit;
      }
      &:focus {
        background-color: inherit;
        min-width: inherit !important;
        border-radius: inherit;
      }
    }
  }
  & .joyride-tooltip__main {
    line-height: 20px;
    font-size: 14px;
    color: #9b9b9b;
    padding-bottom: 22px !important;
  }
}

.tlc-fund-error-icon {
  font-size: 20px;
  vertical-align: middle;
  margin-left: 5px;
}
.tlc-fund-success-icon {
  font-size: 20px;
  vertical-align: middle;
  margin-left: 5px;
}
.tlc-fund-warning-icon {
  font-size: 20px;
  vertical-align: middle;
  margin-left: 5px;
}

/************************************New widget**************************************************/

.widget-card {
  margin-bottom: 25px;
  width: 100%;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.07);
  background: #ffffff;
  padding: 30px 40px;
  display: inline-block;
  @include breakpointMax(1024) {
    padding: 30px 20px;
  }
  & h2 {
    font-size: 20px;
    margin-bottom: 40px;
    @include breakpointMax(767) {
      font-size: 17px;
    }
  }
  & .tlc-content-text {
    color: #bec4cb;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 24px;
  }
  & .property-features-title {
    font-size: 14px;
    margin-bottom: 12px;
  }
  & .est-payment-text {
    color: #7b8aa0;
    font-size: 12px;
  }
  & .panel-title-box {
    padding: 17px 20px;
    border-radius: 8px;
    margin-bottom: 5px;
    font-size: 17px;
    line-height: 22px;
    cursor: pointer;
    @include breakpointMax(1200) {
      font-size: 15px;
    }
    @include breakpointMax(480) {
      font-size: 18px;
      padding: 17px 15px;
    }
    & .panel-title-left {
      width: 30%;
      display: inline-block;
      vertical-align: middle;
      @include breakpointMax(1365) {
        width: 100%;
        text-align: center;
        padding-bottom: 10px;
        & .vertical-mid-content {
          display: inline-block;
        }
      }
      @include breakpointMax(1199) {
        width: 30%;
        text-align: left;
        padding-bottom: 0px;
      }
      @include breakpointMax(810) {
        width: 100%;
        text-align: center;
        padding-bottom: 10px;
        & .vertical-mid-content {
          display: inline-block;
        }
      }
    }
    & .panel-title-right {
      width: 70%;
      display: inline-block;
      vertical-align: middle;
      @include breakpointMax(1365) {
        width: 100%;
        text-align: center !important;
      }
      @include breakpointMax(1199) {
        width: 70%;
        text-align: right !important;
      }
      @include breakpointMax(810) {
        width: 100%;
        text-align: center !important;
      }
    }
    @include breakpointMax(420) {
      & .panel-title-full {
        width: 100%;
        flex: 100%;
        max-width: 100%;
        text-align: center !important;
        padding: 5px 0;
      }
    }

    & .category-total {
      display: inline-block;
      vertical-align: middle;
      width: auto;
      min-height: 25px;
      & .category-icon {
        display: inline-block;
        vertical-align: middle;
        padding-right: 8px;
        @include breakpointMax(1480) {
          padding-right: 6px;
        }
        & img {
          width: auto;
          display: inline-block;
          vertical-align: middle;
        }
        &.water-icon {
          & img {
            width: 17px;
          }
        }
        &.home-icon {
          & img {
            width: 20px;
          }
        }
      }
      & .category-amount {
        display: inline-block;
        vertical-align: middle;
        color: #ffffff;
        font-size: 14px;
        &.category-final-amount {
          font-size: 17px;
          @include breakpointMax(1200) {
            font-size: 15px;
          }
          @include breakpointMax(480) {
            font-size: 18px;
          }
        }
      }
    }
    & .category-pluse {
      display: inline-block;
      vertical-align: middle;
      width: auto;
      color: #ffffff;
      font-size: 18px;
      padding: 0 8px;
      @include breakpointMax(1200) {
        padding: 0 5px;
      }
      & .material-icons {
        color: #ffffff;
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
      }
    }
    & .category-arrow-icon {
      display: inline-block;
      vertical-align: middle;
      width: auto;
      padding: 0 4px 0 3px;
      & .material-icons {
        color: #ffffff;
        display: inline-block;
        vertical-align: middle;
        font-size: 26px;
      }
    }
    & .status-costs {
      width: 35px;
      height: 35px;
      display: inline-block;
      vertical-align: middle;
      border-radius: 100%;
      background: #ffffff;
      text-align: center;
      line-height: 26px;
      margin-left: 10px;
      @include breakpointMax(1200) {
        width: 35px;
        height: 35px;
      }
      & .status-icon {
        font-size: 22px;
        display: inline-block;
        vertical-align: bottom;
        @include breakpointMax(1200) {
          font-size: 21px;
        }
      }
    }
  }
  & .ownership-cost-section {
    width: 100%;
    display: block;
    margin-top: 20px;

    & .highcharts-title {
      width: 100%;
      display: block;
      & tspan {
        width: 100%;
        display: block;
        &:first-child {
          display: block;
        }
      }
      & .budget-price {
        font-size: 15px;
        @include breakpointMax(1199) {
          font-size: 15px;
        }
        @include breakpointMax(900) {
          font-size: 16px;
        }
        @include breakpointMax(768) {
          font-size: 15px;
        }
      }
      & .edit-price-icon {
        cursor: pointer;
      }
      & .success-icon {
        font-size: 75px;
        @include breakpointMax(1199) {
          font-size: 65px;
        }
        @include breakpointMax(900) {
          font-size: 60px;
        }
      }
    }

    &.highcharts-border {
      & .budget-chart {
        position: relative !important;
        width: 100%;
        @include breakpointMax(767) {
          margin-top: 20px;
        }
      }
      & .budget-chart-box {
        @include breakpointMax(480) {
          padding-left: 0px;
          padding-right: 0px;
        }
      }

      & .highcharts-container {
        display: block;
        &:after {
          content: "";
          top: 47px;
          right: 80px;
          position: absolute;
          border-radius: 100%;
          width: 235px;
          height: 235px;
          @include breakpointMax(1430) {
            right: calc(50% - 118px);
          }
          @include breakpointMax(1199) {
            right: calc(50% - 118px);
            top: 47px;
            width: 235px;
            height: 235px;
          }
          @include breakpointMax(1180) {
            right: calc(50% - 118px);
          }
          @include breakpointMax(400) {
            width: 225px;
            height: 225px;
            right: calc(50% - 112px);
            top: 52px;
          }
          @include breakpointMax(369) {
            width: 200px;
            height: 200px;
            right: calc(50% - 100px);
            border-width: 2px;
            top: 64px;
          }
          /* @include breakpointMax(920) {
						width:230px;
    					height:230px;
						top:50px;
						right:calc(50% - 115px);
					}
					@include breakpointMax(899) {
						width:225px;
    					height:225px;
						top:52px;
						right:calc(50% - 113px);
					}
					@include breakpointMax(880) {
						width:220px;
    					height:220px;
						top:54px;
						right:calc(50% - 110px);
					}
					@include breakpointMax(860) {
						width:215px;
    					height:215px;
						top:57px;
						right:calc(50% - 107px);
					} */
        }
      }
    }
    & .expenses-cost-list {
      margin-top: 25px;
      width: 100%;
      display: block;
      & .expenses-cost-header {
        width: 100%;
        display: block;
        color: #ffffff;
        border-radius: 10px;
        padding: 0 15px;
        cursor: pointer;
        margin-bottom: 55px;
        @include breakpointMax(1199) {
          margin-bottom: 45px;
        }
        @include breakpointMax(767) {
          margin-bottom: 35px;
        }
        &:last-child {
          margin-bottom: 0px;
        }
        &.header-bg-blue {
          background: #1f6aa6;
        }
        &.header-bg-light-blue {
          background: #488cae;
        }
        &.header-bg-light-green {
          background: #00adbb;
        }
        & .expenses-content {
          width: 100%;
          min-height: 60px;
          display: table;
          padding: 5px 0;
          @include breakpointMax(480) {
            padding: 10px 0;
          }
          & .expenses-box {
            width: 70%;
            display: table-cell;
            vertical-align: middle;
            word-break: break-word;
            @include breakpointMax(1400) {
              width: 65%;
            }
            & .expenses-icon {
              width: auto;
              display: inline-block;
              vertical-align: middle;
              &.car-icon {
                @include breakpointMax(1365) {
                  & img {
                    width: 35px;
                  }
                }
              }
            }
            & .expenses-name {
              width: 80%;
              display: inline-block;
              vertical-align: middle;
              padding-left: 10px;
              font-size: 15px;
              @include breakpointMax(1400) {
                padding-left: 8px;
              }
              @include breakpointMax(480) {
                width: auto;
              }
            }
            @include breakpointMax(480) {
              width: 100%;
              display: block;
              text-align: center;
            }
          }
          & .expenses-count {
            display: table-cell;
            vertical-align: middle;
            width: 30%;
            text-align: right;
            word-break: break-word;
            @include breakpointMax(1400) {
              width: 35%;
            }
            & .expenses-amount {
              font-size: 15px;
              color: #ffffff;
              display: inline-block;
              vertical-align: middle;
            }
            & .expand-icon {
              font-size: 23px;
              color: #ffffff;
              display: inline-block;
              vertical-align: middle;
              padding-left: 2px;
            }
            @include breakpointMax(480) {
              width: 100%;
              display: block;
              text-align: center;
              padding-top: 10px;
            }
          }
        }
      }
    }
    & .highcharts-container {
      & .highcharts-title {
        & .cost-living {
          @include breakpointMax(369) {
            font-size: 10px !important;
          }
        }
      }
    }
  }
  & .cost-calculat-edit {
    width: 100%;
    display: block;
    background: #f8f7f6;
    border-radius: 8px;
    padding: 20px 15px;
    font-size: 13px;
    @include breakpointMax(767) {
      margin-bottom: 25px;
    }
    & h3 {
      font-size: 15px;
      vertical-align: middle;
      display: inline-block;
    }
    & .final-total-cost {
      display: inline-block;
      vertical-align: middle;
      text-align: right;
      & .cost-value {
        display: inline-block;
        vertical-align: middle;
        font-size: 15px;
      }
    }
    & .final-cost {
      width: 100%;
      display: inline-block;
      vertical-align: middle;
      font-size: 13px;
      @include breakpointMax(1280) {
        width: 100%;
      }
      & .final-cost-input {
        padding: 5px 10px;
        min-height: 32px;
        font-size: 13px;
        @include breakpointMax(420) {
          text-align: left !important;
        }
      }
      &.extra-long-cost {
        width: 100%;
        & .harf-edit {
          width: calc(100% - 62px);
          display: inline-block;
          @include breakpointMax(1366) {
            width: calc(100% - 53px);
          }
          & .final-cost-input {
            border-radius: 4px 0 0 4px;
            text-align: left;
          }
        }
        & .harf-select {
          width: 62px;
          position: relative;
          display: inline-block;
          margin-left: -1px;
          @include breakpointMax(1366) {
            width: 53px;
          }
          & .final-cost-input {
            border-radius: 0 4px 4px 0;
            text-align: left;
            width: 100%;
            @include breakpointMax(1366) {
              padding: 5px 5px 5px 5px;
            }
          }
          & .post-fix {
            position: absolute;
            right: 8px;
            top: 9px;
          }
        }
      }
      &.income-cost-input {
        width: 140px;
        display: inline-block;
        vertical-align: middle;
        @include breakpointMax(1440) {
          width: 132px;
        }
        @include breakpointMax(1400) {
          width: calc(98% - 100px);
        }
      }
      &.has-post-fix {
        position: relative;
      }
      & .post-fix {
        position: absolute;
        top: 9px;
        right: 25px;
      }
    }
    & label {
      font-size: 13px;
      margin-bottom: 0px;
      color: #5a687b;
      font-weight: inherit !important;
      word-break: break-word;
      line-height: 18px;
    }
    & .header-name-icon {
      display: table-cell;
      vertical-align: middle;
      padding-right: 5px;
      & img {
        width: auto;
      }
    }
    & .category-sub-header {
      cursor: pointer;
      display: block;
      width: 100%;
      margin-bottom: 30px;
      & .arrow-icon {
        display: inline-block;
        vertical-align: middle;
        font-size: 23px;
        padding-left: 5px;
      }
    }
    & .income-option {
      width: 100px;
      border: 1px solid #dddddd;
      border-radius: 4px;
      background: #ffffff;
      display: inline-block;
      vertical-align: middle;
      margin-right: 3px;
      @include breakpointMax(1440) {
        width: 99px;
      }
      @include breakpointMax(1400) {
        width: 100px;
      }
      & label {
        width: 50%;
        font-size: 10px;
        min-height: 30px;
        text-align: center;
        line-height: 30px;
        margin-bottom: 0px;
        cursor: pointer;
        & .beds-input-hidden {
          &:checked {
            + {
              & .beds-radio-fill {
                color: #fff;
                min-height: 32px;
              }
            }
          }
        }
        &:first-child {
          border-right: 1px solid #dddddd;
          & .option-radio-fill {
            border-radius: 4px 0px 0px 4px;
          }
        }
        &:last-child {
          & .option-radio-fill {
            border-radius: 0px 4px 4px 0px;
          }
        }
      }
    }
    & .going-location-dropdown {
      & .dropdown-menu {
        max-height: 164px !important;
        width: 250px !important;
        @include breakpointMax(767) {
          width: 100% !important;
        }
      }
    }
    @include breakpointMax(420) {
      & .mb-full-col {
        width: 100%;
        flex: 100%;
        max-width: 100%;
        &.mb-pd-tp-5 {
          padding-top: 5px;
        }
      }
    }
  }
  & .expenses-tlc-detail {
    & .card {
      box-shadow: none;
      border-radius: 0px !important;
      margin-bottom: 5px !important;
      background: #f8f7f6;
      &.mg-bt-0 {
        margin-bottom: 0;
      }
      & .collapse {
        background: #f8f7f6;
      }
    }
    & .card-header {
      background: #f8f7f6;
      border-radius: inherit !important;
      border: none;
      padding: 0px;
      margin-bottom: 1px;
      border-bottom: 1px solid #dddddd;
      & .header-name {
        border: none;
        padding: 10px 0px 10px 15px;
        background: #f8f7f6;
        width: 100%;
        text-align: left;
        position: relative;
        font-size: 15px;
        min-height: 45px;
        /* @include breakpointMax(1190) {
					padding:20px 20px;
				} */
        &:after {
          position: absolute;
          top: 16px;
          right: inherit;
          /* font-size:22px;
					font-family: 'Material Icons';
					content:'add'; */
          font-family: "FontAwesome";
          content: "\f067 ";
          left: 0px;
          font-size: 14px;
        }
        @include breakpointMax(1024) {
          font-size: 15px;
          padding: 10px 0px 10px 15px;
        }
        & .sub-header-name {
          color: #bec4cb;
          font-size: 14px;
          display: block;
          padding-top: 5px;
        }
        &:active,
        &:focus {
          border: none;
          outline: none;
        }
        &.open {
          &::after {
            display: none;
          }
          &::before {
            position: absolute;
            top: 16px;
            left: 0px;
            right: inherit;
            font-size: 22px;
            /* font-family: 'Material Icons';
						content:'minimize'; */
            font-family: "FontAwesome";
            content: "\f068";
            left: 0px;
            font-size: 14px;

            /* @include breakpointMax(1190) {
							
						}
						@include breakpointMax(1025) {
							
						}
						@include breakpointMax(767) {
							
						} */
          }
        }
      }
      & .utilies-icon {
        padding: 0 4px 0 5px;
        display: inline-block;
        vertical-align: middle;
        & img {
          width: auto;
        }
      }
      & .expenses-tlc-name {
        display: inline-block;
        vertical-align: middle;
        width: 70%;
        & .expenses-name-box {
          display: inline-block;
          vertical-align: middle;
          padding-left: 5px;
          width: auto;
        }
      }
      & .expenses-tlc-amount {
        width: 30%;
        display: inline-block;
        vertical-align: middle;
        text-align: right;
      }
    }
    &.accordion {
      & .card {
        overflow: inherit;
      }
    }
    & .card-body {
      background: #f8f7f6;
      padding: 15px 0px 0px 20px;
    }
    &.sub-detail-toggle {
      & .card {
        margin-bottom: 0px !important;
      }
      & .card-header {
        & .expenses-tlc-name {
          font-size: 14px;
        }
        & .header-name {
          min-height: inherit;
          &:after {
            top: 14px;
            font-family: "FontAwesome";
            content: "\f067 ";
            left: 0px;
            font-size: 14px;
          }
          &:before {
            top: 14px;
          }
        }
      }
      &.accordion {
        & .card {
          overflow: inherit;
        }
      }
    }
  }
  & .numeric-input-box {
    width: 100%;
    display: inline-block;
    & .form-control {
      font-size: 13px !important;
      &:focus {
        border-color: inherit;
        box-shadow: inherit;
        border: 1px solid #dddddd;
        border-radius: 4px;
      }
    }
    & b {
      background: inherit !important;
      border: none;
      border-color: inherit !important;
      border-style: inherit !important;
      top: 0 !important;
      bottom: 0 !important;
      border-left: 1px solid #ddd !important;
      cursor: pointer !important;
      width: 30px !important;
      & + {
        & b {
          border-right: 1px solid #ddd !important;
          border-left: inherit !important;
          width: 30px !important;
        }
      }
    }
  }
  & .commute-history-box {
    width: 100%;
    border: 1px solid #dddddd;
    display: block;
    padding: 20px 15px;
    background: #ffffff;
    margin-bottom: 20px;
    & label {
      font-size: 13px;
      line-height: 18px;
      &.vertical-mid-content {
        &.bold {
          font-weight: bold !important;
        }
      }
    }
    & .transportation-option {
      width: 100%;
      display: block;
      text-align: center;
      & label {
        width: 30px;
        font-size: 14px;
        border-left: 1px solid #dddddd;
        height: 30px;
        border-radius: 100%;
        text-align: center;
        line-height: 30px;
        margin-bottom: 0px;
        cursor: pointer;
        margin-left: 8px;
        display: inline-block;
        vertical-align: middle;
        & .option-input-hidden {
          &:checked {
            + {
              & .option-radio-fill {
                color: #fff;
                border-radius: 100%;
                height: 30px;
                width: 30px;
                line-height: 30px;
              }
            }
          }
        }
        & .option-radio-fill {
          width: 30px;
          height: 30px;
          line-height: 30px;
          border-radius: 100%;
          background: #c3c9c9;
          & .material-icons {
            color: #ffffff;
            font-size: 20px;
            line-height: 28px;
          }
        }
        &:first-child {
          margin-left: 0px;
        }
      }
    }
    & .expenses-tlc-detail {
      &.sub-detail-toggle {
        & .card-header {
          background: none;
          & .header-name {
            background: none;
          }
        }
        & .card {
          background: #ffffff;
          & .collapse {
            background: #ffffff;
          }
        }
        & .card-body {
          background: #ffffff;
        }
      }
    }
    @include breakpointMax(768) {
      & .commute-full-col {
        flex: 100%;
        max-width: 100%;
        width: 100%;
        &.mb-pd-tp-5 {
          padding-top: 5px;
        }
      }
    }
  }
}
.widget-with-chart {
  &.widget-chart-hide {
    display: none;
  }
}
.widget-with-detail {
  display: none;
  &.widget-detail-open {
    display: block;
    transition: transform 0.95s ease-out;
  }
}

.controls-wrapper {
  & .numeric-input-box {
    width: 100%;
    display: inline-block;
    & .form-control {
      font-size: 13px !important;
      &:focus {
        border-color: inherit;
        box-shadow: inherit;
        border: 1px solid #dddddd;
        border-radius: 4px;
      }
    }
    & b {
      background: inherit !important;
      border: none;
      border-color: inherit !important;
      border-style: inherit !important;
      top: 0 !important;
      bottom: 0 !important;
      border-left: 1px solid #ddd !important;
      cursor: pointer !important;
      width: 30px !important;
      & + {
        & b {
          border-right: 1px solid #ddd !important;
          border-left: inherit !important;
          width: 30px !important;
        }
      }
    }
  }
}

/**********************************************************************************************/

.highcharts-tooltip {
  z-index: 9999 !important;
}
.muted-text {
  display: block;
  font-size: 10px;
  color: #ababab;
}

.header-title-box {
  background-color: transparent;
  width: 100%;
  max-width: 92%;
  height: auto;
  border-radius: 8px;
  padding: 15px;
  margin: 0px auto 25px;
  &.dark {
    background-color: rgba(0, 0, 0, 0.3);
  }
  & h2 {
    padding-bottom: 0px !important;
  }
  @include breakpointMax(767) {
    max-width: 100%;
  }
}

.poi-filter-wrapper {
  position: absolute;
  right: 20px;
  top: 80px;
  z-index: 999;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;

  @include breakpointMax(768) {
    top: 90px;
  }

  & .filter-button {
    background-color: white;
    width: 44px;
    height: 44px;
    cursor: pointer;
    text-align: center;
    line-height: 27px;
    border-radius: 5px;
    font-size: 32px;
    color: #b2b2b2;
    position: relative;
    margin: 0;
    display: table;
    & .filter-icon-map {
      display: table-cell;
      vertical-align: middle;
      width: 100%;
      height: 100%;
      text-align: center;
      & img {
        width: auto;
      }
    }
    & .filter-icon-close {
      display: table-cell;
      vertical-align: middle;
      width: 100%;
      height: 100%;
      text-align: center;
    }
    & .filter-close-button {
      position: absolute;
      bottom: 2px;
      right: 2px;
      font-size: 18px;
    }
  }
  & .poi-list {
    position: absolute;
    top: -2px;
    right: -2px;
    padding: 12px 0px 12px 0;
    background: rgba(255, 255, 255, 1);
    border-radius: 5px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    max-height: 413px;
    width: 260px;
    background-clip: padding-box;
    & .poi-list-scroll {
      max-height: 326px;
      overflow-y: auto;
      width: 100%;
      display: block;
      padding: 0 0 0 14px;
    }
    & .poi-list-item {
      line-height: normal;
      font-size: 13px;
      padding: 0px;
      min-width: 170px;
      position: relative;
      margin-bottom: 15px;
      display: block;
      min-height: 19px;
      & .pois-input-name {
        margin-left: 20px;
        display: block;
        width: calc(100% - 20px);
        padding-left: 6px;
        font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
        font-weight: bold;
        font-size: 12px;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
    & .reset-point-box {
      position: sticky;
      bottom: 0px;
      width: 100%;
      margin-top: 10px;
      padding-right: 12px;
      padding-left: 12px;
      padding-top: 10px;
      padding-bottom: 12px;
      border-top: 1px solid #dddddd;
      text-align: center;
      /* & .theme-btn {
				width:100%;
			} */
    }
  }
  &.open {
    border: none;
    margin-top: 2px;
    margin-right: 2px;
  }
}
.poi-tooltip {
  @include breakpointMax(567) {
    background: #fff;
    padding: 10px;
    border-radius: 10px;
  }
}
/****************************************************Start*************************************************************/
.home-banner2-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  position: relative;
  width: 100%;
  display: block;
  min-height: 738px;
  padding-top: 166px;
  background-image: url(../img/nd/arkhomes-header-bg.jpg);
  @include breakpointMax(1440) {
    padding-top: 156px;
  }
  @include breakpointMax(1230) {
    min-height: 670px;
    padding-top: 90px;
  }
  @include breakpointMax(1024) {
    min-height: 570px;
    padding-top: 63px;
  }
  @include breakpointMax(1000) {
    padding-top: 55px;
  }
  @include breakpointMax(991) {
    padding-top: 75px;
  }
  @include breakpointMax(480) {
    min-height: 530px;
    padding-top:40px;
  }
  @include breakpointMax(400) {
    padding-top:35px;
  }
  & .header-titles-box {
    width: 87%;
    display: block;
    padding-left: 120px;
    @include breakpointMax(1440) {
      width: 85%;
    }
    @include breakpointMax(1280) {
      width: 80%;
    }
    @include breakpointMax(1025) {
      padding-left: 100px;
    }
    @include breakpointMax(1000) {
      width: 100%;
      padding-right: 15px;
    }
    @include breakpointMax(991) {
      text-align:center;
      padding-left:15px;
    }
    @include breakpointMax(767) {
      padding-left: 50px;
    }
    @include breakpointMax(480) {
      padding-left: 15px;
      padding-right: 15px;
    }
    & .banner-title {
      & span {
        display: block;
        & span {
          display: inline-block;
        }
      }
    }
  }
  & .header-search-filter {
    width: 568px;
    min-height: 290px;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    bottom: 90px;
    left: calc(50% - 284px);
    padding: 45px 15px 23px 15px;
    text-align: center;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    @include breakpointMax(1180) {
      bottom: 40px;
    }
    & .filter-mid-box {
      width: 400px;
      display: inline-block;
      margin: 0px auto;
      @include breakpointMax(767) {
        width: 95%;
      }
      & .header-multisearch {
        width: 100%;
        display: block;
        margin-bottom: 19px;
        & .typeahead-search {
          border: 1px solid #d9d9d9d9;
          width: 100%;
          display: block;
          height: 62px;
          & .form-control {
            font-size: 18px;
            padding: 10px 25px;
          }
        }
      }
      & .tlc-range-box {
        width: 100%;
        display: block;
        min-height: 68px;
        text-align: center;
        padding: 3px 5px 7px 5px;
        border: 1px solid #d9d9d9d9;
        border-radius: 5px;
        background: #ffffff;
        & .tlc-range-title {
          width: 100%;
          display: block;
          text-align: center;
          color: #888888;
          font-size: 18px;
          margin-bottom: 4px;
          line-height: normal;
          & .fa-question-circle {
            font-size: 15px;
            padding-left: 7px;
          }
        }
        & .input-range {
          width: 100%;
          margin: 0;
        }
        & .input-range__label--min {
          /* left:24px;
    					top:-19px;
						bottom:inherit; */
          display: none;
          & .input-range__label-container {
            bottom: -4px;
            font-size: 14px;
            color: #9b9b9b;
          }
        }
        & .input-range__label--max {
          /* right:0px;
    					top:-19px;
						bottom:inherit; */
          display: none;
          & .input-range__label-container {
            bottom: -4px;
            font-size: 14px;
            color: #9b9b9b;
          }
        }
        & .input-range__track {
          width: calc(100% - 50px);
          left: 25px;
        }
        & .input-range__slider-container {
          & .input-range__label--value {
            top: 7px;
            & .input-range__label-container {
              font-size: 13px;
            }
          }
        }
      }
      & .filter-find-button {
        width: 100%;
        display: block;
        margin-top: 25px;
        & .theme-btn {
          min-width:177px;
          text-transform: uppercase;
          font-size:18px;
          border-radius: 8px;
          margin:0 6px;
          @include breakpointMax(480) {
            margin:0 3px;
            font-size:17px;
            min-width:160px;
          }
          @include breakpointMax(450) {
            font-size:13px;
            min-width:140px;
          }
          @include breakpointMax(375) {
            min-width:120px;
            font-size:13px;
            padding:10px 13px;
          }
          @include breakpointMax(360) {
            min-width:120px;
            font-size:13px;
          }
        }
      }
    }
    @include breakpointMax(1000) {
      bottom: 40px;
      padding: 25px 15px 23px 15px;
      min-height: 270px;
    }
    @include breakpointMax(767) {
      width: 90%;
      left: calc(50% - 45%);
    }
  }
  & .partner-logo {
    width: auto;
    display: inline-block;
    @include breakpointMax(1000) {
      width: 100%;
      display: block;
      /* text-align: left; */
      text-align: right;
      padding-left: 100px;
      padding-right: 100px;
      padding-top: 20px;
    }
    @include breakpointMax(991) {
      text-align:center;
      padding-top:40px;
    }
    @include breakpointMax(767) {
      padding-left: 50px;
      padding-right: 50px;
    }
    @include breakpointMax(480) {
      padding-left: 15px;
      padding-right: 15px;
      text-align: center;
      
    }
    @include breakpointMax(400) {
      padding-top:25px;
    }
    & .partner-logo-box1 {
      width: auto;
      display: inline-block;
      top: 52px;
      right: 68px;
      position: absolute;
      @include breakpointMax(1180) {
        right: 58px;
      }
      @include breakpointMax(1100) {
        right: 48px;
      }
      @include breakpointMax(1000) {
        right: inherit;
        left: inherit;
        top: inherit;
        position: inherit;
        width: 120px;
        margin-right: 15px;
      }
      @include breakpointMax(991) {
        width:90px;
      }
    }
    & .partner-logo-box2 {
      width: auto;
      display: inline-block;
      top: 159px;
      right: 86px;
      position: absolute;
      @include breakpointMax(1180) {
        right: 76px;
      }
      @include breakpointMax(1180) {
        right: 66px;
      }
      @include breakpointMax(1000) {
        right: inherit;
        left: inherit;
        top: inherit;
        position: inherit;
        width: 75px;
      }
      @include breakpointMax(991) {
        width:50px;
      }
    }
  }
}
.short-menubox {
  display: none;
}
.with-submenu {
  & .desktop-menu {
    padding-top: 116px;
    & .navbar {
      top: 48px;
      & .other-style-nav {
        & .nav-link {
          font-size: 18px;
          padding-top: 23px;
          padding-bottom: 22px;
          padding-left: 25px;
          padding-right: 25px;
          @include breakpointMax(1230) {
            padding-right: 15px;
            padding-left: 15px;
          }
          @include breakpointMax(999) {
            font-size: 17px;
          }
          @include breakpointMax(991) {
            font-size: 16px;
            padding-top: 17px;
            padding-bottom: 17px;
            padding-left: 3px;
            padding-right: 3px;
          }
        }
      }
    }
    & .short-menubox {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 48px;
      padding: 10px 0;
      z-index: 1001;
      display: block;
      & .short-menu-link {
        & .navbar-brand {
          padding: 0 16px 0 0;
        }
        & .nav-link {
          font-size: 16px;
          padding: 3px 16px;
          &.active {
            font-size: 16px;
          }
        }
      }
    }
    & .navbar-toggler {
      @include breakpointMax(991) {
        top: 19px;
      }
      &.collapsed {
        @include breakpointMax(991) {
          top: 19px;
        }
      }
    }
    & .login-quicklink {
      @include breakpointMax(999) {
        min-height: 79px;
      }
    }
  }
  & .search-bar-panel {
    top: 117px;
    @include breakpointMax(999) {
      top: 116px;
    }
  }
  & .search-container {
    height: calc(100vh - 186px);
    margin-top: 70px;
    & .left-wrapper {
      height: calc(100vh - 186px);
    }
  }
  & .more-filter-panel {
    &.hide-filter-panel {
      top: 198px;
    }
    &.show-filter-panel {
      top: 186px;
      height: calc(100% - 186px);
    }
    & .more-filter-container {
      & .more-filter-content {
        max-height: calc(100% - 106px);
        @include breakpointMax(767) {
          min-height: inherit;
        }
        @media only screen and (max-device-width: 823px) and (orientation: landscape) and (min-device-aspect-ratio: 1/1) {
          min-height: inherit;
        }
      }
    }
  }
}
.search-bar-panel {
  &.extra-menu-height {
    & .more-filter-toggle {
      padding: 11px 10px 10px 10px;
    }
    & .prop-save-searh-button {
      & .theme-btn {
        padding: 11px 10px 10px 10px;
      }
    }
  }
}

.tlcinfo-tooltip-box {
  width: 300px;
  background: #ffffff !important;
  border: 2px solid #9b9b9b !important;
  color: #000000 !important;
  line-height: 22px;
  border-radius: 6px;
  opacity: 1 !important;
  position: relative;
  @include breakpointMax(767) {
    width: 280px;
    white-space: normal;
  }
  &:after {
    position: absolute;
    content: "";
    bottom: 26px;
    border-top: 8px solid #ffffff !important;
    border-width: 8px 8px 0 8px;
  }
  &:before {
    border-top: 8px solid #9b9b9b !important;
    border-width: 8px 8px 0 8px;
  }
}
.block-image-subheader {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
  width: 100%;
  display: block;
  min-height: 416px;
  padding: 0px;
  margin: 0px;
  background-image: url(../img/nd/block-image-1.jpg);
  & .section-content {
    width: calc(50% - 30px);
    float: right;
    height: 100%;
    position: relative;
    &::before {
      border-bottom: 416px solid rgba(34, 73, 115, 0.85);
      border-left: 98px solid transparent;
      border-right: 0px solid transparent;
      width: 100%;
      content: "";
      position: absolute;
    }
    & .container-box {
      width: 100%;
      min-height: 416px;
      display: table;
      padding: 0 20px 0 67px;
      position: relative;
      @include breakpointMax(576) {
        padding: 0 10px 0 67px;
      }
      & .block-contain {
        width: 100%;
        display: table-cell;
        vertical-align: middle;
        word-break: break-word;
        & .block-text {
          width: 33%;
          margin: 0px auto;
          text-align: left;
          @include breakpointMax(1440) {
            width: 62%;
          }
          @include breakpointMax(1024) {
            width: 90%;
          }
          @include breakpointMax(574) {
            width: 100%;
            text-align: center;
          }
          & .block-subtext {
            font-size: 18px;
            width: 100%;
            padding-top: 10px;
            display: block;
            line-height: 25px;
            @include breakpointMax(768) {
              font-size: 16px;
            }
            @include breakpointMax(575) {
              display: none;
            }
          }
          & .banner-title {
            @include breakpointMax(768) {
              font-size: 25px;
            }
            @include breakpointMax(576) {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  & .theme-btn {
    &.theme-transparent-btm {
      min-width: 215px;
      font-size: 19px;
      margin-top: 15px;
      border-radius: inherit;
      padding: 6px 15px;
      text-align: center;
      @include breakpointMax(680) {
        min-width: 100%;
        font-size: 16px;
      }
      @include breakpointMax(480) {
        font-size: 14px;
        padding: 10px 10px;
      }
    }
  }
  &.block-subheader-right {
    background-image: url(../img/nd/block-image-2.jpg);
    & .section-content {
      float: left;
      margin-left: 0;
      margin-right: 30px;
      width: calc(50% + 30px);
      &::before {
        border-left: 98px solid transparent;
        border-right: 0px solid transparent;
        transform: rotate(180deg);
      }
      & .container-box {
        padding: 0 67px 0 20px;
        @include breakpointMax(576) {
          padding: 0 67px 0 10px;
        }
        & .block-contain {
          & .block-text {
            width: 39%;
            @include breakpointMax(1440) {
              width: 61%;
            }
            @include breakpointMax(1024) {
              width: 90%;
            }
            @include breakpointMax(574) {
              width: 100%;
            }
          }
        }
      }
    }
  }
  & .block-subtitle {
    font-size: 18px;
    @include breakpointMax(1024) {
      font-size: 20px;
    }
    @include breakpointMax(767) {
      font-size: 20px;
    }
  }
}
.landing-page-mid {
  width: 100%;
  display: block;
  padding-top: 35px;
  padding-bottom: 70px;
  & .landing-mid-content {
    width: 100%;
    display: block;
    padding: 0 20px;
    & .landing-mid-title {
      padding-bottom: 13px;
      & .symbol-text {
        font-size: 15px;
        display: inline-block;
        vertical-align: top;
        padding-top: 11px;
        @include breakpointMax(767) {
          padding-top: 0px;
        }
      }
      & .title-italic-fontstyle {
        font-style:italic;
      }
    }
    & .landing-sub-text {
      width: 100%;
      display: block;
      text-align: center;
      font-size: 18px;
      line-height: 26px;
      @include breakpointMax(767) {
        font-size: 16px;
      }
    }
    & .landing-video-player {
      width: 1040px;
      display: inline-block;
      text-align: center;
      margin: 25px auto 55px;
      @include breakpointMax(1039) {
        width: 100%;
      }
      @include breakpointMax(1030) {
        margin: 25px auto 40px;
      }
      @include breakpointMax(768) {
        margin: 25px auto 35px;
      }
      & .video-player-box {
        width: 100%;
        /* height:520px; */
        margin-bottom: 40px;
        @include breakpointMax(1030) {
          margin-bottom: 30px;
        }
        & .react-player__preview {
          min-height: 590px;
          background-repeat: no-repeat;
          background-size: 100% !important;
          @include breakpointMax(1030) {
            min-height: 560px;
          }
          @include breakpointMax(1000) {
            min-height: 546px;
          }
          @include breakpointMax(900) {
            min-height: 493px;
          }
          @include breakpointMax(850) {
            min-height: 465px;
          }
          @include breakpointMax(800) {
            min-height: 435px;
          }
          @include breakpointMax(750) {
            min-height: 415px;
          }
          @include breakpointMax(700) {
            min-height: 390px;
          }
          @include breakpointMax(650) {
            min-height: 353px;
          }
          @include breakpointMax(600) {
            min-height: 320px;
          }
          @include breakpointMax(550) {
            min-height: 300px;
          }
          @include breakpointMax(500) {
            min-height: 275px;
          }
          @include breakpointMax(450) {
            min-height: 245px;
          }
          @include breakpointMax(400) {
            min-height: 215px;
          }
          & .react-player__shadow {
            background: #000000 !important;
            @include breakpointMax(1030) {
              border-radius: 50px;
              width: 50px !important;
              height: 50px !important;
              & .react-player__play-icon {
                border-width: 8px 0px 8px 13px !important;
                margin-left: 3px !important;
              }
            }
          }
        }
      }
      & .theme-btn {
        &.theme-transparent-btm {
          min-width: 307px;
          font-size: 25px;
          min-height: 79px;
          text-transform: uppercase;
          display: table;
          @include breakpointMax(1030) {
            min-width: inherit;
            font-size: 22px;
            min-height: inherit;
          }
          @include breakpointMax(768) {
            font-size: 20px;
          }
          @include breakpointMax(480) {
            font-size: 18px;
          }
        }
      }
    }
  }
  & .patternbg-card-content {
    width: 100%;
    display: block;
    margin: 0px;
    padding: 0px;
    text-align: left;
    & .listing-card {
      width: 100%;
      display: block;
    }
    & .pattern-section-title {
      & h1 {
        font-size: 30px;
        @include breakpointMax(1023) {
          font-size: 26px;
        }
        @include breakpointMax(767) {
          font-size: 24px;
        }
        @include breakpointMax(480) {
          font-size: 22px;
        }
      }
    }
    & .section-cards {
      width: 100%;
      text-align: center;
      margin-top: 15px;
      & .prop-card {
        background: #ffffff;
        min-height: 290px;
      }
    }
    & .more-listing {
      text-align: right;
      width: 100%;
      display: block;
      & a {
        font-size: 22px;
        @include breakpointMax(1023) {
          font-size: 20px;
        }
        @include breakpointMax(767) {
          font-size: 18px;
        }
        @include breakpointMax(480) {
          font-size: 17px;
        }
      }
    }
  }
  & .home-featured-listing {
    & .white-card-content {
      background-image: url(../img/nd/pattern-bg.jpg);
      background-repeat: repeat;
      border-radius: 0px;
    }
  }
}
.theme-btn {
  &.theme-transparent-btm {
    border-radius: 0px;
    min-width: 215px;
    font-size: 19px;
    text-transform: uppercase;
    border-radius: 5px;
  }
}
.question-card-patternbg {
  width: 100%;
  display: block;
  background-image: url(../img/nd/pattern-bg.jpg);
  background-repeat: repeat;
  margin-bottom: -120px;
  & .question-card-listing {
    width: 100%;
    display: block;
    padding: 0;
    & .card-content {
      width: 100%;
      display: block;
      padding: 25px 15px 25px 15px;
      @include breakpointMax(1440) {
        padding: 25px 28px 25px 28px;
      }
      & .question-col {
        width: 100%;
        display: block;
        & .question-box {
          width: 100%;
          display: block;
          min-height: 340px;
          background-size: cover;
          background-repeat: no-repeat;
          position: relative;

          @include breakpointMax(1024) {
            min-height: 235px;
          }
          &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            background: rgba(158, 35, 34, 0.65);
          }
          & .question-name {
            width: 100%;
            display: block;
            padding: 25px 25px 25px 25px;
            font-size: 47px;
            line-height: normal;
            position: relative;
            @include breakpointMax(1440) {
              font-size: 40px;
            }
            @include breakpointMax(1200) {
              font-size: 35px;
            }
            @include breakpointMax(1024) {
              font-size: 25px;
            }
          }
          &.question-1 {
            background-image: url(../img/nd/question-1.jpg);
          }
          &.question-2 {
            background-image: url(../img/nd/question-2.jpg);
          }
          &.question-3 {
            background-image: url(../img/nd/question-3.jpg);
          }
          /* &:hover {
						transform: scale(1.05) translate(0px, 0px);
    					transition: all 200ms ease-in-out 0s;
					} */
        }
        @include breakpointMax(767) {
          margin-bottom: 20px;
        }
      }
    }
  }
}

.homebot-wrapper {
  padding: 44px;
  width: 800px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.4);
  @include breakpointMax(1023) {
    padding: 44px;
    width: 600px;
  }
  @include breakpointMax(767) {
    padding: 44px;
    width: 500px;
  }
  @include breakpointMax(519) {
    padding: 12px;
    width: 90%;
  }
}
/****************************************************End*************************************************************/
/****************************************************Poi loading start*************************************************************/
.poi-loading-wrapper {
  position: absolute;
  height: auto;
  width: auto;
  z-index: 998;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding: 8px 8px;
  font-size: 14px;
  border-radius: 4px;
  min-width: 300px;
  & .poi-loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #555;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    display: inline-block;
    vertical-align: middle;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  & .poi-loader-name {
    width: calc(100% - 40px);
    font-size: 15px;
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
  }
}
/****************************************************Poi loading end*************************************************************/
.redirect-loading {
  position: fixed;
  height: auto;
  width: auto;
  z-index: 998;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  width: 400px;
  @include breakpointMax(400) {
    width: 315px;
  }
  & .pd-loader-icon {
    display: inline-block;
    vertical-align: top;
    margin-right: 30px;
    @include breakpointMax(400) {
      margin-right: 20px;
    }
    & .loader {
      width: 80px !important;
      height: 80px !important;
      @include breakpointMax(400) {
        width: 70px !important;
        height: 70px !important;
      }
    }
  }
  & .pd-loader-text {
    display: inline-block;
    vertical-align: top;
    & .pd-loader-text-t1 {
      font-size: 35px;
      margin-bottom: 16px;
      @include breakpointMax(400) {
        margin-bottom: 10px;
      }
    }
    & .pd-loader-text-t2 {
      font-size: 26px;
      @include breakpointMax(400) {
        font-size: 22px;
      }
    }
  }
}

.mls-form-mid {
  width: 100%;
  display: block;
  & .mls-list-option {
    width: 100%;
    display: block;
  }
  & .mls-from-input {
    width: 100%;
    display: block;
  }
  & .error {
    color: #bb0000;
    font-size: 14px;
    text-align: center;
  }
}

.outside-links {
  width: 100%;
  display: block;
  text-align: center;
  & .link-text-cnt {
    width: 100%;
    display: block;
    margin-bottom: 20px;
    font-size: 18px;
  }
  & .outside-link-btn {
    & .theme-btn {
      margin: 0 10px;
    }
  }
}
.contact-agent-disclaimer-cnt {
  width:100%;
  display:block;
  font-size:13px;
  font-style:italic;
  margin-top:15px;
  background:#F8F7F6; 
  padding:10px;
  border-radius:8px;
}

.accept-signing-cnt {
  width:100%;
  display:block;
  text-align:center;
  padding-top:15px;
  border-top:1px solid #efeaea;
  margin-top:15px !important;
}
.terms-cnt-box {
  width:100%;
  display:block;
  margin-top:70px;
  @include breakpointMax(768) {
    margin-top:30px;
  }
  & a {
    text-decoration: underline;
  }
}
.term-mlslogo-logo {
  width:100%;
  display:block;
  & img {
    width:auto;
  }
}
.widget-blur-loader {
  border:none;
  box-shadow:0 4px 7px 3px rgba(0, 0, 0, 0.07) ;
  background-color: #ffffff;
  border-radius:4px;
  padding:15px !important;
  font-size:15px;
  color: #000000 !important;
  font-weight:normal !important;
}

.popup-connect-client {
  width:100%;
  display:block;
  & .connect-client-input {
    width:100%;
    display:block;
    margin-top:13px;
  }
}