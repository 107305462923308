@import '_mixins.scss';

/*****************************Footer start***********************************/

.footer {
	width:100%;
	float:left;
	padding-top:60px;
	@include breakpointMax(767) {
		padding-top:30px;
	}
	& .footer-banner {
		text-align:center;
		& .banner-ads {
			width:728px;
			height:90px;
			display:inline-block;
			float:none;
			margin:0px auto;
			@include breakpointMax(1025) {
				display:none;
			}
		}
	}
	& .footer-bg {
		width: 100%;
		float: left;
		padding: 115px 60px 80px 60px;
		position: relative;
		margin-top: 140px;
		font-size: 12px;
		word-break: break-word;
		@include breakpointMax(1800) {
			padding:140px 40px 80px 40px;
		}
		@include breakpointMax(1400) {
			padding:120px 30px 80px 30px;
		}
		@include breakpointMax(1025) {
			margin-top:0;
			padding:30px 15px 30px 15px;
		}
		@include breakpointMax(800) {
			font-size:11px;
		}
		@include breakpointMax(576) {
			padding:20px 20px 20px 20px;
		}
		& .row {
			&.custom-row {
				width: 100%;
				display: inline-block;
				vertical-align: top;
				margin-left: 0px;
				margin-right: 0px;
				& > div {
					display: inline-block;
					vertical-align: top;
				}
			}
		}
		& .search-footer {
			display: none;
		}
		& h4 {
			font-size: 16px;
			margin-bottom: 13px;
		}
		& a {
			display:inline-block;
			font-size:12px;
			@include breakpointMax(800) {
				font-size:11px;
			}
		}
		/* &:after {
			position:absolute;
			top:-212px;
			left:calc(100% - 830px);
			background-repeat:no-repeat;
			background-size:cover;
			background-position:top right;
			background-image:url(../img/nd/footer-bg.png);
			width:830px;
			content:"";
			min-height:309px;
			@include breakpointMax(1400) {
				top:-184px;
				left:calc(100% - 700px);
				min-height: 260px;
				width:700px;
			}
			@include breakpointMax(1200) {
				top:-155px;
				left:calc(100% - 616px);
				min-height:227px;
				width:616px;
			}
			@include breakpointMax(1025) {
				display:none;
			}
		} */
		& .footer-bg-image {
			position:absolute;
			top:-212px;
			left:calc(100% - 830px);
			background-repeat:no-repeat;
			background-size:cover;
			background-position:top right;
			background-image:url(../img/nd/footer-bg.png);
			width:830px;
			content:"";
			min-height:309px;
			@include breakpointMax(1400) {
				top:-184px;
				left:calc(100% - 700px);
				min-height: 260px;
				width:700px;
			}
			@include breakpointMax(1200) {
				top:-155px;
				left:calc(100% - 616px);
				min-height:227px;
				width:616px;
			}
			@include breakpointMax(1025) {
				display:none;
			}
		}
		& .footer-logo {
			& .footer-logo-text {
				display: block;
				margin: 5px 0 8px;
			}
			& img {
				width: 100%;
				height: auto;
				max-width: 95px;
				margin-bottom: 12px;
			}
			/* @include breakpointMax(1025) {
				width: 90px;	
				height: auto;
			}*/
			@include breakpointMax(767) {
				max-width: 70px;
				margin-bottom: 20px;
				text-align: center;
				display: inline-block;
				text-align: center;
			} 
		}
		
		& .footer-sub-link {
			& .footer-nav-box {
				width: 320px;
				padding: 0 15px;
				display: inline-block;
				vertical-align: top;
			}
		}
		& .footer-nav {
			@include breakpointMax(1199) {
				margin-bottom: 20px;
			}
			& ul {
				list-style: none;
				padding: 0;
				margin: 0
			}
			& li {
				display:block;
				list-style:none;
				margin-bottom:10px;
			}
		}
		& .address-details {
			& .company_address {
				display:block;
				margin-bottom: 10px;
			}
		}		
		& .footer-parter {
			margin-top:0px;
			@include breakpointMax(1025) {
				margin-top:0;
				text-align:center;
			}
			@include breakpointMax(1270) {
				margin-top:0px;
			}
			@include breakpointMax(1025) {
				margin-top:0;
			}
			@include breakpointMax(576) {
				margin-top:20px;
			}
			& .parter-logo {
				display: block;
				margin-bottom: 15px;
				& .parter-logo-text {
					display: block;
					margin: 5px 0 8px;
				}
				& img {
					width: 100%;
					max-width: 120px;
					height: auto;
					&.mw-110 {
						max-width: 110px;
					}
					&.mw-100 {
						max-width: 100px;
					}
					&.mw-90 {
						max-width: 90px;
					}
					&.mw-80 {
						max-width: 80px;
					}
					&.mw-70 {
						max-width: 70px;
					}
					&.mw-60 {
						max-width: 60px;
					}
					&.mw-50 {
						max-width: 50px;
					}
				}
				@include breakpointMax(1410) {
					margin-left:0px;
				}
				@include breakpointMax(1270) {
					text-align:center;
				}
				@include breakpointMax(1200) {
					margin-left:0px;
					margin-bottom:15px;
					display:block;
				}
				@include breakpointMax(767) {
					display: inline-block;
					vertical-align: top;
					margin-left: 7px;
					margin-right: 7px;
					margin-bottom: 15px;
    				margin-top: 10px;
					& img {
						max-width: 87px;
					}
				}
				@include breakpointMax(576) {
					margin-bottom:10px;
				}
				@include breakpointMax(350) {
					margin-left:13px;
				}
			}
		}
		& .external-images {
			text-align: center;
			@include breakpointMax(576) {
				/* display: none; */
			}
			& .external-image {
				& img {
					width: 100%;
					max-width: 280px;
					height: auto;
					&.mw-250 {
						max-width: 250px;
					}
					&.mw-220 {
						max-width: 220px;
					}
					&.mw-200 {
						max-width: 200px;
					}
					&.mw-180 {
						max-width: 180px;
					}
					&.mw-160 {
						max-width: 160px;
					}
					&.mw-150 {
						max-width: 150px;
					}
					&.mw-130 {
						max-width: 130px;
					}
					&.mw-120 {
						max-width: 120px;
					}
					&.mw-100 {
						max-width: 100px;
					}
					&.mw-80 {
						max-width: 80px;
					}
					&.mw-60 {
						max-width: 60px;
					}
					&.mw-50 {
						max-width: 50px;
					}
				}
			}
		}
		& .footer-social {
			margin-top: 50px;
			text-align: center;
			&.footer-social-sm {
				margin-top: 24px;
			}
			&.footer-social-mt-0 {
				margin-top: 0;
			}
			@include breakpointMax(1800) {
				margin-top: 0px;
			}
			@include breakpointMax(1199) {
				text-align: center;
				margin-top: 0px;
			}
			@include breakpointMax(767) {
				margin-top:0px;
			}
			@include breakpointMax(576) {
				/* display: none; */
			}
			& ul {
				margin:0px;
				padding:0px;
			}
			& li {
				display: inline-block;
				padding: 0;
				list-style: none;
				display: inline-block;
				width: 40px;
				border-radius: 100%;
				text-align: center;
				margin-bottom: 10px;
				margin-right: 4px;
				margin-left: 4px;
				height: 40px;
				line-height: 38px;
				position: relative;
				z-index: 8;
				&:after {
					content: '';
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					position: absolute;
					border-radius: 100%;
					opacity: 0.24;
				}

				&:first-child {
					margin-left: 0px;
				}
				@include breakpointMax(1440) {
					margin:0 5px 10px 5px;
					width: 35px;
					height: 35px;
					line-height: 33px;
				}
				@include breakpointMax(1024) {
					display:inline-block;
					margin:0 3px 10px 3px;
					width: 25px;
					height: 25px;
					line-height: 26px;
				}
				@include breakpointMax(767) {
					display: inline-block;
					margin: 0 5px;
					vertical-align: middle;
					width: 35px;
					height: 35px;
					line-height: 33px;
					&:first-child {
						margin: 0 5px;
					}
				}
				&:hover {
					&:after {
						opacity: 0.50;
					}
				}
				& a {
					text-align: center;
					display: block;
					z-index: 99;
    				position: relative;
				}
			}
			& i {
				font-size: 20px;
				display: inline-block;
				vertical-align: middle;
				line-height: 39px;
				@include breakpointMax(1440) {
					font-size: 16px;
					line-height: 33px;
				}
				@include breakpointMax(1024) {
					font-size: 12px;
					line-height: 25px;
				}
				@include breakpointMax(767) {
					font-size: 16px;
					line-height: 33px;
				}
			}
			& .svg-inline--fa {
				font-size: 18px;
				float: none;
				vertical-align: middle;
				height:19px;
				@include breakpointMax(1440) {
					font-size: 16px;
					height:20px;
				}
				@include breakpointMax(1024) {
					font-size: 13px;
					height:13px;
				}
				@include breakpointMax(767) {
					height:17px;
				}				
			}
		}
		& .d-sm-none {
			& .external-images {
				@include breakpointMax(576) {
					display: none;
				}
			}
			& .footer-social {
				@include breakpointMax(576) {
					display: none;
				}
			}
		}
		& .footer-second-social {
			width: 100%;
			margin-top: 10px;
			display: inline-block;
			@include breakpointMax(576) {
				& .footer-social {
					display: block;
				}
				& .external-images {
					display: block;
				}
			}
		}
	}
	& .footer-team {
		width:100%;
		float:left;
		text-align:center;
		font-size:9px;
		padding:25px 20px;
		@include breakpointMax(1024) {
			text-align:left;
			padding:25px 20px 10px 20px;
		}
		& .team-text {
			padding-bottom:15px;
			display:block;
			& a {
				font-size:9px;
			}
		}
	} 
	
}
.footer-otherstyle {
	width:100%;
	display:block;
	margin-top: 120px;
	& .footer-patternbg {
		width:100%;
		display:block;
		background-image:url(../img/nd/footer-pattern-bg.jpg);
		background-repeat:repeat;
		& .footer-bg-color {
			width:100%;
			display:block;
			padding:25px 0px 28px 0px;
			& .footer-logo-box {
				width:100%;
				display:block;
				@include breakpointMax(767) {
					width:100%;
					display: block;
					text-align:center;
				}
				& .footer-logo {
					width:228px;
					display:inline-block;
					margin-bottom:20px;
					@include breakpointMax(1024) {
						width:100%;
					}
					@include breakpointMax(767) {
						width:228px;
					}
					@include breakpointMax(767) {
						margin-bottom:10px;
					}
				}
			}
			& .footer-left-info {
				width:100%;
				/* display:block; */
				position:absolute;
				bottom:0;
				text-align:left;
				@include breakpointMax(767) {
					text-align:center;
					position:inherit;
					bottom:inherit;
					margin-bottom:20px;
				}
				& .company-name-info {
					width:100%;
					display:block;
					font-size:17px;
				}
				& .company-address-info {
					width:100%;
					display:block;
					font-size:14px;	
					padding-top:5px;
					padding-bottom:3px;
					& .company-box {
						display:inline-block;
						vertical-align:middle;
						border-right:1px solid #fff;
						line-height:14px;
						padding-right:5px;
						padding-left:5px;
						&:last-child {
							padding-right:0px;
							border-right:none;
						}
						&:first-child {
							padding-left:0px;
						}
					}
				}
				& .footer-left-nav {
					width:100%;
					display:block;
					padding-top:9px;
					& ul {
						list-style: none;
						padding: 0;
						margin: 0;
						& li {
							display:inline-block;
							list-style:none;
							position: relative;
							line-height:14px;
							border-right:1px solid #fff;
							margin-bottom:6px;
							& a {
								font-size:16px;
								padding:0px 7px 0px 7px;
								line-height: 14px;
								@include breakpointMax(1030) {
									font-size:15px;
								}
							}
							&:first-child {
								& a {
									padding:0px 7px 0px 0px;
									display:inline-block;
								}
							}
							&:last-child {
								border-right:none;
							}
						}
					}
				}
			}
			& .footer-nav-box {
				width:100%;
				/* display:table-cell;
				vertical-align:bottom; */
				text-align:right;
				position:absolute;
				bottom:0;
				right:15px;
				@include breakpointMax(767) {
					margin-top:5px;
					display:block;
					text-align:center;
					position:inherit;
					bottom:inherit;
					right:inherit;
				}
				& .footer-nav {
					& ul {
						list-style: none;
						padding: 0;
						margin: 0;
						& li {
							display:inline-block;
							list-style:none;
							padding:0px 7px 0px 7px;
							margin:6px 0 0 0;
							@include breakpointMax(768) {
								padding:0px 5px 0px 5px;
							}
							&:first-child {
								padding:0px 7px 0px 0px;
								@include breakpointMax(768) {
									padding:0px 5px 0px 0px;
								}
							}
							&:last-child {
								padding:0px 0px 0px 7px;
								@include breakpointMax(768) {
									padding:0px 0px 0px 5px;
								}
							}
							& a {
								font-size:16px;
								display:block;
								text-transform:uppercase;
								@include breakpointMax(1030) {
									font-size:15px;
								}
							}
						}
					}
				}
			}
			& .footer-brokerage-info {
				width:100%;
				display:block;
				& .footer-brokerage-box {
					width:47%;
					display:inline-block;
					margin-right:15px;
					vertical-align:top;
					padding-left:10px;
					@include breakpointMax(900) {
						width:100%;
						margin-right:0px;
						padding-left:0px;
						text-align:center;
						&:first-child {
							margin-bottom:15px;
						}
						&:last-child {
							margin-bottom:0px;
						}
					}
					@include breakpointMax(767) {
						width:50%;
						margin-right:0px;
						padding:0 7px;
						margin-bottom:15px;
						text-align:left;
						&:last-child {
							margin-bottom:0px;
						}
					}
					@include breakpointMax(374) {
						width:100%;
						padding:0px;
						text-align:center;
					}
					&:last-child {
						margin-right:0px;
					}
					& .brokerage-cnt-box {
						width:100%;
						display:block;
						font-size:15px;
						margin-bottom:13px;
						word-break:break-word;
						@include breakpointMax(900) {
							margin-bottom:5px;
							font-size:14px;
						}
						@include breakpointMax(374) {
							margin-bottom:8px;
						}
						&.ft-17 {
							font-size:17px;
							@include breakpointMax(1030) {
								font-size:16px;
							}
						}
						&:last-child {
							margin-bottom:0px;
						}
						&.brokerage-logo-kw {
							width:138px;
							display:block;
							@include breakpointMax(900) {
								display:inline-block;
							}
							@include breakpointMax(1030) {
								width:110px;
							}
							@include breakpointMax(374) {
								display:inline-block;
								width:100px;
							}
						}
						&.brokerage-logo-llc {
							width:80px;
							display:block;
							@include breakpointMax(900) {
								display:inline-block;
							}
							@include breakpointMax(1030) {
								width:70px;
							}
							@include breakpointMax(374) {
								display:inline-block;
								width:70px;
							}
						}
					}
				}
			} 
			& .footer-mls-logos {
				width:100%;
				display:block;
				text-align:right;
				@include breakpointMax(767) {
					/* text-align:center; */
					text-align:right;
					margin-top:20px;
				}
				& .footer-mls-logobox {
					width:auto;
					display:inline-block;
					margin-right:12px;
					margin-bottom:15px;
					vertical-align:top;
					@include breakpointMax(767) {
						margin-right:14px;
						margin-bottom:17px;
					}
					@include breakpointMax(374) {
						/* width:100%;
						margin-right:0px; */
						width:auto;
						margin-right:14px;
					}
					& img {
						width:auto;
						height:41px;
						@include breakpointMax(1030) {
							height:37px;
						}
						@include breakpointMax(374) {
							height:35px;
						}
					}
					&:last-child {
						margin-right:0px;
					}
				}
			}
			& .powerby-logo {
				width:100%;
				display:block;
				text-align:right;
				margin-top:10px;
				@include breakpointMax(767) {
					margin-bottom:15px;
					/* text-align:center; */
					text-align:right;
				}
				& .powerby-text-box {
					width:100%;
					display:block;
					color:#ffffff;
					margin-bottom:10px;
					font-size:14px;
				}
				& .powerby-logo-box {
					width:100%;
					display:block;
					& img {
						width:100%;
						max-width:120px;
						height:auto;
					}
				}
			}
			& .footer-other {
				width:100%;
				display:block;
				padding:0 55px 0 55px;
				@include breakpointMax(1440) {
					padding:0 50px;
				}
				@include breakpointMax(1030) {
					padding:0 20px;
				}
			}
		}
	}
}
.column-list-view {
	& .footer-otherstyle {
		width:calc(100% + 30px);
		margin:0 -15px -20px -15px;
		& .footer-patternbg {
			& .footer-bg-color {
				@include breakpointMax(990) {
					padding:20px 20px 20px 20px;
				}
				& .footer-nav-box {
					text-align:center;
					& .footer-nav {
						& ul {
							margin-top:18px;
							& li {
								display:inline-block;
								margin:10px 0 0 0;
								& a {
									display:inline-block;
								}
							}
						}
					}
				}
				& .footer-mls-logos {
					margin-top:25px;
					/* text-align:center; */
					text-align:right;
					@include breakpointMax(990) {
						margin-top:20px;
						& .footer-mls-logobox {
							display:block;
							margin-bottom:10px;
						}
					}
				}
				& .powerby-logo {
					/* text-align:center; */
					text-align:right;
				}
				& .footer-logo-box {
					@include breakpointMax(990) {
						width:100%;
						text-align:center;
						display:block;
					}
					& .footer-logo {
						width:228px;
						margin-bottom:15px;
						@include breakpointMax(990) {
							width:200px;
						}
					}
				}
				& .footer-other {
					padding:0 20px;
				}
				& .footer-left-info {
					position:inherit;
					bottom:inherit;
					margin-bottom:15px;
				}
				& .footer-nav-box {
					position:inherit;
					bottom:inherit;
					right:inherit;
				}
				& .footer-brokerage-info {
					& .footer-brokerage-box {
						padding-left:0px;
						@include breakpointMax(990) {
							width:100%;
							display:block;
							margin-bottom:20px;
							text-align:center;
							&:last-child {
								margin-bottom:0px;
							}
						}
						& .brokerage-logo-llc {
							@include breakpointMax(990) {
								display:inline-block;
							}
						}
						& .brokerage-logo-kw {
							@include breakpointMax(990) {
								display:inline-block;
							}
						}
						& .brokerage-cnt-box {
							@include breakpointMax(990) {
								text-align:center;
								margin-bottom:8px;
							}
							
						}
					}
				}
			}
			@include breakpointMax(990) {
				& .footer-column {
					width:100%;
					flex: 0 0 100%;
					max-width:100%;
					& .footer-left-info {
						text-align:center;
					}
					& .footer-nav-box {
						text-align:center;
						margin-top:18px;
    					display: block;
					}
				}
			}
		}
		& .footer-column {
			width:100%;
			flex:100%;
			max-width:100%;
		}
	}
}
.column-list-full-view {
	& .footer-otherstyle {
		width:calc(100% + 100px);
		margin:0 -50px;
		@include breakpointMax(1366) {
			margin: 0 -20px;
			width: calc(100% + 40px);
		}
		@include breakpointMax(1280) {
			margin: 0 -5px;
			width: calc(100% + 10px);
		}
		@include breakpointMax(1199) {
			margin: 0 -15px;
   			width: calc(100% + 30px);
		}
	}
}
/*****************************Footer End***********************************/
