.desktop-menu {
  padding-top: 70px;
  /***********Menu login Profile Right*********/ }
  @media only screen and (max-width: 991px) {
    .desktop-menu {
      text-align: left;
      padding-top: 68px; } }
  @media only screen and (max-width: 991px) {
    .desktop-menu .navbar-expand-lg {
      flex-flow: row nowrap;
      justify-content: flex-start; } }
  .desktop-menu .navbar-expand-lg .navbar-nav .dropdown .dropdown-menu {
    padding: 0px;
    max-height: 300px;
    overflow: auto; }
  .desktop-menu .top-menu-logo img {
    max-height: 60px;
    width: auto;
    max-width: 270px; }
    @media only screen and (max-width: 1200px) {
      .desktop-menu .top-menu-logo img {
        /* max-height: 28px; */
        max-height: 60px; } }
    @media only screen and (max-width: 1199px) {
      .desktop-menu .top-menu-logo img {
        /* max-height: 32px; */
        max-height: 60px;
        max-width: 180px; } }
    @media only screen and (max-width: 1023px) {
      .desktop-menu .top-menu-logo img {
        /* max-height: 27px; */
        max-height: 60px;
        max-width: 155px; } }
    @media only screen and (max-width: 991px) {
      .desktop-menu .top-menu-logo img {
        /* max-height: 36px; */
        max-height: 60px;
        display: table; } }
    @media only screen and (max-width: 576px) {
      .desktop-menu .top-menu-logo img {
        /* max-width:150px; */ } }
    @media only screen and (max-width: 400px) {
      .desktop-menu .top-menu-logo img {
        /* max-width:140px; */
        /* max-height: 30px; */ } }
    @media only screen and (max-width: 320px) {
      .desktop-menu .top-menu-logo img {
        /* max-width:130px; */ } }
  .desktop-menu .purl-agent {
    margin: 0 5px 0 15px;
    display: inline-block;
    vertical-align: middle;
    padding: 0px; }
    @media only screen and (max-width: 991px) {
      .desktop-menu .purl-agent {
        margin: 0 5px 0 25px; } }
    @media only screen and (max-width: 420px) {
      .desktop-menu .purl-agent {
        margin: 0 0 0 15px; } }
    @media only screen and (max-width: 320px) {
      .desktop-menu .purl-agent {
        margin: 0 0 0 10px; } }
    .desktop-menu .purl-agent .purl-photo {
      width: 40px;
      height: 40px;
      overflow: hidden;
      background: #ffffff;
      padding: 2px;
      border-radius: 100%;
      text-align: center;
      border: 1px solid #ddd;
      display: inline-block;
      vertical-align: middle; }
      @media only screen and (max-width: 1000px) {
        .desktop-menu .purl-agent .purl-photo {
          width: 35px;
          height: 35px; } }
      @media only screen and (max-width: 991px) {
        .desktop-menu .purl-agent .purl-photo {
          width: 38px;
          height: 38px; } }
      .desktop-menu .purl-agent .purl-photo img {
        border-radius: 50%;
        object-fit: cover;
        min-height: 100%; }
    .desktop-menu .purl-agent .nav-link {
      padding: 0 0px; }
      @media only screen and (max-width: 420px) {
        .desktop-menu .purl-agent .nav-link {
          padding: 0 10px 0 0px; } }
  .desktop-menu .tour-question {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    text-align: center;
    padding: 1px;
    line-height: 15px;
    font-size: 14px; }
    @media only screen and (max-width: 1200px) {
      .desktop-menu .tour-question {
        width: 16px;
        height: 16px;
        line-height: 13px;
        font-size: 13px; } }
    @media only screen and (max-width: 991px) {
      .desktop-menu .tour-question {
        display: none; } }
  .desktop-menu .navbar {
    padding: 0px 20px;
    min-height: 68px;
    z-index: 1001; }
    @media only screen and (max-width: 991px) {
      .desktop-menu .navbar {
        /* display: inline-block; */
        min-height: 68px; } }
    .desktop-menu .navbar .navbar-nav {
      display: inline-block; }
      @media only screen and (max-width: 991px) {
        .desktop-menu .navbar .navbar-nav {
          display: flex;
          width: 100%;
          text-align: left;
          padding: 30px 50px 30px 50px;
          height: 100%;
          overflow-y: scroll;
          overflow-x: hidden; } }
      @media only screen and (max-width: 576px) {
        .desktop-menu .navbar .navbar-nav {
          padding: 30px 15px 30px 15px;
          overflow-y: scroll;
          height: 600px; } }
      .desktop-menu .navbar .navbar-nav .nav-link {
        font-size: 14px;
        padding-right: 9px;
        padding-left: 9px;
        padding-top: 26px;
        padding-bottom: 25px; }
        @media only screen and (max-width: 1230px) {
          .desktop-menu .navbar .navbar-nav .nav-link {
            padding-right: 3px;
            padding-left: 3px;
            font-size: 13px; } }
        @media only screen and (max-width: 991px) {
          .desktop-menu .navbar .navbar-nav .nav-link {
            padding-top: 17px;
            padding-bottom: 17px;
            width: 100%;
            display: block;
            border-bottom: 1px solid #ddd; } }
        .desktop-menu .navbar .navbar-nav .nav-link:hover, .desktop-menu .navbar .navbar-nav .nav-link.active, .desktop-menu .navbar .navbar-nav .nav-link:focus {
          outline: none; }
      .desktop-menu .navbar .navbar-nav .navbar-signup-button {
        padding: 8px 16px;
        /* border:1px solid; */
        font-size: 14px;
        margin: 16px 15px 16px 0; }
        @media only screen and (max-width: 1200px) {
          .desktop-menu .navbar .navbar-nav .navbar-signup-button {
            margin: 16px 8px 16px 0;
            padding: 8px 12px; } }
        @media only screen and (max-width: 991px) {
          .desktop-menu .navbar .navbar-nav .navbar-signup-button {
            margin: 0px 35px 0px 0; } }
        .desktop-menu .navbar .navbar-nav .navbar-signup-button:hover, .desktop-menu .navbar .navbar-nav .navbar-signup-button.active, .desktop-menu .navbar .navbar-nav .navbar-signup-button:focus {
          /* color:#ffffff; */
          outline: none; }
      .desktop-menu .navbar .navbar-nav .navbar-login-button {
        padding: 9px 16px;
        font-size: 14px;
        margin: 16px 0px 16px 0;
        border-radius: 4px; }
        @media only screen and (max-width: 991px) {
          .desktop-menu .navbar .navbar-nav .navbar-login-button {
            margin: 0px 0px 0px 0; } }
        .desktop-menu .navbar .navbar-nav .navbar-login-button.dropdown {
          width: inherit;
          /* margin-top: 0; */
          padding: 7px 16px 6px; }
          @media only screen and (max-width: 991px) {
            .desktop-menu .navbar .navbar-nav .navbar-login-button.dropdown {
              width: auto; } }
          .desktop-menu .navbar .navbar-nav .navbar-login-button.dropdown .dropdown-toggle.btn {
            font-size: 14px; }
          .desktop-menu .navbar .navbar-nav .navbar-login-button.dropdown .dropdown-menu {
            left: inherit;
            right: 0;
            width: 188px;
            min-width: inherit;
            top: calc(100% + 17px); }
            @media only screen and (max-width: 991px) {
              .desktop-menu .navbar .navbar-nav .navbar-login-button.dropdown .dropdown-menu {
                position: absolute;
                width: 200px;
                top: calc(100% + 4px); } }
        .desktop-menu .navbar .navbar-nav .navbar-login-button.login-dropdown-button {
          padding: 0; }
          .desktop-menu .navbar .navbar-nav .navbar-login-button.login-dropdown-button .dropdown-toggle {
            padding: 9px 10px;
            border-radius: 4px;
            line-height: normal; }
            .desktop-menu .navbar .navbar-nav .navbar-login-button.login-dropdown-button .dropdown-toggle:after {
              color: #ffffff; }
      .desktop-menu .navbar .navbar-nav .dropdown-menu .dropdown-item {
        padding: 11px 12px;
        border-bottom: 1px solid #f3f0f0;
        font-size: 13px;
        position: relative; }
        .desktop-menu .navbar .navbar-nav .dropdown-menu .dropdown-item .chat-not-cnt.inline {
          right: 13px;
          top: 4px; }
        .desktop-menu .navbar .navbar-nav .dropdown-menu .dropdown-item .chat-not-cnt.scinline {
          right: -12px;
          top: -3px; }
        .desktop-menu .navbar .navbar-nav .dropdown-menu .dropdown-item .chat-not-cnt.mainline {
          right: -12px;
          top: -3px; }
        .desktop-menu .navbar .navbar-nav .dropdown-menu .dropdown-item:last-child {
          border-bottom: none; }
        .desktop-menu .navbar .navbar-nav .dropdown-menu .dropdown-item.active, .desktop-menu .navbar .navbar-nav .dropdown-menu .dropdown-item:hover {
          background-color: #f5f5f5; }
        .desktop-menu .navbar .navbar-nav .dropdown-menu .dropdown-item.add-client {
          border-bottom: 1px solid #f3f0f0;
          background-color: #f5f5f5;
          font-weight: bold; }
          .desktop-menu .navbar .navbar-nav .dropdown-menu .dropdown-item.add-client .add-client-icon {
            display: inline-block;
            vertical-align: top;
            width: 20px; }
          .desktop-menu .navbar .navbar-nav .dropdown-menu .dropdown-item.add-client .add-client-name {
            width: calc(100% - 20px);
            display: inline-block;
            vertical-align: top;
            white-space: normal;
            word-break: break-word;
            padding-left: 5px; }
        .desktop-menu .navbar .navbar-nav .dropdown-menu .dropdown-item.selected-client {
          border-bottom: 1px solid #f3f0f0;
          background-color: #f5f5f5;
          font-weight: bold; }
          .desktop-menu .navbar .navbar-nav .dropdown-menu .dropdown-item.selected-client .close-icon {
            float: right;
            margin-top: -2px;
            padding-left: 10px; }
      .desktop-menu .navbar .navbar-nav .dropdown-menu .selected-client-wrapper {
        background-color: #f5f5f5; }
        .desktop-menu .navbar .navbar-nav .dropdown-menu .selected-client-wrapper .selected-client {
          display: inline-block;
          vertical-align: middle;
          width: calc(100% - 36px); }
        .desktop-menu .navbar .navbar-nav .dropdown-menu .selected-client-wrapper .close-icon {
          display: inline-block;
          vertical-align: middle;
          padding: 0 0 6px 4px;
          cursor: pointer; }
      @media only screen and (max-width: 991px) {
        .desktop-menu .navbar .navbar-nav .neighborhoods-list .dropdown-menu {
          position: absolute;
          min-width: 250px;
          height: 200px;
          overflow-x: auto; } }
    .desktop-menu .navbar .nav-links {
      padding: 0; }
      @media only screen and (max-width: 991px) {
        .desktop-menu .navbar .nav-links {
          width: 100%; } }
    .desktop-menu .navbar .other-style-nav .nav-link {
      font-size: 18px;
      padding-top: 23px;
      padding-bottom: 22px;
      padding-left: 25px;
      padding-right: 25px; }
      @media only screen and (max-width: 1230px) {
        .desktop-menu .navbar .other-style-nav .nav-link {
          padding-right: 15px;
          padding-left: 15px; } }
      @media only screen and (max-width: 999px) {
        .desktop-menu .navbar .other-style-nav .nav-link {
          font-size: 17px; } }
      @media only screen and (max-width: 991px) {
        .desktop-menu .navbar .other-style-nav .nav-link {
          font-size: 16px;
          padding-top: 17px;
          padding-bottom: 17px;
          padding-left: 3px;
          padding-right: 3px; } }
  @media only screen and (max-width: 991px) {
    .desktop-menu .navbar-toggler {
      float: none;
      border: none;
      outline: none;
      padding-left: 0;
      padding-right: 0;
      position: absolute;
      top: 19px;
      padding: 0px;
      right: 15px; }
      .desktop-menu .navbar-toggler.collapsed .navbar-toggler-icon {
        /* background-image:url(../img/nd/mobile-burger.png); */
        background-image: none; }
        .desktop-menu .navbar-toggler.collapsed .navbar-toggler-icon::after {
          position: absolute;
          top: 6px;
          left: 3px;
          width: 24px;
          content: '';
          border-bottom: 2px solid;
          padding-bottom: 6px;
          border-top: 2px solid;
          transform: none;
          background: none; }
        .desktop-menu .navbar-toggler.collapsed .navbar-toggler-icon::before {
          position: absolute;
          bottom: 0px;
          left: 3px;
          width: 24px;
          content: '';
          padding-top: 6px;
          border-top: 2px solid;
          transform: none;
          background: none;
          height: auto;
          top: inherit; }
      .desktop-menu .navbar-toggler .navbar-toggler-icon {
        background-image: url(../img/nd/close.png);
        z-index: 1;
        position: relative;
        width: 30px;
        height: 30px; }
        .desktop-menu .navbar-toggler .navbar-toggler-icon::after {
          position: absolute;
          top: 15px;
          left: 1px;
          width: 30px;
          content: '';
          transform: rotate(45deg);
          height: 2px; }
        .desktop-menu .navbar-toggler .navbar-toggler-icon::before {
          position: absolute;
          top: 15px;
          left: 1px;
          width: 30px;
          content: '';
          transform: rotate(-45deg);
          height: 2px; } }
  @media only screen and (max-width: 991px) {
    .desktop-menu .navbar-collapse {
      text-align: left;
      width: 0;
      margin: 0;
      background: #fff;
      position: fixed;
      top: 0;
      left: -100%;
      transition: 0.5s;
      height: 100vh; }
      .desktop-menu .navbar-collapse.show {
        margin: 0;
        width: 100%;
        height: 100vh;
        left: 0;
        top: 0;
        transition: all .1s ease-out 0s; } }
  .desktop-menu .navbar-brand {
    /* padding-top:7px;
		padding-bottom:7px; */
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 0px;
    display: table-cell;
    vertical-align: middle; }
    @media only screen and (max-width: 768px) {
      .desktop-menu .navbar-brand {
        padding-top: 0px;
        padding-bottom: 0px; } }
  .desktop-menu .nav-item {
    display: inline-block; }
    .desktop-menu .nav-item.dropdown {
      width: inherit;
      border: none; }
  .desktop-menu .nav-link {
    display: inline-block; }
  .desktop-menu .bg-light {
    background-color: #ffffff !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    /* & .center-logo {
			@include breakpointMax(991) {
				display:table-cell;
				height:68px;
				vertical-align:middle;
			}
		} */ }
  .desktop-menu .mb-nav-header {
    display: none; }
    @media only screen and (max-width: 991px) {
      .desktop-menu .mb-nav-header {
        display: inline-block;
        padding: 0px;
        position: absolute;
        top: 26px;
        right: 60px; } }
    @media only screen and (max-width: 420px) {
      .desktop-menu .mb-nav-header {
        right: 50px; } }
    @media only screen and (max-width: 991px) {
      .desktop-menu .mb-nav-header .nav-link {
        padding: 0 10px;
        vertical-align: top; } }
    .desktop-menu .mb-nav-header .chat-button {
      background: none;
      padding: 0px;
      margin: 5px 20px 0 0;
      display: inline-block;
      vertical-align: middle;
      position: relative; }
      @media only screen and (max-width: 991px) {
        .desktop-menu .mb-nav-header .chat-button {
          margin: -4px 8px 0 8px; } }
      @media only screen and (max-width: 420px) {
        .desktop-menu .mb-nav-header .chat-button {
          margin: -4px 10px 0 6px; } }
  .desktop-menu .desktop-chat {
    background: none;
    padding: 0px;
    margin: 5px 20px 0 0;
    display: inline-block;
    vertical-align: middle;
    position: relative; }
    @media only screen and (max-width: 991px) {
      .desktop-menu .desktop-chat {
        display: none; } }
  .desktop-menu .login-quicklink {
    padding-left: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    display: inherit;
    float: right;
    min-height: 69px;
    margin: 0 0 0 10px;
    /************ Profile ovel**********/ }
    @media only screen and (max-width: 1200px) {
      .desktop-menu .login-quicklink {
        padding-left: 6px;
        margin: 0 0 0 6px; } }
    @media only screen and (max-width: 991px) {
      .desktop-menu .login-quicklink {
        padding-left: 0px;
        text-align: center;
        float: none;
        width: 100%;
        display: block;
        min-height: 60px;
        border-bottom: 1px solid #ddd;
        padding-bottom: 20px;
        margin: 0 0 0 0; } }
    .desktop-menu .login-quicklink.single-boder-lt {
      border-left: 1px solid #ddd; }
      @media only screen and (max-width: 991px) {
        .desktop-menu .login-quicklink.single-boder-lt {
          border-left: none; } }
    .desktop-menu .login-quicklink .btn-group {
      margin-bottom: 0px; }
    .desktop-menu .login-quicklink .btn-primary {
      background-color: inherit;
      border-color: inherit;
      padding: 0px;
      box-shadow: inherit; }
      .desktop-menu .login-quicklink .btn-primary:active, .desktop-menu .login-quicklink .btn-primary:focus, .desktop-menu .login-quicklink .btn-primary.dropdown-toggle {
        background-color: inherit !important;
        border-color: inherit !important;
        box-shadow: inherit !important;
        border: none;
        flex: inherit; }
      .desktop-menu .login-quicklink .btn-primary.show .dropdown-toggle {
        background-color: inherit !important;
        border-color: inherit !important;
        box-shadow: inherit !important; }
    .desktop-menu .login-quicklink .dropdown-toggle:after {
      font-size: 15px;
      color: #9B9B9B; }
    .desktop-menu .login-quicklink .profile-image {
      padding: 15px 0px 15px 0px;
      /************Add a client**********/
      /************Add a client**********/ }
      @media only screen and (max-width: 1200px) {
        .desktop-menu .login-quicklink .profile-image {
          padding: 18px 0px 17px 0px; } }
      @media only screen and (max-width: 991px) {
        .desktop-menu .login-quicklink .profile-image {
          padding-bottom: 5px;
          padding-top: 0px; } }
      .desktop-menu .login-quicklink .profile-image.dropdown {
        border: none;
        width: inherit; }
        @media only screen and (max-width: 991px) {
          .desktop-menu .login-quicklink .profile-image.dropdown {
            width: auto; } }
      .desktop-menu .login-quicklink .profile-image .profile-oval {
        display: inline-block;
        vertical-align: middle;
        border: 1px solid #D8D8D8;
        padding: 2px;
        height: 38px;
        width: 38px;
        border-radius: 50%;
        text-align: center;
        font-size: 13px;
        overflow: hidden; }
        @media only screen and (max-width: 1200px) {
          .desktop-menu .login-quicklink .profile-image .profile-oval {
            height: 33px;
            width: 33px; } }
        @media only screen and (max-width: 991px) {
          .desktop-menu .login-quicklink .profile-image .profile-oval {
            height: 38px;
            width: 38px; } }
        .desktop-menu .login-quicklink .profile-image .profile-oval img {
          border-radius: 50%;
          width: 100%;
          /* height:33px; */
          display: inline-block;
          vertical-align: top; }
        .desktop-menu .login-quicklink .profile-image .profile-oval.profile-name {
          background: #D8D8D8;
          line-height: 33px; }
          @media only screen and (max-width: 1200px) {
            .desktop-menu .login-quicklink .profile-image .profile-oval.profile-name {
              line-height: 28px; } }
          @media only screen and (max-width: 991px) {
            .desktop-menu .login-quicklink .profile-image .profile-oval.profile-name {
              line-height: 33px; } }
      .desktop-menu .login-quicklink .profile-image .dropdown-menu {
        left: inherit;
        right: 0;
        width: 250px;
        min-width: inherit;
        padding: 0px; }
        @media only screen and (max-width: 991px) {
          .desktop-menu .login-quicklink .profile-image .dropdown-menu {
            position: absolute;
            left: 0;
            right: inherit;
            width: 240px;
            padding-top: 0px;
            padding-bottom: 0px; } }
        @media only screen and (max-width: 576px) {
          .desktop-menu .login-quicklink .profile-image .dropdown-menu {
            left: inherit;
            right: 0; } }
      .desktop-menu .login-quicklink .profile-image .agent-full-name {
        width: 100%;
        float: left;
        padding: 10px;
        font-size: 13px;
        text-align: center;
        cursor: default;
        border-bottom: 1px solid #ddd;
        line-height: normal;
        word-break: break-all; }
      .desktop-menu .login-quicklink .profile-image.client-list {
        margin-right: 13px; }
        @media only screen and (max-width: 1200px) {
          .desktop-menu .login-quicklink .profile-image.client-list {
            margin-right: 10px; } }
        @media only screen and (max-width: 991px) {
          .desktop-menu .login-quicklink .profile-image.client-list {
            margin-right: 35px; } }
        @media only screen and (max-width: 768px) {
          .desktop-menu .login-quicklink .profile-image.client-list {
            margin-right: 65px; } }
        @media only screen and (max-width: 375px) {
          .desktop-menu .login-quicklink .profile-image.client-list {
            margin-right: 110px; } }
        .desktop-menu .login-quicklink .profile-image.client-list .dropdown-menu {
          width: 250px;
          min-width: inherit;
          padding: 0px;
          overflow-x: hidden; }
          @media only screen and (max-width: 991px) {
            .desktop-menu .login-quicklink .profile-image.client-list .dropdown-menu {
              width: 260px;
              min-width: inherit;
              left: inherit;
              right: 0; } }
          @media only screen and (max-width: 576px) {
            .desktop-menu .login-quicklink .profile-image.client-list .dropdown-menu {
              left: 0;
              right: inherit; } }
          .desktop-menu .login-quicklink .profile-image.client-list .dropdown-menu .dropdown-item .material-icons {
            vertical-align: middle;
            font-size: 20px; }
          .desktop-menu .login-quicklink .profile-image.client-list .dropdown-menu .dropdown-item .client-name {
            width: calc(100% - 36px);
            display: inline-block;
            vertical-align: top;
            white-space: normal;
            word-break: break-word;
            padding-right: 10px; }
          .desktop-menu .login-quicklink .profile-image.client-list .dropdown-menu .dropdown-item .client-chart-icon {
            display: inline-block;
            vertical-align: top; }
      .desktop-menu .login-quicklink .profile-image.my-agent {
        margin-right: 13px; }
        @media only screen and (max-width: 1200px) {
          .desktop-menu .login-quicklink .profile-image.my-agent {
            margin-right: 10px; } }
        @media only screen and (max-width: 991px) {
          .desktop-menu .login-quicklink .profile-image.my-agent {
            margin-right: 35px; } }
        @media only screen and (max-width: 768px) {
          .desktop-menu .login-quicklink .profile-image.my-agent {
            margin-right: 65px; } }
        @media only screen and (max-width: 375px) {
          .desktop-menu .login-quicklink .profile-image.my-agent {
            margin-right: 110px; }
            .desktop-menu .login-quicklink .profile-image.my-agent.my-adv {
              margin-right: 36px; } }
        @media only screen and (max-width: 991px) {
          .desktop-menu .login-quicklink .profile-image.my-agent .dropdown-menu {
            left: inherit;
            right: 0; } }
        @media only screen and (max-width: 576px) {
          .desktop-menu .login-quicklink .profile-image.my-agent .dropdown-menu {
            left: 0;
            right: inherit; } }
  @media only screen and (max-width: 1300px) {
    .desktop-menu.extra-nav-link .navbar-nav .nav-links .nav-link {
      padding-right: 4px;
      padding-left: 4px; } }
  @media only screen and (max-width: 1200px) {
    .desktop-menu.extra-nav-link .navbar-nav .nav-links .nav-link {
      font-size: 12px; } }
  @media only screen and (max-width: 1100px) {
    .desktop-menu.extra-nav-link .navbar-nav .nav-links .nav-link {
      padding-right: 3px;
      padding-left: 3px;
      font-size: 12px; } }
  @media only screen and (max-width: 991px) {
    .desktop-menu.extra-nav-link .navbar-nav .nav-links .nav-link {
      font-size: 13px; } }
  @media only screen and (max-width: 1100px) {
    .desktop-menu.extra-nav-link .navbar-nav .navbar-signup-button {
      padding: 8px 10px; } }
  @media only screen and (max-width: 991px) {
    .desktop-menu.extra-nav-link .navbar-nav .navbar-signup-button {
      padding: 8px 12px; } }
  @media only screen and (max-width: 1100px) {
    .desktop-menu.extra-nav-link .desktop-chat {
      margin: 5px 12px 0 0; }
      .desktop-menu.extra-nav-link .desktop-chat .material-icons {
        font-size: 20px; }
      .desktop-menu.extra-nav-link .desktop-chat .notification-number {
        width: 18px;
        height: 18px;
        font-size: 10px;
        line-height: 18px;
        top: -11px;
        left: 9px; } }
  @media only screen and (max-width: 1100px) {
    .desktop-menu.extra-nav-link .top-menu-logo img {
      max-height: 50px;
      max-width: 120px; } }
  @media only screen and (max-width: 991px) {
    .desktop-menu.extra-nav-link .top-menu-logo img {
      max-height: 60px;
      max-width: 155px; } }
  @media only screen and (max-width: 1100px) {
    .desktop-menu.extra-nav-link .login-quicklink .profile-image.client-list {
      margin-right: 8px; } }
  @media only screen and (max-width: 991px) {
    .desktop-menu.extra-nav-link .login-quicklink .profile-image.client-list {
      margin-right: 35px; } }
  @media only screen and (max-width: 768px) {
    .desktop-menu.extra-nav-link .login-quicklink .profile-image.client-list {
      margin-right: 65px; } }
  @media only screen and (max-width: 1100px) {
    .desktop-menu.extra-nav-link .purl-agent .purl-photo {
      width: 28px;
      height: 28px; } }
  @media only screen and (max-width: 991px) {
    .desktop-menu.extra-nav-link .purl-agent .purl-photo {
      width: 38px;
      height: 38px; } }

/**********Quick Bar Start**********/
.quick-blackbg {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  display: none; }

.quick-bar {
  position: fixed;
  top: 0;
  right: -320px;
  width: 100% !important;
  height: 100%;
  z-index: 99999;
  transform: translateX(100%);
  box-shadow: 0 0 4px #ddd;
  text-align: right;
  transition: transform .25s ease-out;
  display: none; }
  .quick-bar.open {
    transform: translateX(0);
    right: 0px;
    display: block; }
    .quick-bar.open + .quick-blackbg {
      display: block; }
  .quick-bar .quick-bar-slider {
    width: 320px;
    display: inline-block;
    background: #fff;
    text-align: left;
    vertical-align: top; }
    @media only screen and (max-width: 576px) {
      .quick-bar .quick-bar-slider {
        width: 100%; } }
  .quick-bar .quick-bar-close {
    width: 100%;
    display: block;
    height: 68px;
    padding: 5px 0 5px 15px;
    line-height: 50px;
    border-bottom: 1px solid #ddd; }
    .quick-bar .quick-bar-close .material-icons {
      display: inline-block;
      vertical-align: middle;
      font-size: 22px;
      padding-right: 10px;
      cursor: pointer; }
  .quick-bar .activity-container {
    padding: 25px 25px 0 30px;
    overflow: auto;
    width: 100%;
    display: block; }
    .quick-bar .activity-container .activity-list {
      margin: 0;
      padding: 0px;
      position: relative; }
      .quick-bar .activity-container .activity-list:after {
        position: absolute;
        top: -5px;
        bottom: 0;
        left: 0;
        width: 2px;
        margin-left: -1px;
        content: "";
        background-color: #e4eaec; }
      .quick-bar .activity-container .activity-list li {
        padding-left: 30px;
        width: 100%;
        float: none;
        display: block;
        position: relative;
        margin-bottom: 22px; }
      .quick-bar .activity-container .activity-list .activity-icon {
        position: absolute;
        width: 30px;
        height: 30px;
        top: 0;
        left: -15px;
        line-height: 30px;
        right: 0;
        z-index: 11;
        font-size: 18px;
        color: #fff;
        text-align: center;
        cursor: pointer;
        border-radius: 50%; }
        .quick-bar .activity-container .activity-list .activity-icon.bg-comment {
          background: #46be8a; }
        .quick-bar .activity-container .activity-list .activity-icon.bg-favorite {
          background: #57c7d4; }
      .quick-bar .activity-container .activity-list .activity-content {
        float: none;
        width: 100%;
        overflow: hidden;
        color: #84909a;
        font-size: 14px;
        cursor: pointer; }
        .quick-bar .activity-container .activity-list .activity-content .activity-time {
          font-size: 12px;
          width: 100%;
          float: left;
          padding-bottom: 16px;
          line-height: normal; }
        .quick-bar .activity-container .activity-list .activity-content .activity-profile {
          float: left;
          border: 1px solid #D8D8D8;
          padding: 2px;
          height: 35px;
          width: 35px;
          border-radius: 50%;
          overflow: hidden; }
          .quick-bar .activity-container .activity-list .activity-content .activity-profile img {
            border-radius: 50%;
            height: 100%;
            object-fit: cover; }
          .quick-bar .activity-container .activity-list .activity-content .activity-profile.activity-profile-name {
            background: #D8D8D8;
            color: #033258;
            font-size: 13px;
            line-height: 30px;
            text-align: center; }
        .quick-bar .activity-container .activity-list .activity-content .activity-name {
          width: calc(100% - 35px);
          float: none;
          display: block;
          overflow: hidden;
          padding-left: 10px;
          line-height: 20px; }
  .quick-bar h2 {
    font-size: 24px;
    display: inline-block;
    vertical-align: middle; }
    @media only screen and (max-width: 768px) {
      .quick-bar h2 {
        font-size: 20px; } }

/**********Quick Bar End**********/
/************Chat Button**********/
.chat-button {
  background: none;
  padding: 0px;
  margin: 5px 5px 0 0;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  border: none !important;
  outline: none !important; }
  @media only screen and (max-width: 1200px) {
    .chat-button {
      margin: 10px 15px 0 0; } }
  .chat-button:hover, .chat-button:active, .chat-button:focus {
    background: none; }
  .chat-button .notification-number {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    line-height: 20px;
    position: absolute;
    /* top:-8px;
    	left:10px; */
    top: -8px;
    left: 10px;
    font-size: 11px; }

.main-fixed .desktop-menu .bg-light {
  box-shadow: none; }

.chat-not-cnt {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  line-height: 20px;
  position: absolute;
  top: -5px;
  right: 8px;
  font-size: 11px;
  text-align: center; }
  .chat-not-cnt.inline {
    right: -12px; }
